import useApiConnection from '../apiConnection'
import { ECOM_ENDPOINTS } from '../apiEndpoints'

const useFeaturedProductService = () => {
  const endpoint = process.env.REACT_APP_API_SLEEK_GLOBALADMIN
  const api = useApiConnection(endpoint, ECOM_ENDPOINTS.FEATURED_PRODUCTS)

  const getByIdAsync = async (id) => {
    return await api.getAsync(id)
  }

  const getListAsync = async (payload) => {
    return await api.getAsync('', { payload })
  }

  const getCurrentListAsync = async (type, payload) => {
    return await api.getAsync(`current-list/${type}`, { payload })
  }

  const getByCategoryAsync = async (categoryId, payload) => {
    return await api.getAsync(`get-by-categoryId/${categoryId}`, { payload })
  }

  const insertAsync = async (payload) => {
    return await api.putAsync('', { payload })
  }

  const updateAsync = async (payload) => {
    return await api.postAsync('', { payload })
  }

  const deleteAsync = async (id) => {
    return await api.deleteAsync(id)
  }

  const deleteRangeAsync = async (payload) => {
    return await api.deleteAsync('', { payload })
  }

  return { getByIdAsync, getListAsync, getCurrentListAsync, getByCategoryAsync, insertAsync, updateAsync, deleteAsync, deleteRangeAsync }
}

export default useFeaturedProductService
