import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FEATURED_PRODUCT_TYPE_ENUM, getKeyByValue } from '../../../utilities/enum'
import { useFeaturedProductService } from '../../../services/apiServices'
import { useIntl } from 'react-intl'
import { DEFAULT_VALUE } from '../../../utilities/constant'
import NumberFormat from '../../../components/shared/NumberFormat'
import Rate from '../../../components/shared/Rate'

const TopProducts = () => {
  const intl = useIntl()
  const { getCurrentListAsync: getFeaturedProducts } = useFeaturedProductService()

  const [topSelling, setTopSelling] = useState([])
  const [trendingProduct, setTrendingProduct] = useState([])
  const [recentlyAdded, setRecentlyAdded] = useState([])
  const [topRated, setTopRated] = useState([])

  const [productGroups] = useState([
    FEATURED_PRODUCT_TYPE_ENUM.TOPSELLING,
    FEATURED_PRODUCT_TYPE_ENUM.TRENDINGPRODUCT,
    FEATURED_PRODUCT_TYPE_ENUM.RECENTLYADDED,
    FEATURED_PRODUCT_TYPE_ENUM.TOPRATED,
  ])

  useEffect(() => {
    getTopSelling()
    getTrendingProduct()
    getTopRated()
    getRecentlyAdded()
  }, [])

  const getTopSelling = async () => {
    try {
      const { result } = await getFeaturedProducts(FEATURED_PRODUCT_TYPE_ENUM.TOPSELLING, {
        pageIndex: DEFAULT_VALUE.PAGE_INDEX,
        pageSize: 3,
      })

      if (result) {
        setTopSelling(result.items)
      } else {
        setTopSelling([])
      }
    } catch (error) {
      console.error('Error fetching initial getTopSelling:', error)
    }
  }

  const getTrendingProduct = async () => {
    try {
      const { result } = await getFeaturedProducts(FEATURED_PRODUCT_TYPE_ENUM.TRENDINGPRODUCT, {
        pageIndex: DEFAULT_VALUE.PAGE_INDEX,
        pageSize: 3,
      })

      if (result) {
        setTrendingProduct(result.items)
      } else {
        setTrendingProduct([])
      }
    } catch (error) {
      console.error('Error fetching initial getTrendingProduct:', error)
    }
  }

  const getTopRated = async () => {
    try {
      const { result } = await getFeaturedProducts(FEATURED_PRODUCT_TYPE_ENUM.TOPRATED, {
        pageIndex: DEFAULT_VALUE.PAGE_INDEX,
        pageSize: 3,
      })

      if (result) {
        setTopRated(result.items)
      } else {
        setTopRated([])
      }
    } catch (error) {
      console.error('Error fetching initial getTopRated:', error)
    }
  }

  const getRecentlyAdded = async () => {
    try {
      const { result } = await getFeaturedProducts(FEATURED_PRODUCT_TYPE_ENUM.RECENTLYADDED, {
        pageIndex: DEFAULT_VALUE.PAGE_INDEX,
        pageSize: 3,
      })

      if (result) {
        setRecentlyAdded(result.items)
      } else {
        setRecentlyAdded([])
      }
    } catch (error) {
      console.error('Error fetching initial getRecentlyAdded:', error)
    }
  }

  return (
    <section id='home-top-product'>
      <div className='mb-8'>
        <div className='row'>
          {productGroups?.map((groupName) => {
            let products =
              {
                [FEATURED_PRODUCT_TYPE_ENUM.TOPSELLING]: topSelling,
                [FEATURED_PRODUCT_TYPE_ENUM.TRENDINGPRODUCT]: trendingProduct,
                [FEATURED_PRODUCT_TYPE_ENUM.RECENTLYADDED]: recentlyAdded,
                [FEATURED_PRODUCT_TYPE_ENUM.TOPRATED]: topRated,
              }[groupName] || []
            return (
              <ShowTopProduct
                key={getKeyByValue(FEATURED_PRODUCT_TYPE_ENUM, groupName)}
                products={products}
                name={intl.formatMessage({ id: `Ecom.FeaturedProduct.${getKeyByValue(FEATURED_PRODUCT_TYPE_ENUM, groupName)}` })}
              />
            )
          })}
        </div>
      </div>
    </section>
  )
}

const ShowTopProduct = ({ products, name }) => {
  return (
    <div className='col-lg-6 col-xl-3 col-sm-6 mt-4'>
      <h4 className='section-title style-1'>{name}</h4>
      <div className='product-list-small'>
        {products?.map((d) => {
          const product = d.product.details.reduce((lowest, product) => {
            const currentPrice = product.sellingPrice - product.discountAmount
            const lowestPrice = lowest.sellingPrice - lowest.discountAmount

            return currentPrice < lowestPrice ? product : lowest
          })
          return (
            <article className='row align-items-center hover-up' key={d.productId}>
              <figure className='col-4 mb-0'>
                <Link to={`/product/${d.product.id}`}>
                  <img className='rounded' src={d.product.fileThumbnail.url} alt='' />
                </Link>
              </figure>
              <div className='col-8 mb-0'>
                <h6>
                  <Link to={`/product/${d.productId}`}>{d.product.name}</Link>
                </h6>
                <Rate value={d.rating} isDisabled={true} />
                <div className='product-price'>
                  <NumberFormat amount={product.sellingPrice - product.discountAmount} className={'product-price'} suffix='đ' />
                  <NumberFormat amount={product.sellingPrice} className='old-price' suffix='đ' />
                </div>
              </div>
            </article>
          )
        })}
      </div>
    </div>
  )
}

export default TopProducts
