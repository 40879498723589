import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { MenuComponent } from '../../assets/ts/components/index.ts'
import { PageDataProvider } from './components/breadcrumb/PageData'
import ScrollTop from './components/ScrollTop'
import Header from './components/Header.js'
import Footer from './components/Footer.js'

const MasterLayout = () => {
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root'>
        <div className='page d-flex flex-row flex-column-fluid bg-white'>
          <div id='kt_wrapper' className='wrapper d-flex flex-column flex-row-fluid'>
            <Header />
            <div id='kt_content' className='d-flex flex-column flex-column-fluid mb-5'>
              <div id='kt_content_container' className='container-xxl pt-4'>
                <Outlet />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
      <ScrollTop />
    </PageDataProvider>
  )
}

export default MasterLayout
