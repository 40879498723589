import useApiConnection from './apiConnection'
import { ECOM_ENDPOINTS } from './apiEndpoints'

const usePaymentService = () => {
  const endpoint = process.env.REACT_APP_API_SLEEK_GLOBALADMIN
  const api = useApiConnection(endpoint, ECOM_ENDPOINTS.PAYMENT)

  const vnPay = async (payload) => {
    return await api.postAsync('vnPay', { payload })
  }

  return { vnPay }
}

export default usePaymentService
