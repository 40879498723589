/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import Languages from './Languages'
import Notification from './Notification'
import UserMenu from './UserMenu'
import { useAuth } from '../../../components/auth/AuthContext'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { FormattedMessage, useIntl } from 'react-intl'
import { Search } from './Search'
import Categories from './Categories'
import ShoppingCart from './ShoppingCart'

const Header = () => {
  const intl = useIntl()
  const { user, login, register } = useAuth()
  const [scrollPosition, setScrollPosition] = useState(0)
  const homeUrl = process.env.REACT_APP_SLEEK_HOME
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div id='kt_header' className='d-flex flex-column'>
      <div className={`bg-primary`}>
        <div className='container-xxl navbar-wrapper d-flex justify-content-between py-2'>
          <div className='d-flex align-items-center'>
            <Link to='/home' className='content'>
              <i className='fa fa-home text-white me-1'></i>
              <FormattedMessage id='Home' />
            </Link>
            <div className='d-none d-md-flex'>
              <div className='border-start mx-2'></div>
              <Link target='_blank' to={`${homeUrl}/gioi-thieu`} className='content'>
                <FormattedMessage id='AboutUs' />
              </Link>
              <div className='border-start mx-2'></div>
              <Link to='/purchase/1' className='content'>
                <FormattedMessage id='OrderTracking' />
              </Link>
              <div className='border-start mx-2'></div>
              <div className='content'>
                <FormattedMessage id='FollowUsOn' />
              </div>
              <div className='content'>
                <a target='_blank' href='https://www.facebook.com/profile.php?id=61554251262875' title={intl.formatMessage({ id: 'FollowUsOnFacebook' })}>
                  <i className='fa-brands fa-facebook text-white' aria-hidden='true'></i>
                </a>
                <a
                  target='_blank'
                  href='https://www.linkedin.com/in/d%C4%A9-%C4%91o%C3%A0n-v%C4%83n-7ba4562a9/'
                  title={intl.formatMessage({ id: 'FollowUsOnLinked' })}
                  className='ms-2'
                >
                  <i className='fa-brands fa-linkedin-in text-white' aria-hidden='true'></i>
                </a>

                <a target='_blank' href='https://www.youtube.com/@Green24h' title={intl.formatMessage({ id: 'FollowUsOnYoutube' })} className='ms-2'>
                  <i className='fa-brands fa-youtube text-white' aria-hidden='true'></i>
                </a>

                {/* <a target='_blank' title={intl.formatMessage({ id: 'FollowUsOnTwitter' })} className='ms-2'>
                  <i className='fa-brands fa-twitter text-white' aria-hidden='true'></i>
                </a>
                
                <a target='_blank' title={intl.formatMessage({ id: 'FollowUsOnTiktok' })} className='ms-2'>
                  <i className='fa-brands fa-tiktok text-white' aria-hidden='true'></i>
                </a> */}
              </div>
            </div>
          </div>

          <div className='d-flex align-items-stretch'>
            <div className='d-flex d-none d-lg-flex'>
              <a target='_blank' className='content'>
                <span className='ms-1'>
                  <FormattedMessage id='CallUs' />: 08 5553 8385
                </span>
              </a>
              <div className='border-start mx-2' />
            </div>
            <Notification />
            <div className='border-start mx-2' />
            <Languages />
          </div>
        </div>
      </div>

      <div className={`bg-white`}>
        <div className={`d-flex p-4 ${scrollPosition > 200 ? 'bg-white left-0 fixed-header show animate__animated animate__fadeInDown' : 'container-xxl'}`}>
          <div className='container-xxl d-flex'>
            <div className='header-logo me-5 me-md-10 flex-grow-1 flex-lg-grow-0 d-none d-md-flex'>
              <Link to='/home'>
                <img alt='Logo' src={'/media/logos/logo_large.png'} className='logo-sticky h-45px me-10' />
              </Link>
            </div>

            <div className={`header-wrap`}>
              <div className='header-right'>
                <Search />
                <div className='header-action-right'>
                  <div className='header-action-2'>
                    <div className='header-action-icon-2 hover-text'>
                      <a className='header-action-content' href='/shop-wishlist'>
                        <img src={'/media/svg/icons/icon-heart.svg'} alt='' />
                        <span className='pro-count blue'>0</span>
                      </a>
                      <a className='header-action-content d-none d-lg-flex align-items-center' href='/shop-wishlist'>
                        <span className='ms-2 label d-none d-xl-flex'>
                          <FormattedMessage id='Wishlist' />
                        </span>
                      </a>
                    </div>
                    <div className='header-action-icon-2'>
                      <ShoppingCart />
                    </div>
                    <div className='header-action-icon-2 d-none d-lg-flex'>
                      {_.isEmpty(user) ? (
                        <div className='d-flex ms-1'>
                          <img src={'/media/svg/icons/icon-user.svg'} alt='' />
                          <a onClick={register} className='header-action-content'>
                            <span className='ms-2 label hover-text d-flex align-items-center'>
                              <FormattedMessage id='Register' />
                            </span>
                          </a>
                          <div className='border-start border-dark mt-1 mx-2'></div>
                          <a onClick={login} className='header-action-content'>
                            <span className='ms-2 label hover-text d-flex align-items-center'>
                              <FormattedMessage id='Login' />
                            </span>
                          </a>
                        </div>
                      ) : (
                        <div className='d-flex' id='kt_header_user_menu_toggle'>
                          <a
                            className='cursor-pointer header-action-content'
                            data-kt-menu-trigger='click'
                            data-kt-menu-attach='parent'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='bottom'
                          >
                            <img src={'/media/svg/icons/icon-user.svg'} alt='' />
                            <span className='ms-2 label'>{user?.profile?.fullName}</span>
                          </a>
                          <UserMenu />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Categories />
      </div>
    </div>
  )
}

export default Header
