import React from 'react'
import '../../assets/sass/productDetail.scss'
import Rate from '../../components/shared/Rate'

const ProductReview = (product) => {
  return (
    <section className='card px-4'>
      <div className='product-rating'>
        <div className='d-flex bg-light p-3 my-2 rounded'>
          <div className='row align-items-center p-4'>
            <div className='text-primary fs-4'>
              <span className='fw-bold'>4.7 / 5</span>
              <span className='fw-normal'> (87 đánh giá)</span>
            </div>
            <Rate rating={4.5} isDisabled={true} />
          </div>

          <div className='product-rating-filters p-4'>
            <div className='product-rating-filter product-rating-filter-active'>Tất cả</div>
            <div className='product-rating-filter'>5 Sao (45)</div>
            <div className='product-rating-filter'>4 Sao (1)</div>
            <div className='product-rating-filter'>3 Sao (0)</div>
            <div className='product-rating-filter'>2 Sao (0)</div>
            <div className='product-rating-filter'>1 Sao (0)</div>
            <div className='product-rating-filter'>Có Bình luận (21)</div>
            <div className='product-rating-filter'>Có hình ảnh / video (14)</div>
          </div>
        </div>
      </div>

      <div className='row border-bottom my-4 pb-4'>
        <div className='col-3'>
          <p className='fw-bold my-1'>Phan Duy An 95</p>
          <p className='my-1'>Hạng Kim Cương</p>
          <Rate rating={4.5} isDisabled={true} />
          <p className='my-1'>23/01/2024 - 08:16</p>
        </div>
        <div className='d-flex flex-column position-relative col-9'>
          <div className='text-muted'>Phân loại: XANH RÊU</div>
          <div>
            Khách khen áo đẹp mặc vừa vặn Khách khen áo đẹp mặc vừa vặn Khách khen áo đẹp mặc vừa vặn Khách khen áo đẹp mặc vừa vặn Khách khen áo đẹp mặc vừa
            vặn
          </div>
          <div>
            <img
              width='100'
              height='100'
              src='https://media.sellycdn.net/files/sm_1705972563241mStyA4D365.jpeg'
              loading='lazy'
              className='object-fit-cover border-0 rounded cursor-pointer'
            />
          </div>
          <div className='d-flex justify-content-between pt-4'>
            <span className='cursor-pointer text-muted'>
              <i className='fa fa-thumbs-up me-1' aria-hidden='true'></i>
              Hữu ích?
            </span>
            <span className='cursor-pointer'>
              <i className='fa fa-ellipsis-v fs-2' aria-hidden='true'></i>
            </span>
          </div>
        </div>
      </div>

      <div className='row border-bottom my-4 pb-4'>
        <div className='col-3'>
          <p className='fw-bold my-1'>Phan Duy An 95</p>
          <p className='my-1'>Hạng Kim Cương</p>
          <Rate rating={4.5} isDisabled={true} />
          <p className='my-1'>23/01/2024 - 08:16</p>
        </div>
        <div className='d-flex flex-column position-relative col-9'>
          <div className='text-muted'>Phân loại: XANH RÊU</div>
          <div>
            Khách khen áo đẹp mặc vừa vặn Khách khen áo đẹp mặc vừa vặn Khách khen áo đẹp mặc vừa vặn Khách khen áo đẹp mặc vừa vặn Khách khen áo đẹp mặc vừa
            vặn
          </div>
          <div className='d-flex justify-content-between pt-4'>
            <span className='cursor-pointer text-muted'>
              <i className='fa fa-thumbs-up me-1' aria-hidden='true'></i>
              Hữu ích?
            </span>
            <span className='cursor-pointer'>
              <i className='fa fa-ellipsis-v fs-2' aria-hidden='true'></i>
            </span>
          </div>
        </div>
      </div>

      <div className='d-flex flex-row justify-content-center p-4'>
        <button className='icon-button'>
          <i className='fa fa-angle-left' aria-hidden='true'></i>
        </button>
        <button className='icon-button-select icon-button'>1</button>
        <button className='icon-button'>2</button>
        <button className='icon-button'>3</button>
        <button className='icon-button'>4</button>
        <button className='icon-button'>5</button>
        <button className='icon-button'>...</button>
        <button className='icon-button'>
          <i className='fa fa-angle-right' aria-hidden='true'></i>
        </button>
      </div>
    </section>
  )
}

export default ProductReview
