import { useIntl } from 'react-intl'
import Breadcrumb from '../../components/layout/components/breadcrumb/Breadcrumb'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CONTROL_TYPE, FormControl, validationAllComponents } from '../../components/shared/FormControl'
import { useAffiliateService, useLocationService } from '../../services/apiServices'
import { GENDER_ENUM, getEnums } from '../../utilities/enum'
import { addToFormData } from '../../utilities/sharedFunction'
import _ from 'lodash'
import { showToast, TOAST_TYPES } from '../../components/shared/Toast'
import Button from '../../components/shared/Button'

const RegisterAffiliate = () => {
  const intl = useIntl()
  const componentRefs = useRef({})
  let tabIndex = 1
  const { insertAsync, getByCodeAsync } = useAffiliateService()
  const { getProvincesAsync, getDistrictsAsync, getWardsAsync } = useLocationService()
  const { referralCode } = useParams()
  const ecomUrl = process.env.REACT_APP_SLEEK_ECOM
  const navigate = useNavigate()

  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [phoneNumber, setPhoneNumber] = useState()
  const [avatar, setAvatar] = useState()
  const [gender, setGender] = useState()
  const [birthday, setBirthday] = useState()
  const [taxCode, setTaxCode] = useState()
  const [identificationCardCode, setIdentificationCardCode] = useState()
  const [fileAvatar, setFileAvatar] = useState()
  const [provinceId, setProvinceId] = useState()
  const [districtId, setDistrictId] = useState()
  const [wardId, setWardId] = useState()
  const [addressLine, setAddressLine] = useState()
  const [latitude, setLatitude] = useState()
  const [longitude, setLongitude] = useState()

  const [affiliateParentCode, setAffiliateParentCode] = useState(null)
  const [parrentAfffiliateId, setParrentAfffiliateId] = useState(null)
  const [affiliateParentName, setAffiliateParentName] = useState(null)

  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const [wards, setWards] = useState([])
  const genders = getEnums(GENDER_ENUM, intl)

  useEffect(() => {
    const fetchProvinces = async () => {
      const { result } = await getProvincesAsync()
      if (result) {
        setProvinces(
          result.map((item) => {
            return { value: item.id, label: item.name }
          })
        )
      }
    }

    fetchProvinces()
    if (referralCode !== 0) {
      fetchParentAffiliate()
    }
  }, [referralCode])

  useEffect(() => {
    const fetchDistricts = async (provinceId) => {
      const { result } = await getDistrictsAsync(provinceId)
      if (result) {
        const districtList = result.map((item) => ({ value: item.id, label: item.name }))

        setDistricts(districtList)

        if (!districtList.some((district) => district.value === districtId)) {
          setDistrictId(null)
        }
      }
    }

    if (provinceId) {
      fetchDistricts(provinceId)
    } else {
      setDistricts([])
      setDistrictId(null)
    }
  }, [provinceId])

  useEffect(() => {
    const fetchWards = async (districtId) => {
      const { result } = await getWardsAsync(districtId)
      if (result) {
        const wardsList = result.map((item) => ({ value: item.id, label: item.name }))

        setWards(wardsList)

        if (!wardsList.some((ward) => ward.value === wardId)) {
          setWardId(null)
        }
      }
    }

    if (districtId) {
      fetchWards(districtId)
    } else {
      setWards([])
      setWardId(null)
    }
  }, [districtId])

  useEffect(() => {
    fetchParentAffiliate()
  }, [affiliateParentCode])

  const fetchParentAffiliate = async () => {
    if (affiliateParentCode) {
      const { result } = await getByCodeAsync(affiliateParentCode)

      setParrentAfffiliateId(result?.id)
      setAffiliateParentName(result?.fullName)
      setAffiliateParentCode(result?.code)
    }
  }

  const handleSave = async () => {
    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    const formData = new FormData()
    addToFormData(formData, 'parentId', parrentAfffiliateId)
    addToFormData(formData, 'firstName', firstName)
    addToFormData(formData, 'lastName', lastName)
    addToFormData(formData, 'phoneNumber', phoneNumber)
    addToFormData(formData, 'email', email)
    addToFormData(formData, 'avatar', avatar)
    addToFormData(formData, 'gender', gender)
    addToFormData(formData, 'birthday', birthday.toISOString())
    addToFormData(formData, 'taxCode', taxCode)
    addToFormData(formData, 'identificationCardCode', identificationCardCode)
    addToFormData(formData, 'isActive', true)
    addToFormData(formData, 'address', {
      provinceId,
      districtId,
      wardId,
      addressLine,
      latitude,
      longitude,
      isActive: true,
    })

    if (!_.isEmpty(fileAvatar)) {
      let file = Array.isArray(fileAvatar) ? fileAvatar[0] : fileAvatar
      addToFormData(formData, 'fileAvatar', file.url ? null : file.originFileObj)
    }

    const { error } = await insertAsync(formData)
    if (_.isEmpty(error)) {
      navigate('/confirmation', {
        state: {
          confirmMessage: {
            title: intl.formatMessage({ id: 'Email.ConfirmCreatedAccount' }),
            email: email,
            message: intl.formatMessage({ id: 'EmailRegisterSuccess' }, { email }),
            returnUrl: ecomUrl,
          },
        },
      })
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
    }
  }

  return (
    <div>
      <div className='header-page'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='title'>
            <h1 className='mb-4'>{intl.formatMessage({ id: 'Affiliate.Register' })}</h1>
            <Breadcrumb />
          </div>
        </div>
      </div>

      <div className='card card-flush mt-5 shadow-sm'>
        <div className='card-body'>
          <form className='d-flex flex-column gap-4'>
            <div className='row'>
              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.firstName = ref)}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Ecom.Affiliate.FirstName' })}
                  value={firstName}
                  onChange={setFirstName}
                  tabIndex={tabIndex++}
                  isRequired={true}
                />
              </div>

              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.lastName = ref)}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Ecom.Affiliate.LastName' })}
                  value={lastName}
                  onChange={setLastName}
                  tabIndex={tabIndex++}
                  isRequired={true}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.email = ref)}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Ecom.Affiliate.Email' })}
                  value={email}
                  onChange={setEmail}
                  tabIndex={tabIndex++}
                  isRequired={true}
                  isEmail={true}
                />
              </div>

              <div className='col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.phoneNumber = ref)}
                  controlType={CONTROL_TYPE.PHONENUMBER}
                  label={intl.formatMessage({ id: 'Ecom.Affiliate.PhoneNumber' })}
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  tabIndex={tabIndex++}
                  isRequired={true}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.birthday = ref)}
                  controlType={CONTROL_TYPE.DATEPICKER}
                  label={intl.formatMessage({ id: 'Ecom.Affiliate.Birthday' })}
                  value={birthday}
                  isRequired={true}
                  onChange={setBirthday}
                  tabIndex={tabIndex++}
                />
              </div>

              <div className='col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.gender = ref)}
                  controlType={CONTROL_TYPE.SELECT}
                  label={intl.formatMessage({ id: 'Ecom.Affiliate.Gender' })}
                  value={gender}
                  isRequired={true}
                  onChange={setGender}
                  options={genders}
                  tabIndex={tabIndex++}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.identificationCardCode = ref)}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Ecom.Affiliate.IdentificationCardCode' })}
                  value={identificationCardCode}
                  onChange={setIdentificationCardCode}
                  tabIndex={tabIndex++}
                />
              </div>

              <div className='col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.taxCode = ref)}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Ecom.Affiliate.TaxCode' })}
                  value={taxCode}
                  onChange={setTaxCode}
                  tabIndex={tabIndex++}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-4 mb-4 mb-md-0'>
                <FormControl
                  controlType={CONTROL_TYPE.SELECT}
                  ref={(ref) => (componentRefs.current.provinceId = ref)}
                  label={intl.formatMessage({ id: 'Province' })}
                  isRequired={true}
                  value={provinceId}
                  onChange={setProvinceId}
                  tabIndex={tabIndex++}
                  options={provinces}
                />
              </div>

              <div className='col-md-4 mb-4 mb-md-0'>
                <FormControl
                  controlType={CONTROL_TYPE.SELECT}
                  ref={(ref) => (componentRefs.current.districtId = ref)}
                  label={intl.formatMessage({ id: 'District' })}
                  isRequired={true}
                  value={districtId}
                  onChange={setDistrictId}
                  tabIndex={tabIndex++}
                  options={districts}
                />
              </div>

              <div className='col-md-4'>
                <FormControl
                  controlType={CONTROL_TYPE.SELECT}
                  ref={(ref) => (componentRefs.current.wardId = ref)}
                  label={intl.formatMessage({ id: 'Ward' })}
                  isRequired={true}
                  value={wardId}
                  onChange={setWardId}
                  tabIndex={tabIndex++}
                  options={wards}
                />
              </div>
            </div>

            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.addressLine = ref)}
                controlType={CONTROL_TYPE.TEXTAREA}
                label={intl.formatMessage({ id: 'AddressLine' })}
                value={addressLine}
                rows={2}
                onChange={setAddressLine}
                tabIndex={tabIndex++}
                isRequired={true}
              />
            </div>

            <div className='row'>
              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.affiliateParentCode = ref)}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Affiliate.AfflicateCode' })}
                  value={affiliateParentCode}
                  onChange={setAffiliateParentCode}
                  tabIndex={tabIndex++}
                />
              </div>

              <div className='col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.affiliateParentName = ref)}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Affiliate.AffiliateParentName' })}
                  value={affiliateParentName}
                  onChange={setAffiliateParentName}
                  tabIndex={tabIndex++}
                  readOnly={true}
                />
              </div>
            </div>
          </form>

          <div className='d-flex m-4 justify-content-center gap-4'>
            <Button
              title={intl.formatMessage({ id: 'Save' })}
              icon='SaveOutlined'
              className='btn-primary'
              onClick={handleSave}
              tabIndex={tabIndex++}
              disabled={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterAffiliate
