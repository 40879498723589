import { useIntl, FormattedMessage } from 'react-intl'
import useCountdownTimer from '../../../utilities/useCountdownTimer'
import { DEFAULT_VALUE } from '../../../utilities/constant'
import NumberFormat from '../../../components/shared/NumberFormat'
import { PROMOTION_DISCOUNT_TYPE_ENUM } from '../../../utilities/enum'
import dayjs from 'dayjs'

const PromotionCountdown = ({ promotion }) => {
  const intl = useIntl()
  const countdown = useCountdownTimer(promotion.toDate ?? dayjs(promotion.fromDate).add(30, 'day').toDate())

  return (
    <div className='col-xl-3 col-lg-4 col-sm-6'>
      <div className='product-cart-wrap style-2'>
        <div className='product-img-action-wrap'>
          <div className='product-img'>
            <a href='/shop-product-right'>
              <img src={promotion.fileBanner.url} alt='' />
            </a>
          </div>
        </div>
        <div className='product-content-wrap'>
          <div className='deals-countdown-wrap'>
            <div className='deals-countdown d-flex align-items-center justify-content-center'>
              <span className='countdown-section'>
                <span className='countdown-amount hover-up'>{countdown.days}</span>
                <span className='countdown-period'> days </span>
              </span>
              <span className='countdown-section'>
                <span className='countdown-amount hover-up'>{countdown.hours}</span>
                <span className='countdown-period'> hours </span>
              </span>
              <span className='countdown-section'>
                <span className='countdown-amount hover-up'>{countdown.minutes}</span>
                <span className='countdown-period'> mins </span>
              </span>
              <span className='countdown-section'>
                <span className='countdown-amount hover-up'>{countdown.seconds}</span>
                <span className='countdown-period'> sec </span>
              </span>
            </div>
          </div>
          <div className='deals-content'>
            <h2 className='cursor-pointer'>{promotion.name}</h2>

            <div className='product-card-bottom'>
              <div className='product-price'>
                <span>{intl.formatMessage({ id: 'Discount' })}</span>
                <span className='old-price'>
                  <NumberFormat
                    amount={promotion.discountValue}
                    suffix={promotion.type === PROMOTION_DISCOUNT_TYPE_ENUM.PERCENTAGE ? DEFAULT_VALUE.PERCENT : DEFAULT_VALUE.VND}
                  />
                </span>
              </div>
              <div className='add-cart'>
                <div className='btn btn-primary btn-sm d-flex align-items-center gap-1'>
                  <i className='fa-solid fa-solid fa-cart-shopping' />
                  {intl.formatMessage({ id: 'Add' })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Promotions = ({ promotions }) => {
  return (
    <section id='home-promotion'>
      <div className='mt-5'>
        <div className='section-title d-flex align-items-center justify-content-between'>
          <h3>
            <FormattedMessage id='Promotion' />
          </h3>
          <div className='fw-bold text-primary cursor-pointer'>
            <FormattedMessage id='ViewAll' />
            <i className='fa fa-angle-right ms-2 text-primary' aria-hidden='true'></i>
          </div>
        </div>

        <div className='row'>
          {promotions?.map((d, index) => {
            return <PromotionCountdown promotion={d} key={index} />
          })}
        </div>
      </div>
    </section>
  )
}

export default Promotions
