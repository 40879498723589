import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import ImageMagnify from 'react-image-magnify'
import SVG from 'react-inlinesvg'
import { useIntl } from 'react-intl'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Slider from 'react-slick'
import '../../assets/sass/productDetail.scss'
import { TOAST_TYPES, showToast } from '../../components/shared/Toast'
import { DEFAULT_VALUE, GUID_NEWID } from '../../utilities/constant'
import { VOUCHER_DISCOUNT_TYPE_ENUM, PROMOTION_DISCOUNT_TYPE_ENUM } from '../../utilities/enum'
import { useAuth } from '../../components/auth/AuthContext'
import { useShopingCart } from '../../components/contexts/ShoppingCartContext'
import NewsBanner from '../search/components/NewsBanner'
import Breadcrumb from '../../components/layout/components/breadcrumb/Breadcrumb'
import Rate from '../../components/shared/Rate'
import Input from '../../components/shared/Input'
import NumberBox from '../../components/shared/NumberBox'
import NumberFormat from '../../components/shared/NumberFormat'
import ProductSupplier from './ProductSupplier'
import { useSupplierService, useProductService } from '../../services/apiServices'
import OtherProduct from './OtherProduct'

import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

const ProductDetail = ({ product }) => {
  const intl = useIntl()
  const { checkLogin } = useAuth()
  const { shoppingCart, addToCart } = useShopingCart()
  const { getByIdAsync: getSupplier } = useSupplierService()
  const { getBySupplierIdAsync: getProudctBySupplier, getByCategoryIdAsync: getProudctByCategory } = useProductService()

  const navigate = useNavigate()
  const [combinedImages, setCombinedImages] = useState([])
  const [productDetail, setProductDetail] = useState({})

  const [supplier, setSupplier] = useState(null)
  const [productCategories, setProductCategories] = useState([])
  const [supplierProducts, setSupplierProducts] = useState([])
  const [qtyRemain, setQtyRemain] = useState(0)
  const [quantityOrder, setQuantityOrder] = useState(1)
  const [propertySelected, setPropertySelected] = useState([])
  const [hoveredImageId, setHoveredImageId] = useState(null)
  const [productPrice, setProductPrice] = useState(0)

  useEffect(() => {
    const fetchSuppliers = async () => {
      setSupplier({})
      if (!_.isEmpty(product)) {
        const { result } = await getSupplier(product.supplierId)
        setSupplier(result ?? {})
      }
    }

    const fetchProductsBySupplier = async () => {
      setSupplierProducts([])
      if (!_.isEmpty(product)) {
        const { result } = await getProudctBySupplier(product.supplierId)
        setSupplierProducts(result ?? [])
      }
    }

    const fetchProductsByCategory = async () => {
      setProductCategories([])
      if (!_.isEmpty(product)) {
        const { result } = await getProudctByCategory(product.categoryId)
        setProductCategories(result ?? [])
      }
    }

    fetchSuppliers()
    fetchProductsBySupplier()
    fetchProductsByCategory()

    if (!_.isEmpty(product)) {
      if (product.fileThumbnail) {
        setHoveredImageId(product.fileThumbnail.id)
      }

      setCombinedImages([...(product?.fileImages ?? []), product?.fileThumbnail])

      const productSelected = product.details?.reduce((lowest, product) => {
        const currentPrice = product.sellingPrice - product.discountAmount
        const lowestPrice = lowest.sellingPrice - lowest.discountAmount

        return currentPrice < lowestPrice ? product : lowest
      })

      const prices = product.details?.reduce(
        (acc, product) => {
          const currentPrice = product.sellingPrice - product.discountAmount
          acc.min = Math.min(acc.min, currentPrice)
          acc.max = Math.max(acc.max, currentPrice)
          return acc
        },
        { min: Infinity, max: -Infinity }
      )

      setProductPrice(prices ? `${prices.min} - ${prices.max}` : 0)

      setProductDetail(productSelected)
      setPropertySelected(
        productSelected.attributeValues.map((item) => ({
          propId: item.productAttributeId,
          propName: product?.attributes.find((x) => x.id === item.productAttributeId)?.name,
          propValue: item.value,
        }))
      )
    }
  }, [product])

  useEffect(() => {
    setQtyRemain(productDetail?.stock)
  }, [productDetail])

  useEffect(() => {
    if (!_.isEmpty(propertySelected)) {
      setProductDetail(
        product.details?.find((item) => {
          return arraysEqual(item.attributeValues, propertySelected)
        })
      )
    }
  }, [propertySelected])

  const arraysEqual = (productAttrs, properties) => productAttrs.length === properties.length && productAttrs.every((item) => propertyEqual(item, properties))

  const propertyEqual = (productAttr, properties) => properties.some((x) => x.propId === productAttr.productAttributeId && x.propValue === productAttr.value)

  const increase = (e) => {
    let _value = Number(e.target.value || quantityOrder) + 1
    if (_value > qtyRemain) {
      _value = qtyRemain
    } else if (qtyRemain === 0) {
      _value = 0
    }
    setQuantityOrder(_value)
  }

  const decrease = (e) => {
    let _value = Number(e.target.value || quantityOrder) - 1
    if (_value < 1 && _value <= qtyRemain) {
      _value = 1
    } else if (qtyRemain === 0) {
      _value = 0
    }
    setQuantityOrder(_value)
  }

  const handleChangeQuantity = (e) => {
    let _value = Number(e.target.value)
    if (qtyRemain !== undefined && _value > qtyRemain) {
      _value = qtyRemain
    } else if (_value < 1 && _value <= qtyRemain) {
      _value = 1
    } else {
      _value = 0
    }
    setQuantityOrder(_value)
  }

  const handleImageHover = (id) => {
    setHoveredImageId(id)
  }

  const handleChangeProperty = (propId, propName, propValue) => {
    const filterValues = product.details
      ?.filter((x) => x.attributeValues?.some((y) => y.productAttributeId === propId && y.value === propValue))
      .flatMap((x) => x.attributeValues)
      .filter((x) => x.productAttributeId !== propId)
      .filter((value, index, self) => self.findIndex((v) => v.id === value.id) === index)
      .map((x) => x.value)

    const updateProp = propertySelected.some((d) => d.propId === propId)
      ? propertySelected.map((d) => (d.propId === propId ? { ...d, propName, propValue } : d))
      : [...propertySelected, { propId, propName, propValue }]

    setPropertySelected(updateProp.filter((d) => d.propId === propId || (d.propId !== propId && filterValues.includes(d.propValue))))
  }

  const handleAddToCart = (isPurchase) => {
    if (quantityOrder === 0) {
      showToast(intl.formatMessage({ id: 'Order.OutOfStock' }), TOAST_TYPES.WARNING)
      return
    }

    if (_.isEmpty(productDetail)) {
      showToast(`${intl.formatMessage({ id: 'PleaseChoose' })} ${intl.formatMessage({ id: 'Product.ProductClassification' })}`, TOAST_TYPES.WARNING)
      return
    }

    checkLogin()

    const cartDetails = shoppingCart?.shoppingCartDetails
    let cartDetail = cartDetails?.find((d) => d.productId === product.id)
    let cartDetailId = cartDetails?.find((d) => d.productId === product.id)?.id ?? GUID_NEWID()

    if ((cartDetail?.quantity ?? 0) + quantityOrder > productDetail.stock) {
      showToast(intl.formatMessage({ id: 'Order.OutOfStock' }), TOAST_TYPES.WARNING)
      return
    }

    const productOrder = {
      id: cartDetailId,
      productId: product?.id,
      productDetailId: productDetail?.id,
      productCode: productDetail?.code,
      productName: product?.productName,
      quantity: quantityOrder,
      sellingPrice: productDetail?.sellingPrice,
      discountAmount: productDetail?.discountAmount,
      fileThumbnail: product.fileThumbnail.url,
      productPropertyName: propertySelected?.map((item) => `${item.propName}: ${item.propValue}`).join('; '),
      supplierId: product?.supplierId,
    }

    addToCart(productOrder)

    if (isPurchase) {
      navigate(`/cart/${cartDetailId}`)
    }
  }

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  }

  return (
    <div className='product-detail'>
      <div className='header-page'>
        <div className='title'>
          <h1 className='mb-4'>{intl.formatMessage({ id: 'ProductDetail' })}</h1>
          <Breadcrumb />
        </div>
      </div>
      <div className='d-flex'>
        <div className='col-xl-9'>
          <section className='card d-flex flex-row mt-4 me-8'>
            <div className='product-image-content'>
              <div className='d-flex flex-column'>
                <div className='border rounded'>
                  {!_.isEmpty(hoveredImageId) && (
                    <ImageMagnify
                      {...{
                        smallImage: {
                          alt: 'Zoomable Image',
                          isFluidWidth: true,
                          src: combinedImages.find((x) => x.id === hoveredImageId)?.url,
                          className: 'product-image rounded',
                        },
                        largeImage: {
                          src: combinedImages.find((x) => x.id === hoveredImageId)?.url,
                          width: 1200,
                          height: 800,
                        },
                        isHintEnabled: true,
                        enlargedImagePosition: 'over',
                        enlargedImageContainerDimensions: {
                          width: '200%',
                          height: '200%',
                        },
                        enlargedImageContainerStyle: {
                          zIndex: 1000,
                        },
                        shouldHideHintAfterFirstActivation: false,
                      }}
                    />
                  )}
                </div>

                <Slider {...sliderSettings} className='mt-4'>
                  {!_.isEmpty(product?.fileThumbnail) &&
                    !_.isEmpty(product?.fileImages) &&
                    combinedImages?.map((item) => (
                      <div
                        key={item.id}
                        className={`rounded ${hoveredImageId === item.id ? 'product-image-hovered' : 'border'}`}
                        onMouseEnter={() => handleImageHover(item.id)}
                      >
                        <div className='product-image-wrapper'>
                          <img className='product-image rounded' src={item.url} alt='' />
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            </div>

            <div className='d-flex flex-auto product-info-content'>
              <div className='flex-auto flex-column product-info-wrapper'>
                <div className='product-title'>
                  <h2>{product?.name}</h2>
                </div>

                <div className='d-flex my-2'>
                  <div className='d-flex cursor-pointer'>
                    <div className='product-rate-text product-rate fw-bold fs-5 border-bottom'>{product?.rating}</div>
                    <Rate value={product?.rating} isDisabled={true} />
                  </div>

                  <span className='ps-4 border-end'></span>
                  <div className='d-flex ps-4 cursor-pointer'>
                    <NumberFormat value={product?.quantityProductReviewed ?? 0} className='product-rate-text border-bottom fw-bold' />
                    <span className='text-dark'>{intl.formatMessage({ id: 'Rating' })}</span>
                  </div>

                  {/* <span className='ps-4 border-end'></span>
                  <div className='d-flex ps-4 cursor-pointer'>
                     <NumberFormat value={product?.quantityProductSold} className='product-rate-text border-bottom fw-bold' />
                    <div className='text-dark'>{intl.formatMessage({ id: 'Sold' })}</div>
                  </div> */}
                </div>

                <div className='flex flex-column bg-light p-3 mt-4 rounded '>
                  <section className='d-flex align-items-center' aria-live='polite'>
                    <div className='d-flex align-items-center product-price-wrapper'>
                      <NumberFormat
                        amount={_.isEmpty(productDetail) ? productPrice : productDetail?.sellingPrice - productDetail?.discountAmount}
                        suffix={DEFAULT_VALUE.VND}
                        className={'product-price-new'}
                      />
                      {productDetail?.discountAmount > 0 && (
                        <div className='d-flex align-items-center ms-2'>
                          <NumberFormat amount={productDetail?.sellingPrice} suffix={DEFAULT_VALUE.VND} className={'product-price-old'} />
                        </div>
                      )}
                    </div>
                  </section>
                </div>

                {product?.attributes?.length > 0 && (
                  <div className={`bg-light p-3 my-2 prop-box rounded}`}>
                    <div className={`row`}>
                      {product.attributes.map((d) => {
                        let attributeValues = product?.details
                          ?.flatMap((attv) => attv.attributeValues)
                          .filter((attr) => attr.productAttributeId === d.id && !_.isEmpty(attr.value))

                        const propId = propertySelected && propertySelected[0]?.propId
                        const propValue = propertySelected && propertySelected[0]?.propValue

                        const filterValues = product.details
                          ?.filter((x) => x.attributeValues?.some((y) => y.productAttributeId === propId && y.value === propValue))
                          .flatMap((x) => x.attributeValues)
                          .filter((x) => x.productAttributeId !== propId)
                          .filter((value, index, self) => self.findIndex((v) => v.id === value.id) === index)
                          .map((x) => x.value)

                        return (
                          <React.Fragment key={d.id}>
                            <div className='pt-2 col-xl-2 col-3 fw-bold'>{d.name}</div>
                            <div className='col-xl-10 col-9'>
                              <div role='group' className='app-toggle-group flex-wrap btn-group' style={{ margin: '0px -10px' }}>
                                {[...new Map(attributeValues.map((e) => [e.value, e])).values()]?.map((e) => {
                                  const isDisabled = d.id !== propId && !filterValues.includes(e.value)
                                  return (
                                    <label
                                      role='button'
                                      tabIndex='0'
                                      className={`fw-light text-dark rounded flex-grow-0 m-1 btn prop-btn-light ${
                                        propertySelected?.find((d) => d.propValue === e.value) ? 'prop-active' : ''
                                      } ${isDisabled ? 'disabled' : ''}`}
                                      onClick={!isDisabled ? () => handleChangeProperty(d.id, d.name, e.value) : null}
                                      key={e.id}
                                    >
                                      {e.value}
                                    </label>
                                  )
                                })}
                              </div>
                            </div>
                          </React.Fragment>
                        )
                      })}
                    </div>
                  </div>
                )}

                <div className='my-2 d-flex flex-auto align-items-center'>
                  <div className='pt-2 col-xl-2 col-3 fw-bold'>{intl.formatMessage({ id: 'Quantity' })}</div>
                  <div className='d-flex align-items-center'>
                    <div className='me-3'>
                      <div className='d-flex align-items-center'>
                        <button className='product-quantity' onClick={decrease}>
                          <i className='fa fa-minus text-dark'></i>
                        </button>
                        <Input className='product-quantity product-quantity-text' value={quantityOrder} onChange={handleChangeQuantity} />
                        <button aria-label='Increase' className='product-quantity' onClick={increase}>
                          <i className='fa fa-plus text-dark'></i>
                        </button>
                      </div>
                    </div>
                    <div>
                      {qtyRemain} {intl.formatMessage({ id: 'Product.ProductAvailable' })}
                    </div>
                  </div>
                </div>

                <div className='d-flex gap-4'>
                  <button type='button' className='btn btn-primary btn-lg fw-bold w-50' onClick={() => handleAddToCart(false)}>
                    <i className='fa fa-cart-plus me-3'></i>
                    {intl.formatMessage({ id: 'AddToCart' })}
                  </button>

                  <button type='button' className='btn btn-primary btn-lg fw-bold w-50' onClick={() => handleAddToCart(true)}>
                    {intl.formatMessage({ id: 'OrderNow' })}
                    <i className='fa fa-angle-right ms-3'></i>
                  </button>
                </div>

                {/* <div className='d-flex mt-4'>
                  <button type='button' className='btn btn-outline-light w-100 border'>
                    <i className='fa fa-share-square me-3'></i>
                    {intl.formatMessage({ id: 'Product.PostForSale' })}
                  </button>
                </div> */}
              </div>
            </div>
          </section>

          <ProductSupplier supplier={supplier} product={product} productDetail={productDetail} />

          {!_.isEmpty(supplierProducts) && <OtherProduct products={supplierProducts} supplierId={product?.supplierId} />}
        </div>

        <div className='col-xl-3 mt-4'>
          <div className='product-sidebar'>
            <div className='sidebar-widget' id='benefit-overview'>
              <div className='module_benefits'>
                <div className='buyer-benefits'>
                  <h4>
                    <span>{intl.formatMessage({ id: 'Product.MembershipBenefit' })}</span>
                  </h4>
                  <div className='buyer-item'>
                    <span className='golden-buyer-first-benefit'>{intl.formatMessage({ id: 'Product.Coupon80K' })} </span>
                    <a style={{ textDecoration: 'underline' }}>{intl.formatMessage({ id: 'ViewMore' })}</a>
                  </div>
                </div>
              </div>

              <div className='module_ta_plus'>
                <div className='ta-semi'>
                  <h3>
                    <div className='ta-header'>
                      <div className='ta-item'>
                        <span className='title'>{intl.formatMessage({ id: 'Product.ProtectiveMeasure' })}</span>
                      </div>
                    </div>
                  </h3>
                  <div className='semi-list'>
                    <div className='semi-item'>
                      <h4>
                        <img src={'/media/png/icons/payment-safe.png'} alt='' />
                        <div>
                          <span>{intl.formatMessage({ id: 'Footer.SecuredPayment' })}</span>
                        </div>
                      </h4>
                      <div>{intl.formatMessage({ id: 'Product.Policy' })}</div>
                    </div>
                    <div className='semi-item'>
                      <h4>
                        <img src={'/media/png/icons/policy-rerturn.png'} alt='' />
                        <div>
                          <span>{intl.formatMessage({ id: 'Product.RefundPolicy' })}</span>
                        </div>
                      </h4>
                      <div>{intl.formatMessage({ id: 'Product.RefundRequest' })}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='product-sidebar mt-8'>
            <div className='sidebar-widget'>
              <h5 className='section-title style-1 mb-5'>{intl.formatMessage({ id: 'Product.SimilarProduct' })}</h5>
              <div style={{ height: '285px', overflow: 'auto' }}>
                {productCategories
                  ?.filter((x) => !_.isEmpty(x.details))
                  .map((d, index) => {
                    const productDetail = d.details?.reduce((lowest, product) => {
                      const currentPrice = product.sellingPrice - product.discountAmount
                      const lowestPrice = lowest.sellingPrice - lowest.discountAmount

                      return currentPrice < lowestPrice ? product : lowest
                    })
                    return (
                      <div className='single-post' key={d.id + index}>
                        <Link className='hover-up' to={`/product/${d.id}`}>
                          <div className='image'>
                            <img src={d.fileThumbnail.url} alt='' />
                          </div>
                          <div className='content'>
                            <h5>{d.name}</h5>
                            <div className='product-item-text mb-1'>
                              <NumberFormat
                                amount={productDetail.sellingPrice - productDetail.discountAmount}
                                suffix='đ'
                                className={'text-primary fs-3 fw-bold'}
                              />
                              {productDetail.discountAmount > 0 && <NumberFormat amount={productDetail.sellingPrice} className='old-price' suffix='đ' />}
                            </div>
                            <Rate value={d.rating} isDisabled={true} />
                          </div>
                        </Link>
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>

          <div className='mt-8'>
            <NewsBanner news={[]} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductDetail
