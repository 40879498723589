import React, { useState, useEffect } from 'react'
import { Route, Routes, useParams, Navigate, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/components/breadcrumb/PageData'
import { useSupplierService } from '../../services/apiServices'
import SupplierHome from './SupplierHome'
import ListProduct from './ListProduct'
import Supplier from './Supplier'

const SupplierPage = () => {
  const intl = useIntl()
  const { supplierId } = useParams()
  const [supplier, setSupplier] = useState({})
  const { getByIdAsync } = useSupplierService()

  const navigate = useNavigate()

  useEffect(() => {
    fetchSupplier()
  }, [supplierId])

  const fetchSupplier = async () => {
    const { result } = await getByIdAsync(supplierId)
    console.log(supplierId, result)
    if (result) {
      setSupplier(result)
    } else {
      navigate(`/`)
    }
  }

  const loadBreadcrumbs = () => {
    let item = [
      { title: intl.formatMessage({ id: 'Home' }), path: '/home' },
      { title: intl.formatMessage({ id: 'Supplier' }), path: '/suppliers' },
    ]
    return item
  }

  return (
    <Routes>
      <Route path='/' element={<Navigate to='home' />} />
      <Route path='/' element={<Supplier supplier={supplier} />}>
        <Route
          path='home'
          element={
            <>
              <PageTitle breadcrumbs={loadBreadcrumbs} isHideTitle={true}>
                {supplier.companyName}
              </PageTitle>
              <SupplierHome supplier={supplier} />
            </>
          }
        />
        <Route
          path='products'
          element={
            <>
              <PageTitle breadcrumbs={loadBreadcrumbs} isHideTitle={true}>
                {supplier.companyName}
              </PageTitle>
              <ListProduct supplier={supplier} />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default SupplierPage
