import 'animate.css/animate.min.css'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

const CategoryBanners = ({ categories }) => {
  const intl = useIntl()

  return (
    <section id='home-category-banners'>
      <div className='row mb-8'>
        {categories.map((category, index) => {
          return (
            <div className='col-lg-4 col-md-6 mb-2' key={category.id}>
              <div className='banner-img hover-parent'>
                <img className='rounded' src={`/media/banners/banner-${index + 1}.png`} alt='' />
                <div className='banner-text'>
                  <h4 className='hover-up w-100'>{category.name}</h4>
                  <Link to={`/categories/${category.id}`} className='btn btn-primary btn-sm fw-bolder d-flex align-items-center gap-1 w-150px p-4'>
                    {intl.formatMessage({ id: 'OrderNow' })} <i className='ms-1 fa fa-arrow-right'></i>
                  </Link>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default CategoryBanners
