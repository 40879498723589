import React, { useState } from 'react'
import { Nav } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import '../../../assets/sass/imageCarouse.scss'


import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Categories = ({ categories }) => {
  const intl = useIntl()
  const [hoveredIndex, setHoveredIndex] = useState(null)
  const sliderRef = React.useRef()
  const baseSlidesToShow = 10

  const handleCategoryHover = (index) => {
    setHoveredIndex(index)
  }

  const getNumberCyclically = (currentIndex) => {
    return (currentIndex % 15) + 1
  }

  const handlePrev = () => {
    sliderRef.current.slickPrev()
  }

  const handleNext = () => {
    sliderRef.current.slickNext()
  }

  const generateResponsiveSettings = (baseSlidesToShow) => {
    return [
      { breakpoint: 1500, settings: { slidesToShow: baseSlidesToShow - 1 } },
      { breakpoint: 1350, settings: { slidesToShow: baseSlidesToShow - 2 } },
      { breakpoint: 1200, settings: { slidesToShow: baseSlidesToShow - 3 } },
      { breakpoint: 1050, settings: { slidesToShow: baseSlidesToShow - 4 } },
      { breakpoint: 900, settings: { slidesToShow: baseSlidesToShow - 5 } },
      { breakpoint: 750, settings: { slidesToShow: baseSlidesToShow - 6 } },
      { breakpoint: 650, settings: { slidesToShow: baseSlidesToShow - 7 } },
    ]
  }

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 1,
    arrows: false,
    responsive: generateResponsiveSettings(baseSlidesToShow),
  }

  return (
    <section id='home-category-list'>
      <div className='section-title'>
        <div className='title'>
          <h3>{intl.formatMessage({ id: 'Categories' })}</h3>
          <Nav className='list-inline nav nav-tabs links d-none d-lg-flex'>
            {categories.slice(0, 5).map((d) => {
              return (
                <Nav.Item className='list-inline-item' key={d.id}>
                  <Link to={`/categories/${d.id}`} className={`nav-link`}>
                    {d.name}
                  </Link>
                </Nav.Item>
              )
            })}
          </Nav>
        </div>
        <div className='slider-arrow slider-arrow-1 flex-right'>
          <span className='slider-btn slider-prev slick-arrow' onClick={handlePrev}>
            <i className='fa-solid fa-arrow-left' />
          </span>
          <span className='slider-btn slider-next slick-arrow' onClick={handleNext}>
            <i className='fa-solid fa-arrow-right' />
          </span>
        </div>
      </div>

      <div className='slider-container'>
        <Slider ref={sliderRef} {...sliderSettings} className='mt-4'>
          {categories.map((category, index) => {
            let style = {
              backgroundImage: `url(${category?.fileThumbnail?.url})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }
            return (
              <div
                key={category.id}
                title={category?.name}
                className={`image-carousel-item rounded ${hoveredIndex === index ? 'hovered' : ''}`}
                onMouseEnter={() => handleCategoryHover(index)}
                onMouseLeave={handleCategoryHover}
              >
                <Link className={`category-list-category-grid rounded h-100 bg-${getNumberCyclically(index)}`} to={`/categories/${category.id}`}>
                  <div className='category-item-group'>
                    <div className='category-item-image'>
                      <div className='position-relative h-100'>
                        <div className='h-100 opacity-1 img' style={style}></div>
                      </div>
                    </div>
                    <div className='category-item-text-wrapper'>
                      <div className='category-item-text'>{category?.name}</div>
                    </div>
                  </div>
                </Link>
              </div>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}

export default Categories
