import { Route, Routes, Navigate, Outlet } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/components/breadcrumb/PageData'
import ChangePassword from './ChangePassword'
import Overview from './Overview'
import AccountHeader from './AccountHeader'
import { AccountHeaderProvider } from './components/AccountHeaderContext'
import Affiliates from './Affiliates'

const UserProfilePage = () => {
  const intl = useIntl()
  const loadBreadcrumbs = () => {
    let item = [{ title: intl.formatMessage({ id: 'Home' }), path: '/home' }]
    return item
  }

  return (
    <Routes>
      <Route
        key='user-profile'
        path='user-profile/*'
        element={
          <AccountHeaderProvider>
            <AccountHeader />
            <Outlet />
          </AccountHeaderProvider>
        }
      >
        <Route
          path='overview'
          element={
            <>
              <PageTitle breadcrumbs={loadBreadcrumbs} isHideTitle={true}>
                {intl.formatMessage({ id: 'User.UserProfile' })}
              </PageTitle>
              <Overview />
            </>
          }
        />
        <Route
          path='affiliates'
          element={
            <>
              <PageTitle breadcrumbs={loadBreadcrumbs} isHideTitle={true}>
                {intl.formatMessage({ id: 'IntroduceCollaborator' })}
              </PageTitle>
              <Affiliates />
            </>
          }
        />
        
        <Route
          path='change-password'
          element={
            <>
              <PageTitle breadcrumbs={loadBreadcrumbs} isHideTitle={true}>
                {intl.formatMessage({ id: 'User.ResetPassword' })}
              </PageTitle>
              <ChangePassword />
            </>
          }
        />
        
      </Route>
    </Routes>
  )
}

export default UserProfilePage
