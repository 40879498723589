import PurchasePage from './PurchasePage'
import Purchases from './Purchases'
import PurchasedProducts from './PurchasedProducts'
import { ORDER_STATUS_ENUM } from '../../utilities/enum'

export const purchaseRoutes = [
  {
    title: 'Purchase.ALL',
    type: ORDER_STATUS_ENUM.ALL,
  },
  {
    title: 'Purchase.WAITFORCONFIRM',
    type: ORDER_STATUS_ENUM.WAITFORCONFIRM,
  },
  {
    title: 'Purchase.PREPARINGGOODS',
    type: ORDER_STATUS_ENUM.PREPARINGGOODS,
  },
  {
    title: 'Purchase.WAITFORDELIVERY',
    type: ORDER_STATUS_ENUM.WAITFORDELIVERY,
  },
  {
    title: 'Purchase.DELIVERYRECEIVED',
    type: ORDER_STATUS_ENUM.DELIVERYRECEIVED,
  },
  {
    title: 'Purchase.COMPLETE',
    type: ORDER_STATUS_ENUM.COMPLETE,
  },
  {
    title: 'Purchase.CANCEL',
    type: ORDER_STATUS_ENUM.CANCEL,
  },
  {
    title: 'Purchase.RETURNREFUND',
    type: ORDER_STATUS_ENUM.RETURNREFUND,
  },
]

export { PurchasePage, Purchases, PurchasedProducts }
