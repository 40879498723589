import _ from 'lodash'
import { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import ReasonCancelModal from './ReasonCancelModal'
import { ORDER_STATUS_ENUM } from '../../utilities/enum'
import { DEFAULT_VALUE } from '../../utilities/constant'
import NumberFormat from '../../components/shared/NumberFormat'
import { useOrderService } from '../../services/apiServices'

const PurchasedProducts = ({ orders }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { getByIdAsync } = useOrderService()
  const [isOpenCancel, setIsOpenCancel] = useState(false)
  const [selectedOrder, setPayload] = useState(null)

  const handleCancel = async (order) => {
    setPayload(order)
    setIsOpenCancel(true)
  }

  const handleRepurchase = async (order) => {
    const { error, result: selectedCarts } = await getByIdAsync(order.id)
    if (selectedCarts) {
      navigate('/checkout', { state: { selectedCarts } })
    } else {
      console.log(error)
    }
  }

  const statusMessage = {
    [ORDER_STATUS_ENUM.WAITFORCONFIRM]: () => <></>,
    [ORDER_STATUS_ENUM.PREPARINGGOODS]: (item) => (
      <span className='purchase-action-desciption'>
        {intl.formatMessage({ id: 'Product.PreparedShipping' })}
        <div className='purchase-order-drawer ps-1'>
          <u className='purchase-date-delivery'>{item.receiveDate}</u>
        </div>
      </span>
    ),
    [ORDER_STATUS_ENUM.WAITFORDELIVERY]: () => <span className='purchase-action-desciption'>{intl.formatMessage({ id: 'Product.ClickReceive' })}</span>,

    [ORDER_STATUS_ENUM.COMPLETE]: () => <span className='purchase-action-desciption'>{intl.formatMessage({ id: 'Product.WaitingSellerReview' })}</span>,
    [ORDER_STATUS_ENUM.CANCEL]: () => <span className='purchase-action-desciption'>{intl.formatMessage({ id: 'Product.CanceledByYou' })}</span>,
    [ORDER_STATUS_ENUM.RETURNREFUND]: () => <></>,
  }

  const statusAction = {
    [ORDER_STATUS_ENUM.WAITFORCONFIRM]: (item) => (
      <div className='purchase-action-primary'>
        <button className='stardust-button purchase-action-text' onClick={() => handleCancel(item)}>
          <FormattedMessage id='CancelOrder' />
        </button>
      </div>
    ),
    [ORDER_STATUS_ENUM.PREPARINGGOODS]: (item) => (
      <div className='purchase-action-primary'>
        <button className='stardust-button purchase-action-text' onClick={() => handleCancel(item)}>
          <FormattedMessage id='CancelOrder' />
        </button>
      </div>
    ),
    [ORDER_STATUS_ENUM.WAITFORDELIVERY]: (item) => (
      <>
        <div className='purchase-action-success'>
          <button className='stardust-button stardust-button-primary purchase-action-text' onClick={() => handleCancel(item)}>
            {intl.formatMessage({ id: 'Product.ReceivedGoods' })}
          </button>
        </div>
        <div className='purchase-action-primary'>
          <button className='stardust-button purchase-action-text'>{intl.formatMessage({ id: 'Product.ReturnRefundRequest' })}</button>
        </div>
      </>
    ),
    [ORDER_STATUS_ENUM.COMPLETE]: () => (
      <>
        <div className='purchase-action-success'>
          <button className='stardust-button stardust-button-primary purchase-action-text'>{intl.formatMessage({ id: 'Product.Repurchase' })}</button>
        </div>
        <div className='purchase-action-primary'>
          <button className='stardust-button purchase-action-text'>{intl.formatMessage({ id: 'Product.ReviewShop' })}</button>
        </div>
      </>
    ),
    [ORDER_STATUS_ENUM.CANCEL]: (item) => (
      <div className='purchase-action-success'>
        <button className='stardust-button stardust-button-primary purchase-action-text' onClick={() => handleRepurchase(item)}>
          {intl.formatMessage({ id: 'Product.Repurchase' })}
        </button>
      </div>
    ),
    [ORDER_STATUS_ENUM.RETURNREFUND]: () => <></>,
  }

  return (
    <>
      {!_.isEmpty(orders) ? (
        orders.map((order) => (
          <div className='purchase-suppliers' key={order.id}>
            <div className='purchase-supplier-wrap'>
              <section>
                <div className='purchase-supplier-flex'>
                  <div className='purchase-supplier-left'>
                    <div className='purchase-supplier-name'>{order.supplier.companyName}</div>
                    <a className='purchase-supplier-visit' href={`/supplier/${order.supplierId}`}>
                      <div className='stardust-button'>
                        <img src={'/media/svg/icons/shop.svg'} alt='' className='checkout-svg-icon checkout-svg-icon fs-3' />
                        <span>
                          <FormattedMessage id='VisitShop' />
                        </span>
                      </div>
                    </a>
                  </div>
                  <div className='purchase-supplier-status'>
                    <div className='purchase-supplier-status-name'>{intl.formatMessage({ id: `Purchase.${order.statusName.toUpperCase()}` })}</div>
                  </div>
                </div>
              </section>
              <div className='purchase-supplier-bottom' />
              {order.details.map((product) => (
                <section key={product.id}>
                  <div className='purchase-product-section'>
                    <div className='purchase-product-wrap'>
                      <div className='purchase-product-flex'>
                        <div className='purchase-product-left p-0'>
                          <img src={product.productImageUrl} className='purchase-product-image' alt='' />
                          <div className='purchase-product-info'>
                            <div className='purchase-product-name'>
                              <span className='purchase-product-middle'>{product.productName}</span>
                            </div>
                            <div className='d-flex justify-content-between w-100'>
                              <div className='purchase-product-category'>
                                {product.productProperty && (
                                  <>
                                    <FormattedMessage id='ProductCategorizing' />: {product.productProperty}
                                  </>
                                )}
                              </div>
                              <div className='purchase-product-qty'>x{product.quantity}</div>
                            </div>
                            <div className='d-flex justify-content-between w-100'>
                              <span className='purchase-product-return purchase-product-green'>{intl.formatMessage({ id: 'Product.FreeReturn' })}</span>
                              <div className='purchase-product-price'>
                                {product.discountAmount > 0 && (
                                  <NumberFormat amount={product.sellingPrice} className='purchase-product-price-discount' suffix={DEFAULT_VALUE.VND} />
                                )}
                                <NumberFormat
                                  amount={product.sellingPrice - product.discountAmount}
                                  className='purchase-product-price-total purchase-success-color'
                                  suffix={DEFAULT_VALUE.VND}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='purchase-product-end' />
                    </div>
                  </div>
                </section>
              ))}
            </div>

            <div className='purchase-supplier-bottom-wrap'>
              <div className='purchase-amount-section'>
                <div className='purchase-amount-flex'>
                  <span className='purchase-cursor'>
                    <div className='purchase-amount-icon'>
                      <img src={'/media/svg/icons/money-bag.svg'} alt='' className='icon-voucher-line' />
                    </div>
                  </span>
                  <label className='purchase-amount-text'>
                    <FormattedMessage id='TotalAmount' />:
                  </label>

                  <NumberFormat amount={order.grandTotal} className='purchase-amount-text-total' suffix={DEFAULT_VALUE.VND} />
                </div>
              </div>
              <div className='purchase-action-section'>
                <div className='purchase-action-status'>{statusMessage[order.status](order)}</div>
                <div className='purchase-action-button-wrap'>{statusAction[order.status](order)}</div>
              </div>
            </div>

            {isOpenCancel && <ReasonCancelModal closeModal={setIsOpenCancel} order={selectedOrder} />}
          </div>
        ))
      ) : (
        <section>
          <div className='return-no-content-wrap'>
            <div className='return-no-content-flex'>
              <div className='return-no-content-image'></div>
              <h2 className='return-no-content-text'>
                <FormattedMessage id='NoOrder' />
              </h2>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default PurchasedProducts
