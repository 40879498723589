import { useIntl } from 'react-intl'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getEnums, ORDER_STATUS_ENUM, ReasonCancelEnum } from '../../utilities/enum'
import Modal from '../../components/shared/Modal'
import Button from '../../components/shared/Button'
import { useOrderService } from '../../services/apiServices'

const ReasonCancelModal = ({ closeModal, order }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { cancelAsync } = useOrderService()

  const [reasonCancels] = useState(() => getEnums(ReasonCancelEnum, intl))
  const [reason, setReason] = useState(null)

  const handleSave = async () => {
    if (!reason) return

    const { error, result } = await cancelAsync(order.id, reason.value)
    if (result) {
      navigate(`/purchase/${ORDER_STATUS_ENUM.CANCEL}`)
    } else {
      console.log(error)
    }
    closeModal(false)
  }

  return (
    <Modal title={intl.formatMessage({ id: 'Product.ChooseReasonCancel' })} modalSize='mw-650px' closeModal={closeModal}>
      <div className='shipping-unit'>
        <div className='d-flex flex-column mb-3 mt-3 cursor-pointer'>
          {reasonCancels.map((d, index) => (
            <div
              key={d.value + index}
              className={`shipping-item d-flex align-items-center ${d.value === reason?.value ? 'active' : ''} ${index === 0 ? '' : 'mt-2'}`}
              onClick={() => setReason(d)}
            >
              <div className='me-3'>
                <div className='shipping-type'>{d.label}</div>
                <div className='shipping-time gray-color'></div>
                <div className='shipping-voucher gray-color'></div>
              </div>
              {d.value === reason?.value && (
                <div className='ml-auto'>
                  <img src={'/media/svg/icons/selected.svg'} alt='' className='stardust-icon stardust-icon-tick' />
                </div>
              )}
            </div>
          ))}
        </div>

        <div className='text-center pt-10'>
          <Button
            onClick={() => closeModal(false)}
            title={intl.formatMessage({ id: 'Close' })}
            className={'btn btn-light me-3'}
            tabIndex={4}
            icon='CloseOutlined'
          />
          <Button onClick={() => handleSave()} title={intl.formatMessage({ id: 'Complete' })} className={'btn btn-primary'} tabIndex={5} icon='SaveOutlined' />
        </div>
      </div>
    </Modal>
  )
}

export default ReasonCancelModal
