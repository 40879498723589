import React, { createContext, useContext, useState, useEffect } from 'react'
import { useSupplierService, useProductService, useOrderService, useAffiliateService } from '../../../services/apiServices'
import { useAuth } from '../../../components/auth/AuthContext'

const AccountHeaderContext = createContext()

export const useAccountHeaderContext = () => useContext(AccountHeaderContext)

export const AccountHeaderProvider = ({ children }) => {
  const { user } = useAuth()
  const { getBalanceAsync } = useAffiliateService()
  const [reloadWithdrawalHistories, setReloadWithdrawalHistories] = useState(false)
  const [accountBalance, setAccountBalance] = useState(0)

  useEffect(() => {
    const getAccountBalance = async () => {
      const { result } = await getBalanceAsync()
      setAccountBalance(result ?? 0)
    }

    getAccountBalance()
  }, [user])

  return (
    <AccountHeaderContext.Provider
      value={{
        reloadWithdrawalHistories,
        setReloadWithdrawalHistories,
        accountBalance,
      }}
    >
      {children}
    </AccountHeaderContext.Provider>
  )
}
