import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSignalR } from '../../contexts/SignalRContext'
import Icon from '../../shared/Icon'

const Notification = () => {
  const intl = useIntl()
  const { notificationConnection } = useSignalR()
  const [notifications, setNotifications] = useState([])
  const [countUnread, setCountUnread] = useState(0)

  useEffect(() => {
    if (notificationConnection) {
      const handleReceiveNotification = (notification) => {
        setNotifications((notifications) => [...notifications, notification])
      }

      notificationConnection.on('ReceiveNotification', handleReceiveNotification)

      return () => {
        notificationConnection.off('ReceiveNotification', handleReceiveNotification)
      }
    }
  }, [notificationConnection])

  return (
    <div className='d-flex'>
      <div className='content cursor-pointer' data-kt-menu-trigger='hover' data-kt-menu-attach='parent' data-kt-menu-placement='bottom-end'>
        <i className='fa fa-bell text-white' aria-hidden='true'></i>
        <span className='ms-1'>
          <FormattedMessage id='Notifications' />
        </span>
      </div>

      <div className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px' data-kt-menu='true'>
        <div className='d-flex flex-column bgi-no-repeat rounded-top' style={{ backgroundImage: `url(/media/patterns/header-bg.jpg)` }}>
          <h3 className='text-white fw-bold px-8 my-4'>
            <FormattedMessage id='Notifications' />
            <span className='badge badge-danger ms-1 mb-1 fs-8 ps-3 float-end'>
              {countUnread} <FormattedMessage id='News' />{' '}
            </span>
          </h3>
        </div>

        <div className='scroll-y mh-325px my-5 px-8'>
          {notifications.map((item) => (
            <div key={item.id} className='d-flex flex-stack py-4'>
              <div className='d-flex align-items-center me-2'>
                <a href='#' className='text-gray-800 text-hover-primary fw-bold'>
                  {item.title}
                </a>

                <span className='badge badge-light fs-8'>{item.createdDateStr}</span>
              </div>
            </div>
          ))}
        </div>
        <div className='text-center border-top'>
          <Link to='/' className='btn btn-color-gray-600 btn-active-color-primary'>
            <FormattedMessage id='ViewAll' />
            <Icon iconName='arrow-right' className='fs-5' />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Notification
