import React, { useEffect, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import _ from 'lodash'
import Breadcrumb from '../../components/layout/components/breadcrumb/Breadcrumb'
import { getEnums, PaymentMethodEnum, PaymentStatusEnum, OrderAddressTypeEnum, VOUCHER_DISCOUNT_TYPE_ENUM, VOUCHER_TYPE_ENUM } from '../../utilities/enum'
import { useShopingCart } from '../../components/contexts/ShoppingCartContext'
import { useCustomerService, useVoucherService } from '../../services/apiServices'
import CheckoutHeader from './CheckoutHeader'
import CheckoutBody from './CheckoutBody'
import CheckoutCoupon from './CheckoutCoupon'
import CheckoutPayment from './CheckoutPayment'
import CheckoutTotal from './CheckoutTotal'

import '../../assets/sass/checkout.scss'
import { useAuth } from '../../components/auth/AuthContext'

const Checkout = () => {
  const intl = useIntl()
  const { shoppingCart } = useShopingCart()
  const { getByAffiliateIdAsync, getByUserIdAsync } = useCustomerService()
  const { getCurrentListAsync: getVoucher } = useVoucherService()
  const { userPermissions, user } = useAuth()

  const [paymentMethods] = useState(getEnums(PaymentMethodEnum, intl))

  const [selectedItems, setSelectedItems] = useState([])
  const [customerRelations, setCustomerRelations] = useState([])
  const [suppliers, setSuppliers] = useState([])

  const [selectedAddress, setSelectedAddress] = useState()
  const [coupons, setCoupons] = useState([])
  const [systemVoucherSelected, setSystemVoucherSelected] = useState(null)
  const [shipVoucherSelected, setShipVoucherSelected] = useState(null)
  const [paymentMethodSelected, setPaymentMethodSelected] = useState(paymentMethods[0])
  const [customerRelationSelected, setCustomerRelationSelected] = useState(null)
  const [totalAmount, setTotalAmount] = useState(0)

  useEffect(() => {
    setSelectedItems(shoppingCart?.shoppingCartDetails)
  }, [shoppingCart])

  useEffect(() => {
    const uniqueSuppliers = Object.values(
      selectedItems?.reduce((acc, item) => {
        if (!acc[item.supplierId]) {
          const totalAmount = selectedItems
            .filter((x) => x.supplierId === item.supplierId)
            .reduce((total, { discounted, quantity }) => total + discounted * quantity, 0)

          acc[item.supplierId] = {
            supplierId: item.supplierId,
            supplierName: item.supplierName,
            message: null,
            expectedDeliveryDate: null,
            shippingMethod: null,
            supplierVoucherId: null,
            supplierDiscount: null,
            shippingCost: null,
            totalAmount: totalAmount,
            warehouse: item.warehouse,
          }
        }
        return acc
      }, {}) ?? []
    )

    if (!_.isEmpty(selectedItems)) {
      fetchDataInit()
      setSuppliers(uniqueSuppliers)
      setTotalAmount(uniqueSuppliers?.reduce((acc, current) => acc + current.totalAmount, 0) ?? 0)
    }
  }, [selectedItems])

  const fetchDataInit = async () => {
    const { result } = await getVoucher()
    if (result) {
      setCoupons(result)
    }

    const { result: customers } = userPermissions ? await getByAffiliateIdAsync() : await getByUserIdAsync(user.profile.id)
    if (!userPermissions && customers) {
      setCustomerRelations([customers])
      setCustomerRelationSelected(customers)
    } else {
      setCustomerRelations(customers ?? [])
    }
  }

  const handleChangeSupplier = (updatedSupplier) => {
    const updateSuppliers = suppliers.map((supplier) => {
      if (supplier.supplierId === updatedSupplier.supplierId) {
        return { ...supplier, ...updatedSupplier }
      }
      return supplier
    })

    setSuppliers(updateSuppliers)
  }

  const prepareOrderModel = () => {
    const orders = suppliers?.map((supplier) => {
      const products = selectedItems.filter((x) => x.supplierId === supplier.supplierId)
      const sysCouponAmount = systemVoucherSelected
        ? systemVoucherSelected.discountType === VOUCHER_DISCOUNT_TYPE_ENUM.FIXED_PRICE
          ? systemVoucherSelected.discountValue
          : Math.min((supplier.totalAmount * systemVoucherSelected.discountValue) / 100, systemVoucherSelected.maxDiscountAmount)
        : 0

      return {
        ...supplier,
        appDiscount: sysCouponAmount,
        shippingDiscount: shipVoucherSelected?.discountValue ?? 0,
        paymentAmount: 0,
        paymentMethod: paymentMethodSelected?.value,
        paymentStatus: PaymentStatusEnum.Unpaid,
        appVoucherId: systemVoucherSelected?.id,
        shippingVoucherId: shipVoucherSelected?.id,
        customerId: customerRelationSelected?.id,
        details: products?.map((product) => ({
          id: product.id,
          productId: product.productId,
          productCode: product.productCode,
          quantity: product.quantity,
          sellingPrice: product.sellingPrice,
          discountAmount: product.discountAmount,
          commissionAmount: product.commissionAmount,
          productProperty: product.productPropertyName,
        })),
        addresses: [
          {
            type: OrderAddressTypeEnum?.ReceivingAddress,
            provinceId: selectedAddress?.provinceId,
            districtId: selectedAddress?.districtId,
            wardId: selectedAddress?.wardId,
            addressLine: selectedAddress?.addressLine,
            latitude: selectedAddress?.latitude,
            longitude: selectedAddress?.longitude,
          },
          {
            type: OrderAddressTypeEnum?.DeliveryAddress,
            provinceId: supplier.warehouse.address?.provinceId,
            districtId: supplier.warehouse.address?.districtId,
            wardId: supplier.warehouse.address?.wardId,
            addressLine: supplier.warehouse.address?.addressLine,
            latitude: supplier.warehouse.address?.latitude,
            longitude: supplier.warehouse.address?.longitude,
          },
        ],
      }
    })
    return orders
  }

  return (
    <div className='checkout'>
      <div className='header-page'>
        <div className='title'>
          <h1 className='mb-4'>{intl.formatMessage({ id: 'Checkout' })}</h1>
          <Breadcrumb />
        </div>
      </div>
      <div className='checkout-main mt-4'>
        <CheckoutHeader
          customerRelations={customerRelations}
          customerRelationSelected={customerRelationSelected}
          onChangeCustomerRelation={setCustomerRelationSelected}
          selectedAddress={selectedAddress}
          onChangeAddress={setSelectedAddress}
        />

        {suppliers?.map((d, index) => (
          <div className='checkout-suppliers' key={d.supplierId + index}>
            <div className='checkout-table-header'>
              <div className='checkout-table-column'>
                <div className='checkout-table-text-left checkout-flex-4'>
                  <div className='checkout-column-product d-flex align-items-center gap-2'>
                    <h2 className='checkout-supplier-name'>{d.supplierName}</h2>

                    <a className='purchase-supplier-visit' href={`/supplier/${d.supplierId}`}>
                      <div className='stardust-button'>
                        <img src={'/media/svg/icons/shop.svg'} alt='' className='checkout-svg-icon fs-3' />
                        <span>
                          <FormattedMessage id='VisitShop' />
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
                <div className='checkout-table-text-left checkout-flex-2'></div>
                <div className='checkout-table-text-left'>{intl.formatMessage({ id: 'UnitPrice' })}</div>
                <div className='checkout-table-text-left'>{intl.formatMessage({ id: 'Quantity' })}</div>
                <div className='checkout-table-text-left checkout-flex-2'>{intl.formatMessage({ id: 'TotalAmount' })}</div>
              </div>
            </div>
            <div className='checkout-table-body'>
              <CheckoutBody
                key={d.supplierId}
                productOrders={selectedItems.filter((e) => e.supplierId === d.supplierId)}
                supplier={d}
                onChangeSupplier={handleChangeSupplier}
                coupons={coupons.filter((d) => d.discountType === VOUCHER_TYPE_ENUM.VOUCHER_SUPPLIER)}
              />
            </div>
          </div>
        ))}
        <div className='d-flex row-cols-2'>
          <CheckoutCoupon
            coupons={coupons.filter((d) => (d.minOrderAmount == null || d.minOrderAmount <= totalAmount) && d.type === VOUCHER_TYPE_ENUM.VOUCHER_SHIPPING)}
            voucherSelected={shipVoucherSelected}
            onChangeVoucher={setShipVoucherSelected}
            couponType={VOUCHER_TYPE_ENUM.VOUCHER_SHIPPING}
          />

          <CheckoutCoupon
            coupons={coupons.filter((d) => (d.minOrderAmount == null || d.minOrderAmount <= totalAmount) && d.type === VOUCHER_TYPE_ENUM.VOUCHER_SYSTEM)}
            voucherSelected={systemVoucherSelected}
            onChangeVoucher={setSystemVoucherSelected}
            couponType={VOUCHER_TYPE_ENUM.VOUCHER_SYSTEM}
          />
        </div>
        <div className='checkout-payments'>
          <CheckoutPayment paymentMethods={paymentMethods} paymentMethodSelected={paymentMethodSelected} onChangePaymentMethod={setPaymentMethodSelected} />
          <CheckoutTotal orders={prepareOrderModel()} customer={customerRelationSelected} address={selectedAddress} />
        </div>
      </div>
    </div>
  )
}

export default Checkout
