import { forwardRef } from 'react'
import { Select as AntSelect, Space } from 'antd'
import { useIntl } from 'react-intl'

const Select = forwardRef((props, ref) => {
  const intl = useIntl()
  let { options, value, onChange, onBlur, className, tabIndex, isValid, errorMessage, readOnly, placeholder, isClearable } = props

  return (
    <>
      <Space
        direction='vertical'
        style={{
          width: '100%',
        }}
      >
        <AntSelect
          style={{
            width: '100%',
          }}
          ref={ref}
          showSearch
          allowClear={isClearable}
          value={options?.find((x) => x.value === value)}
          placeholder={placeholder ?? intl.formatMessage({ id: 'SelectAnOption' })}
          optionFilterProp='label'
          className={className}
          onChange={(e) => onChange(e)}
          onBlur={onBlur}
          options={options}
          disabled={readOnly}
          tabIndex={tabIndex}
          status={isValid === false ? 'error' : ''}
          getPopupContainer={(trigger) => trigger.parentNode}
          size='large'
        />
      </Space>
      {isValid === false && (
        <p className='mt-0 mb-0 text-danger' style={{ whiteSpace: 'pre-line' }}>
          {errorMessage}
        </p>
      )}
    </>
  )
})

export default Select
