import { useEffect } from 'react'
import Button from './Button'

const Modal = (props) => {
  const { title, closeModal, children, modalSize, paddingLess = false } = props
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const handleCloseModal = () => {
    closeModal(false)
  }

  return (
    <>
      <div className='modal fade show d-block' id='kt_modal' role='dialog' tabIndex={-1} aria-modal='true'>
        <div className={`modal-dialog modal-dialog-centered ${modalSize ?? 'mw-950px'}`}>
          <div className='modal-content text-upperCase'>
            <div className='modal-header p-4 d-flex justify-content-between align-items-center'>
              <h3 className='fw-bolder fs-4'>{title}</h3>
              <Button className='btn-icon btn-active-light-danger' onClick={() => handleCloseModal()} icon='CloseOutlined' />
            </div>

            <div className={`modal-body ${paddingLess ? 'p-0' : ''}`}>{children}</div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export default Modal
