import React from 'react'
import ReactDOM from 'react-dom/client'
import AppRoutes from './routing/AppRoutes'
import { AuthProvider } from './components/auth/AuthContext'

import './assets/sass/style.scss'
import './assets/sass/plugins.scss'
import './assets/sass/style.react.scss'
import './assets/sass/common.scss'
import { I18nProvider } from './components/contexts/I18nContext'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <AuthProvider>
    <I18nProvider>
      <AppRoutes />
    </I18nProvider>
  </AuthProvider>
)
