import { useState } from 'react'
import SystemVoucherModal from './SystemVoucherModal'
import { useIntl } from 'react-intl'
import { VOUCHER_DISCOUNT_TYPE_ENUM, VOUCHER_TYPE_ENUM } from '../../utilities/enum'
import NumberFormat from '../../components/shared/NumberFormat'
import { DEFAULT_VALUE } from '../../utilities/constant'

const CheckoutCoupon = ({ coupons, voucherSelected, onChangeVoucher, couponType }) => {
  const intl = useIntl()
  const [isOpenSystemVoucher, setIsOpenSystemVoucher] = useState(false)
  const title =
    couponType === VOUCHER_TYPE_ENUM.VOUCHER_SHIPPING
      ? 'Coupon.FreeShip'
      : couponType === VOUCHER_TYPE_ENUM.VOUCHER_SUPPLIER
      ? 'Checkout.ShopVoucher'
      : 'Checkout.GreenVoucher'
  return (
    <div className='checkout-coupons'>
      <div className='checkout-coupon-wrap'>
        <div className='d-flex justify-content-between'>
          <div className='p-1'>
            <div className='checkout-voucher-icon'>
              <img src={'/media/svg/icons/coupon.svg'} alt='' className='me-2' />
              <span>{intl.formatMessage({ id: title })}</span>
            </div>
          </div>
          <div className='p-1'>
            {voucherSelected && (
              <span className='color-brand'>
                {voucherSelected.name + ' - '}
                &nbsp;(
                <NumberFormat
                  amount={voucherSelected.discountValue}
                  className='color-brand'
                  suffix={voucherSelected.discountType === VOUCHER_DISCOUNT_TYPE_ENUM.PERCENTAGE ? DEFAULT_VALUE.PERCENT : DEFAULT_VALUE.VND}
                />
                )
              </span>
            )}
          </div>
          <div className='p-1'>
            <button className='checkout-voucher-button-text' onClick={() => setIsOpenSystemVoucher(true)}>
              <span>{intl.formatMessage({ id: 'Checkout.ChooseVoucher' })}</span>
            </button>
          </div>
        </div>
      </div>

      {isOpenSystemVoucher && (
        <SystemVoucherModal
          closeModal={setIsOpenSystemVoucher}
          voucherSelected={voucherSelected}
          coupons={coupons}
          onChangeVoucher={onChangeVoucher}
          couponType={couponType}
        />
      )}
    </div>
  )
}

export default CheckoutCoupon
