import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useLocationService, useCustomerService } from '../../services/apiServices'
import { CONTROL_TYPE, FormControl, validationAllComponents } from '../../components/shared/FormControl'
import Button from '../../components/shared/Button'
import Modal from '../../components/shared/Modal'
import { showToast, TOAST_TYPES } from '../../components/shared/Toast'
import { useAuth } from '../../components/auth/AuthContext'
import { addToFormData } from '../../utilities/sharedFunction'

const CreateUserModal = ({ editCustomer, closeModal, fetchCustomers }) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  const { user } = useAuth()
  const { getProvincesAsync, getDistrictsAsync, getWardsAsync } = useLocationService()
  const { insertAsync, updateAsync } = useCustomerService()
  let tabIndex = 1

  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const [wards, setWards] = useState([])

  const [firstName, setFirstName] = useState(editCustomer?.firstName ?? null)
  const [lastName, setLastName] = useState(editCustomer?.lastName ?? null)
  const [phoneNumber, setPhoneNumber] = useState(editCustomer?.phoneNumber ?? null)
  const [email, setEmail] = useState(editCustomer?.email ?? null)
  const [provinceId, setProvinceId] = useState(null)
  const [districtId, setDistrictId] = useState(null)
  const [wardId, setWardId] = useState(null)
  const [addressLine, setAddressLine] = useState(null)
  const [isDefault, setIsDefault] = useState(true)

  useEffect(() => {
    const fetchProvinces = async () => {
      const { result } = await getProvincesAsync()
      if (result) {
        setProvinces(
          result.map((item) => {
            return { value: item.id, label: item.name }
          })
        )
      }
    }

    fetchProvinces()
  }, [])

  useEffect(() => {
    const fetchDistricts = async (provinceId) => {
      const { result } = await getDistrictsAsync(provinceId)
      if (result) {
        const districtList = result.map((item) => ({ value: item.id, label: item.name }))

        setDistricts(districtList)

        if (!districtList.some((district) => district.value === districtId)) {
          setDistrictId(null)
        }
      }
    }

    if (provinceId) {
      fetchDistricts(provinceId)
    } else {
      setDistricts([])
      setDistrictId(null)
    }
  }, [provinceId])

  useEffect(() => {
    const fetchWards = async (districtId) => {
      const { result } = await getWardsAsync(districtId)
      if (result) {
        const wardsList = result.map((item) => ({ value: item.id, label: item.name }))

        setWards(wardsList)

        if (!wardsList.some((ward) => ward.value === wardId)) {
          setWardId(null)
        }
      }
    }

    if (districtId) {
      fetchWards(districtId)
    } else {
      setWards([])
      setWardId(null)
    }
  }, [districtId])

  const handleSave = async () => {
    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    const formData = new FormData()

    addToFormData(formData, 'id', editCustomer?.id)
    addToFormData(formData, 'affiliateId', user?.profile.id)
    addToFormData(formData, 'firstName', firstName)
    addToFormData(formData, 'lastName', lastName)
    addToFormData(formData, 'phoneNumber', phoneNumber)
    addToFormData(formData, 'email', email)
    addToFormData(formData, 'isActive', true)
    addToFormData(formData, 'isDefault', true)
    addToFormData(formData, 'address', {
      provinceId,
      districtId,
      wardId,
      addressLine,
      isActive: true
    })

    const { error } = editCustomer?.isAdded ? await insertAsync(formData) : await updateAsync(formData)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      closeModal(false)
      fetchCustomers()
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
  }

  return (
    <Modal title={intl.formatMessage({ id: 'Customer.Addnew' })} closeModal={closeModal} modalSize='mw-650px'>
      <form className='form'>
        <div
          className='d-flex flex-column'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.customerName = ref)}
                tabIndex={tabIndex++}
                controlType={CONTROL_TYPE.INPUT}
                label={intl.formatMessage({ id: 'Ecom.Affiliate.FirstName' })}
                value={firstName}
                isRequired={true}
                onChange={setFirstName}
              />
            </div>
            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.customerName = ref)}
                tabIndex={tabIndex++}
                controlType={CONTROL_TYPE.INPUT}
                label={intl.formatMessage({ id: 'Ecom.Affiliate.LastName' })}
                value={lastName}
                isRequired={true}
                onChange={setLastName}
              />
            </div>
            <div className='col-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.phoneNumber = ref)}
                tabIndex={tabIndex++}
                controlType={CONTROL_TYPE.PHONENUMBER}
                label={intl.formatMessage({ id: 'PhoneNumber' })}
                value={phoneNumber}
                isRequired={true}
                onChange={setPhoneNumber}
              />
            </div>
            <div className='col-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.email = ref)}
                tabIndex={tabIndex++}
                controlType={CONTROL_TYPE.INPUT}
                label={intl.formatMessage({ id: 'Email' })}
                isRequired={true}
                value={email}
                onChange={setEmail}
              />
            </div>
            {editCustomer?.isAdded && (
              <>
                <div className='col-6 mb-3'>
                  <FormControl
                    controlType={CONTROL_TYPE.SELECT}
                    ref={(ref) => (componentRefs.current.provinceId = ref)}
                    label={intl.formatMessage({ id: 'Province' })}
                    isRequired={true}
                    value={provinceId}
                    onChange={setProvinceId}
                    tabIndex={tabIndex++}
                    options={provinces}
                  />
                </div>
                <div className='col-6 mb-3'>
                  <FormControl
                    controlType={CONTROL_TYPE.SELECT}
                    ref={(ref) => (componentRefs.current.districtId = ref)}
                    label={intl.formatMessage({ id: 'District' })}
                    isRequired={true}
                    value={districtId}
                    onChange={setDistrictId}
                    tabIndex={tabIndex++}
                    options={districts}
                  />
                </div>
                <div className='col-12 mb-3'>
                  <FormControl
                    controlType={CONTROL_TYPE.SELECT}
                    ref={(ref) => (componentRefs.current.wardId = ref)}
                    label={intl.formatMessage({ id: 'Ward' })}
                    isRequired={true}
                    value={wardId}
                    onChange={setWardId}
                    tabIndex={tabIndex++}
                    options={wards}
                  />
                </div>
                <div className='col-12 mb-3'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.addressLine = ref)}
                    controlType={CONTROL_TYPE.TEXTAREA}
                    label={intl.formatMessage({ id: 'AddressLine' })}
                    value={addressLine}
                    rows={2}
                    onChange={setAddressLine}
                    tabIndex={tabIndex++}
                    isRequired={true}
                  />
                </div>

                <div className='col-12 mb-3'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.isDefault = ref)}
                    tabIndex={6}
                    controlType={CONTROL_TYPE.CHECKBOX}
                    label={intl.formatMessage({ id: 'Default' })}
                    value={isDefault}
                    onChange={setIsDefault}
                    isFromSwitch={true}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className='text-center pt-5'>
          <Button
            onClick={() => closeModal(false)}
            title={intl.formatMessage({ id: 'Close' })}
            className={'btn btn-light me-3'}
            icon='SaveOutlined'
            tabIndex={7}
          >
            <FormattedMessage id='Close' />
          </Button>
          <Button onClick={handleSave} title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} tabIndex={8} icon='CloseOutlined'>
            <FormattedMessage id='Save' />
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default CreateUserModal
