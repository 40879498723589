import { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { FEATURED_PRODUCT_TYPE_ENUM } from '../../../utilities/enum'
import { useFeaturedProductService } from '../../../services/apiServices'
import PageNumber from '../../../components/shared/PageNumber'
import { DEFAULT_VALUE } from '../../../utilities/constant'
import Product from '../../../components/shared/Product'

const FeaturedProducts = () => {
  const intl = useIntl()
  const { getCurrentListAsync: getFeaturedProducts } = useFeaturedProductService()

  const [featuredProducts, setFeaturedProducts] = useState([])
  const [selectedItem, setSelectedItem] = useState(FEATURED_PRODUCT_TYPE_ENUM.TOPSELLING)
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize] = useState(20)
  const [totalPages, setTotalPages] = useState(0)

  const [productGroups] = useState(
    Object.keys(FEATURED_PRODUCT_TYPE_ENUM).map((key) => ({
      value: FEATURED_PRODUCT_TYPE_ENUM[key],
      name: intl.formatMessage({ id: `Ecom.FeaturedProduct.${key}` }),
    }))
  )

  useEffect(() => {
    fetchFeaturedProducts()
  }, [selectedItem, currentPage])

  const fetchFeaturedProducts = async () => {
    try {
      const { result } = await getFeaturedProducts(selectedItem, {
        pageIndex: currentPage,
        pageSize: pageSize,
      })

      if (result) {
        setFeaturedProducts(result.items)
        setTotalPages(result.totalPageCount)
      } else {
        setFeaturedProducts([])
        setTotalPages(0)
      }
    } catch (error) {
      console.error('Error fetching initial productPriority:', error)
    }
  }

  return (
    <section id='home-product-suggestions'>
      <div className='mb-8 mb-xl-14'>
        <div className='section-title d-flex flex-column align-items-start justify-content-between flex-lg-row align-items-lg-center'>
          <h3 className='min-w-250px'>
            <FormattedMessage id='PopularProduct' />
          </h3>

          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder justify-content-lg-end justify-content-start gap-4 gap-xl-8'>
            {productGroups.map((item) => {
              return (
                <li className='nav-item' key={item.value}>
                  <a onClick={() => setSelectedItem(item.value)} className={`nav-link m-0 text-active-primary ${item.value === selectedItem ? 'active' : ''}`}>
                    {item.name}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>

        <div className='row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5'>
          <Product products={featuredProducts.map((x) => x.product)} />
        </div>
        <div className='p-4'>
          <PageNumber currentPage={currentPage} totalPages={totalPages} className='pagination-lg' onPageChange={setCurrentPage} />
        </div>
      </div>
    </section>
  )
}

export default FeaturedProducts
