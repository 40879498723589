import { useState } from 'react'
import { useIntl } from 'react-intl'
import Modal from '../../components/shared/Modal'
import Button from '../../components/shared/Button'

const CustomerRelationModal = ({ closeModal, customerRelationSelected, setCustomerRelationSelected, customerRelations }) => {
  const intl = useIntl()
  const [customer, setCustomer] = useState(customerRelationSelected)

  const handleSave = () => {
    setCustomerRelationSelected(customer)
    closeModal(false)
  }

  return (
    <Modal title={intl.formatMessage({ id: 'Checkout.ChooseCustomer' })} modalSize='mw-750px' closeModal={closeModal} paddingLess={true}>
      <div className='shipping-unit d-flex justify-content-between flex-column min-h-250px'>
        <div>
          {customerRelations?.map((d, index) => (
            <div key={index} className='d-flex flex-column cursor-pointer' onClick={() => setCustomer(d)}>
              <div className={`shipping-item ${customer?.id === d.id ? 'active' : ''} d-flex align-items-center`}>
                <div className='me-5'>
                  <div className='shipping-type'>{d.fullName + ` (${d.phoneNumber})`}</div>
                </div>
                {customer?.id === d.id && (
                  <div className='ml-auto'>
                    <img src={'/media/svg/icons/selected.svg'} alt='' className='stardust-icon stardust-icon-tick' />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className='text-center py-4'>
          <Button
            onClick={() => closeModal(false)}
            title={intl.formatMessage({ id: 'Close' })}
            className={'btn btn-light me-3'}
            tabIndex={4}
            icon='CloseOutlined'
          />
          <Button onClick={() => handleSave()} title={intl.formatMessage({ id: 'Complete' })} className={'btn btn-primary'} tabIndex={5} icon='SaveOutlined' />
        </div>
      </div>
    </Modal>
  )
}

export default CustomerRelationModal
