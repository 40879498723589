import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useAuth } from '../../components/auth/AuthContext'
import { DEFAULT_VALUE } from '../../utilities/constant'
import { ORDER_STATUS_ENUM } from '../../utilities/enum'
import Icon from '../../components/shared/Icon'
import CreateAddressModal from './CreateAddressModal'
import CreateUserModal from './CreateUserModal'
import Breadcrumb from '../../components/layout/components/breadcrumb/Breadcrumb'
import Table from '../../components/shared/Table'
import NumberFormat from '../../components/shared/NumberFormat'
import { Tooltip } from 'antd'
import Confirm from '../../components/shared/Confirm'
import Button from '../../components/shared/Button'
import { useCustomerService, useOrderService } from '../../services/apiServices'

import '../../assets/sass/purchase.scss'
import { showToast, TOAST_TYPES } from '../../components/shared/Toast'

const Customers = () => {
  const intl = useIntl()
  const { user } = useAuth()
  const { getListAsync, deleteAsync, deleteCustomerAddressAsync } = useCustomerService()
  const { getListAsync: getOrders } = useOrderService()


  const [customerUpdate, setCustomerUpdate] = useState(null)
  const [customerSelected, setCustomerSelected] = useState(null)
  const [customers, setCustomers] = useState([])

  const [addresses, setAddresses] = useState([])
  const [address, setAddress] = useState(null)
  const [isEditCustomer, setIsEditCustomer] = useState(false)
  const [isEditAddress, setIsEditAddress] = useState(false)
  const [tabInfo, setTabInfo] = useState(1)

  const [loadCustomer, setLoadCustomer] = useState(false)
  const [loadOrder, setLoadOrder] = useState(false)

  const statusColor = {
    [ORDER_STATUS_ENUM.WAITFORCONFIRM]: 'primary text-light',
    [ORDER_STATUS_ENUM.PREPARINGGOODS]: 'info text-light',
    [ORDER_STATUS_ENUM.WAITFORDELIVERY]: 'warning text-light',
    [ORDER_STATUS_ENUM.COMPLETE]: 'success text-light',
    [ORDER_STATUS_ENUM.CANCEL]: 'danger text-light',
    [ORDER_STATUS_ENUM.RETURNREFUND]: 'secondary text-dark',
  }

  useEffect(() => {
    setLoadCustomer(true)
  }, [])

  useEffect(() => {
    if (!_.isEmpty(customers)) {
      setCustomerSelected(customerSelected ?? customers[0])
    }
  }, [customers])

  useEffect(() => {
    if (!_.isEmpty(customerSelected)) {
      setLoadOrder(true)
      setAddresses(customerSelected.address)
    }
  }, [customerSelected])

  const fetchCustomers = async (pageIndex, pageSize) => {
    setLoadCustomer(false)
    const result = await getListAsync({ pageIndex, pageSize, affiliateId: user?.profile?.affiliate?.id ?? 'unauthorized' })
    const { result: customers } = result
    setCustomers(customers.items)

    if (!_.isEmpty(customerSelected)) {
      var item = customers.items.find((x) => x.id === customerSelected.id)
      setAddresses(item.address)
    }

    return result
  }

  const fetchOrders = async (pageIndex, pageSize) => {
    setLoadOrder(false)
    return await getOrders({ pageIndex, pageSize, customerId: customerSelected?.id })
  }

  const hanleCreateCustomer = () => {
    setCustomerUpdate({ isAdded: true })
    setIsEditCustomer(true)
  }

  const handleEditCustomer = (item) => {
    setCustomerUpdate(item)
    setIsEditCustomer(true)
  }

  const handleCreateAddress = () => {
    setAddress({ isAdded: true })
    setIsEditAddress(true)
  }

  const handleEditAddress = (item) => {
    setAddress(item)
    setIsEditAddress(true)
  }

  const handleRemoveAddress = async (id) => {
    const { error } = await deleteCustomerAddressAsync(id)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
      setLoadCustomer(true)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteError' })}`, TOAST_TYPES.ERROR)
    }
  }

  const handleRemove = async (id) => {
    const { error } = await deleteAsync(id)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
      setLoadCustomer(true)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteError' })}`, TOAST_TYPES.ERROR)
    }
  }

  const renderAddress = () => {
    return (
      <div className='table-responsive p-4'>
        <table className='table dataTable align-middle table-row-dashed table-hover'>
          <tbody className='fw-semibold'>
            {_.map(addresses, function (item, index) {
              return (
                <tr key={index}>
                  <td>
                    <div className='d-flex align-items-center'>{item.fullAddress}</div>
                    {item.isDefault && <div className='ms-1 badge bg-danger text-light'>{intl.formatMessage({ id: 'Default' })}</div>}
                  </td>
                  <td className='min-w-100px d-flex justify-content-end gap-2'>
                    <Tooltip title={intl.formatMessage({ id: 'Edit' })}>
                      <div onClick={() => handleEditAddress(item)} className='btn btn-sm btn-icon btn-light-primary'>
                        <Icon iconName='EditOutlined' />
                      </div>
                    </Tooltip>

                    <Confirm
                      title={intl.formatMessage({ id: 'Notifications' })}
                      description={intl.formatMessage({ id: 'Confirm.Remove' })}
                      onConfirm={() => handleRemoveAddress(item.id)}
                      tooltip={intl.formatMessage({ id: 'Delete' })}
                      button={
                        <div className='btn btn-sm btn-icon btn-light-danger'>
                          <Icon iconName='DeleteOutlined' />
                        </div>
                      }
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <a className='btn btn-sm btn-primary' onClick={handleCreateAddress}>
          <Icon iconName='plus' className='fs-3' />
          {intl.formatMessage({ id: 'AddNewAddress' })}
        </a>
      </div>
    )
  }

  const columnsOrder = [
    {
      title: `${intl.formatMessage({ id: 'Order.Code' })}`,
      dataIndex: 'orderCode',
      sorter: (a, b) => a.orderCode.localeCompare(b.orderCode),
      width: 200,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Order.Status' })}`,
      key: 'orderStatus',
      sorter: (a, b) => a.title.localeCompare(b.title),
      width: 150,
      showSorterTooltip: false,
      render: (item) => (
        <div className={`badge bg-${statusColor[item.orderStatus]}`}>
          <FormattedMessage id={`Purchase.${item.orderStatusName}`} />
        </div>
      ),
    },
    {
      title: `${intl.formatMessage({ id: 'Order.Amount' })}`,
      dataIndex: 'grandTotal',
      width: 100,
      showSorterTooltip: false,
      sorter: (a, b) => a.grandTotal - b.grandTotal,
      render: (grandTotal) => <NumberFormat amount={grandTotal} suffix='đ' />,
    },
    {
      title: `${intl.formatMessage({ id: 'Order.Rewards' })}`,
      key: 'rewards',
      width: 100,
      showSorterTooltip: false,
      render: (item) => {
        const totalCommission = item.orderDetails.reduce((sum, detail) => sum + detail.totalCommission, 0)
        return <NumberFormat amount={totalCommission} suffix='đ' />
      },
    },
    {
      title: `${intl.formatMessage({ id: 'Order.Date' })}`,
      dataIndex: 'orderDateStr',
      sorter: (a, b) => a.orderDateStr - b.orderDateStr,
      width: 80,
      showSorterTooltip: false,
    },
  ]

  const columnsCustomer = [
    {
      title: `${intl.formatMessage({ id: 'CustomerName' })}`,
      dataIndex: 'fullName',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      width: 200,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'PhoneNumber' })}`,
      dataIndex: 'phoneNumber',
      width: 100,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Email' })}`,
      dataIndex: 'email',
      width: 100,
      showSorterTooltip: false,
    },
    {
      title: '',
      key: 'action',
      width: 60,
      render: (item) => (
        <div className='d-flex justify-content-center flex-shrink-0 gap-2'>
          <Tooltip title={intl.formatMessage({ id: 'Edit' })}>
            <div onClick={() => handleEditCustomer(item)} className='btn btn-sm btn-icon btn-light-primary'>
              <Icon iconName='EditOutlined' />
            </div>
          </Tooltip>

          <Tooltip title={intl.formatMessage({ id: 'Detail' })}>
            <div onClick={() => setCustomerSelected(item)} className='btn btn-sm btn-icon btn-light-info'>
              <Icon iconName='EyeOutlined' />
            </div>
          </Tooltip>

          <Confirm
            title={intl.formatMessage({ id: 'Notifications' })}
            description={intl.formatMessage({ id: 'Confirm.Remove' })}
            onConfirm={() => handleRemove(item.id)}
            tooltip={intl.formatMessage({ id: 'Delete' })}
            button={
              <div className='btn btn-sm btn-icon btn-light-danger'>
                <Icon iconName='DeleteOutlined' />
              </div>
            }
          />
        </div>
      ),
    },
  ]

  const customertoolbar = (
    <div className='d-flex flex-column flex-md-row justify-content-between gap-4'>
      <div className='d-flex gap-4'>
        <Button
          title={intl.formatMessage({ id: 'AddNew' })}
          icon='PlusOutlined'
          className='btn-primary'
          onClick={hanleCreateCustomer}
          tabIndex={0}
          disabled={false}
        />
      </div>
    </div>
  )
  return (
    <div className='customer'>
      <div className='header-page'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='title'>
            <h1 className='mb-4'>{intl.formatMessage({ id: 'Customer' })}</h1>
            <Breadcrumb />
          </div>
        </div>
      </div>

      <div className='d-flex flex-column flex-xl-row gap-4 pt-8'>
        <div className='flex-lg-row-fluid'>
          <Table columns={columnsCustomer} fetchData={fetchCustomers} onReload={loadCustomer} toolbar={customertoolbar} />
        </div>

        <div className='flex-column flex-lg-row-auto w-100 w-xl-750px'>
          <div className='card border'>
            <div className='d-flex flex-center flex-column p-4'>
              <div className='symbol symbol-100px symbol-circle mb-2'>
                <img
                  src={_.isEmpty(customerSelected?.avatar) ? '/media/images/no-avatar.png' : DEFAULT_VALUE.BASE_URL + customerSelected?.avatar}
                  alt='image'
                />
              </div>
              <a className='fs-3 text-gray-800 text-hover-primary fw-bold mb-1'>{customerSelected?.fullName}</a>
              <div className='d-flex fw-semibold text-muted gap-2'>
                {customerSelected?.email && (
                  <div className='d-flex align-items-center'>
                    <Icon iconName='sms' className='fs-2 me-1' />
                    {customerSelected?.email}
                  </div>
                )}
                {customerSelected?.phone && (
                  <div className='d-flex align-items-center'>
                    <Icon iconName='phone' className='fs-2 me-1' />
                    {customerSelected?.phone}
                  </div>
                )}
              </div>
            </div>
            <div className='purchase-right-nav'>
              <div className='purchase-right-wrap'>
                <section className='purchase-tablist'>
                  <a
                    className={`purchase-col-all nav-link ${tabInfo === 1 ? 'active text-active-primary purchase-border-primary' : ''} p-4`}
                    onClick={() => setTabInfo(1)}
                  >
                    <span className='purchase-text-all'>{intl.formatMessage({ id: 'Address' })}</span>
                  </a>
                  <a
                    className={`purchase-col-all nav-link ${tabInfo === 2 ? 'active text-active-primary purchase-border-primary' : ''} p-4`}
                    onClick={() => setTabInfo(2)}
                  >
                    <span className='purchase-text-all'>{intl.formatMessage({ id: 'PurchaseOrder' })}</span>
                  </a>
                </section>
              </div>
            </div>
            <div>
              {!_.isEmpty(customerSelected) ? (
                tabInfo === 1 ? (
                  renderAddress()
                ) : (
                  <Table columns={columnsOrder} fetchData={fetchOrders} onReload={loadOrder} />
                )
              ) : (
                <div className='text-center mt-4 px-3 pt-3'>
                  <svg width='48' height='48' viewBox='0 0 48 48' fill='none'>
                    <path d='M44 21V4H4V44H21' stroke='#9B9FAC' strokeWidth='4' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round'></path>
                    <path d='M45 45L29 29' stroke='#9B9FAC' strokeWidth='4' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round'></path>
                    <path d='M29 44V29H44' stroke='#9B9FAC' strokeWidth='4' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round'></path>
                  </svg>
                  <p className='text-center'>{intl.formatMessage({ id: 'Customer.ChooseCustomer' })}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isEditCustomer && <CreateUserModal editCustomer={customerUpdate} closeModal={setIsEditCustomer} fetchCustomers={() => setLoadCustomer(true)} />}
      {isEditAddress && <CreateAddressModal customerId={customerSelected.id} editAddress={address} closeModal={setIsEditAddress} fetchAddresses={() => setLoadCustomer(true)} />}
    </div>
  )
}

export default Customers
