import { useIntl } from 'react-intl'
import Breadcrumb from '../../components/layout/components/breadcrumb/Breadcrumb'
import { useEffect, useRef, useState } from 'react'
import { useCategoryService, useSupplierMembershipService, useSupplierService } from '../../services/apiServices'
import { getEnums, PARTNER_TYPE_ENUM } from '../../utilities/enum'
import { CONTROL_TYPE, FormControl, validationAllComponents } from '../../components/shared/FormControl'
import { addToFormData } from '../../utilities/sharedFunction'
import _ from 'lodash'
import { showToast, TOAST_TYPES } from '../../components/shared/Toast'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/shared/Button'

const RegisterSupplier = () => {
  const intl = useIntl()
  const componentRefs = useRef({})
  let tabIndex = 1
  const { getByIdAsync, insertAsync, updateAsync, approveAsync, rejectAsync } = useSupplierService()
  const { getCurrentListAsync } = useCategoryService()
  const { getListAsync: getSupplierMembershipsAsync } = useSupplierMembershipService()
  const ecomUrl = process.env.REACT_APP_SLEEK_ECOM
  const navigate = useNavigate()

  const [companyName, setCompanyName] = useState(null)
  const [contactName, setContactName] = useState(null)
  const [email, setEmail] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [address, setAddress] = useState(null)
  const [website, setWebsite] = useState(null)
  const [businessCode, setBusinessCode] = useState(null)
  const [taxCode, setTaxCode] = useState(null)
  const [logo, setLogo] = useState(null)
  const [fileLogo, setFileLogo] = useState([])
  const [businessLicense, setBusinessLicense] = useState(null)
  const [fileBusinessLicense, setFileBusinessLicense] = useState([])
  const [contract, setContract] = useState(null)
  const [fileContract, setFileContract] = useState([])
  const [isActive, setIsActive] = useState(true)
  const [status, setStatus] = useState(true)
  const [supplierMembershipId, setSupplierMembershipId] = useState(null)
  const [categoryId, setCategoryId] = useState(null)
  const [agreeProvideInformation, setAgreeProvideInformation] = useState(null)
  const [partnerTypeId, setPartnerTypeId] = useState(null)

  const [categories, setCategories] = useState([])
  const [supplierMemberships, setSupplierMemberships] = useState([])
  const partnerTypes = getEnums(PARTNER_TYPE_ENUM, intl)

  useEffect(() => {
    const fetchCategories = async () => {
      const { result } = await getCurrentListAsync()
      if (result) {
        setCategories(
          result.map((item) => {
            return { value: item.id, label: item.name }
          })
        )
      }
    }

    const fetchSupplierMemberships = async () => {
      const { result } = await getSupplierMembershipsAsync()
      if (result) {
        setSupplierMemberships(
          result.map((item) => {
            return { value: item.id, label: item.name }
          })
        )
      }
    }

    fetchCategories()
    fetchSupplierMemberships()
    setData()
  }, [])

  useEffect(() => {
    if (!agreeProvideInformation) {
      setAgreeProvideInformation(null)
    }
  }, [agreeProvideInformation])

  const setData = (item) => {
    setCompanyName(item?.companyName)
    setContactName(item?.contactName)
    setEmail(item?.email)
    setPhoneNumber(item?.phoneNumber)
    setAddress(item?.address)
    setWebsite(item?.website)
    setBusinessCode(item?.businessCode)
    setTaxCode(item?.taxCode)
    setLogo(item?.logo)
    setFileLogo(item?.fileLogo ?? [])
    setBusinessLicense(item?.businessLicense)
    setFileBusinessLicense(item?.fileBusinessLicense ?? [])
    setContract(item?.contract)
    setFileContract(item?.fileContract)
    setIsActive(item?.isActive ?? true)
    setStatus(item?.status)
    setSupplierMembershipId(item?.supplierMembershipId)
    setCategoryId(item?.categoryId)
    setAgreeProvideInformation(item?.agreeProvideInformation)
    setPartnerTypeId(item?.partnerTypeId)
  }

  const handleSave = async () => {
    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    const formData = new FormData()
    addToFormData(formData, 'companyName', companyName)
    addToFormData(formData, 'contactName', contactName)
    addToFormData(formData, 'phoneNumber', phoneNumber)
    addToFormData(formData, 'email', email)
    addToFormData(formData, 'address', address)
    addToFormData(formData, 'website', website)
    addToFormData(formData, 'businessCode', businessCode)
    addToFormData(formData, 'taxCode', taxCode)
    addToFormData(formData, 'logo', logo)
    addToFormData(formData, 'businessLicense', businessLicense)
    addToFormData(formData, 'contract', contract)
    addToFormData(formData, 'isActive', isActive)
    addToFormData(formData, 'categoryId', categoryId)
    addToFormData(formData, 'supplierMembershipId', supplierMembershipId)
    addToFormData(formData, 'agreeProvideInformation', agreeProvideInformation)
    addToFormData(formData, 'partnerTypeId', partnerTypeId)

    if (!_.isEmpty(fileLogo)) {
      let file = Array.isArray(fileLogo) ? fileLogo[0] : fileLogo
      addToFormData(formData, 'fileLogo', file.url ? null : file.originFileObj)
    }

    if (!_.isEmpty(fileBusinessLicense)) {
      let file = Array.isArray(fileBusinessLicense) ? fileBusinessLicense[0] : fileBusinessLicense
      addToFormData(formData, 'fileBusinessLicense', file.url ? null : file.originFileObj)
    }

    if (!_.isEmpty(fileContract)) {
      let file = Array.isArray(fileContract) ? fileContract[0] : fileContract
      addToFormData(formData, 'fileContract', file.url ? null : file.originFileObj)
    }

    const { error } = await insertAsync(formData)
    if (_.isEmpty(error)) {
      navigate('/confirmation', {
        state: {
          confirmMessage: {
            title: intl.formatMessage({ id: 'Email.ConfirmCreatedAccount' }),
            email: email,
            message: intl.formatMessage({ id: 'EmailRegisterSuccess' }, { email }),
            returnUrl: ecomUrl,
          },
        },
      })
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
    }
  }

  return (
    <div>
      <div className='header-page'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='title'>
            <h1 className='mb-4'>{intl.formatMessage({ id: 'Supplier.Register' })}</h1>
            <Breadcrumb />
          </div>
        </div>
      </div>

      <div className='card card-flush mt-5 shadow-sm'>
        <div className='card-body'>
          <form className='d-flex flex-column gap-4'>
            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.companyName = ref)}
                controlType={CONTROL_TYPE.INPUT}
                label={intl.formatMessage({ id: 'Ecom.Supplier.CompanyName' })}
                value={companyName}
                onChange={setCompanyName}
                tabIndex={tabIndex++}
                isRequired={true}
              />
            </div>

            <div className='row'>
              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.email = ref)}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Ecom.Supplier.Email' })}
                  value={email}
                  onChange={setEmail}
                  tabIndex={tabIndex++}
                  isRequired={true}
                  isEmail={true}
                />
              </div>

              <div className='col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.phoneNumber = ref)}
                  controlType={CONTROL_TYPE.PHONENUMBER}
                  label={intl.formatMessage({ id: 'Ecom.Supplier.PhoneNumber' })}
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  tabIndex={tabIndex++}
                  isRequired={true}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.contactName = ref)}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Ecom.Supplier.ContactName' })}
                  value={contactName}
                  onChange={setContactName}
                  tabIndex={tabIndex++}
                  isRequired={true}
                />
              </div>

              <div className='col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.partnerTypeId = ref)}
                  controlType={CONTROL_TYPE.SELECT}
                  label={intl.formatMessage({ id: 'Ecom.Supplier.PartnerType' })}
                  value={partnerTypeId}
                  onChange={setPartnerTypeId}
                  options={partnerTypes}
                  tabIndex={tabIndex++}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.businessCode = ref)}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Ecom.Supplier.BusinessCode' })}
                  value={businessCode}
                  onChange={setBusinessCode}
                  tabIndex={tabIndex++}
                  isRequired={true}
                />
              </div>

              <div className='col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.taxCode = ref)}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Ecom.Supplier.TaxCode' })}
                  value={taxCode}
                  onChange={setTaxCode}
                  tabIndex={tabIndex++}
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-6 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.categoryId = ref)}
                  controlType={CONTROL_TYPE.SELECT}
                  label={intl.formatMessage({ id: 'Ecom.Supplier.Category' })}
                  value={categoryId}
                  onChange={setCategoryId}
                  tabIndex={tabIndex++}
                  options={categories}
                />
              </div>
              <div className='col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.website = ref)}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Ecom.Supplier.Webstie' })}
                  value={website}
                  onChange={setWebsite}
                  tabIndex={tabIndex++}
                />
              </div>
            </div>

            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.address = ref)}
                controlType={CONTROL_TYPE.TEXTAREA}
                label={intl.formatMessage({ id: 'Ecom.Supplier.Address' })}
                value={address}
                onChange={setAddress}
                tabIndex={tabIndex++}
                rows={2}
                isRequired={true}
              />
            </div>

            <div className='col-md-12'>
              <FormControl
                ref={(ref) => (componentRefs.current.supplierMembershipId = ref)}
                controlType={CONTROL_TYPE.SELECT}
                label={intl.formatMessage({ id: 'Ecom.Supplier.SupplierMembership' })}
                value={supplierMembershipId}
                onChange={setSupplierMembershipId}
                tabIndex={tabIndex++}
                isRequired={true}
                options={supplierMemberships}
              />
            </div>

            <div className='row'>
              <div className='col-md-4 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.fileLogo = ref)}
                  controlType={CONTROL_TYPE.FILEUPLOAD}
                  label={intl.formatMessage({ id: 'Ecom.Supplier.Logo' })}
                  value={fileLogo}
                  onChange={setFileLogo}
                  accept='image/*,.jpg,.jpeg,.png,.gif,.bmp,.webp,.svg,.tiff,.ico,.pdf'
                  isMultiple={false}
                  tabIndex={tabIndex++}
                />
              </div>

              <div className='col-md-4 mb-4 mb-md-0'>
                <FormControl
                  ref={(ref) => (componentRefs.current.fileContract = ref)}
                  controlType={CONTROL_TYPE.FILEUPLOAD}
                  label={intl.formatMessage({ id: 'Ecom.Supplier.Contract' })}
                  value={fileContract}
                  onChange={setFileContract}
                  accept='image/*,.jpg,.jpeg,.png,.gif,.bmp,.webp,.svg,.tiff,.ico,.pdf'
                  isMultiple={false}
                  tabIndex={tabIndex++}
                />
              </div>

              <div className='col-md-4'>
                <FormControl
                  ref={(ref) => (componentRefs.current.fileBusinessLicense = ref)}
                  controlType={CONTROL_TYPE.FILEUPLOAD}
                  label={intl.formatMessage({ id: 'Ecom.Supplier.BusinessLicense' })}
                  value={fileBusinessLicense}
                  onChange={setFileBusinessLicense}
                  accept='image/*,.jpg,.jpeg,.png,.gif,.bmp,.webp,.svg,.tiff,.ico,.pdf'
                  isMultiple={false}
                  tabIndex={tabIndex++}
                />
              </div>
            </div>

            <FormControl
              ref={(ref) => (componentRefs.current.agreeProvideInformation = ref)}
              controlType={CONTROL_TYPE.CHECKBOX}
              label={intl.formatMessage({ id: 'Ecom.Supplier.AgreeProvideInformation' })}
              value={agreeProvideInformation}
              onChange={setAgreeProvideInformation}
              tabIndex={tabIndex++}
              isRequired={true}
            />
          </form>

          <div className='d-flex m-4 justify-content-center gap-4'>
            <Button
              title={intl.formatMessage({ id: 'Save' })}
              icon='SaveOutlined'
              className='btn-primary'
              onClick={handleSave}
              tabIndex={tabIndex++}
              disabled={false}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterSupplier
