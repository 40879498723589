/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation } from 'react-router'
import _ from 'lodash'
import { useAuth } from '../../components/auth/AuthContext'
import { checkIsActive } from '../../utilities/sharedFunction'
import Icon from '../../components/shared/Icon'
import { FormControl, CONTROL_TYPE } from '../../components/shared/FormControl'
import Breadcrumb from '../../components/layout/components/breadcrumb/Breadcrumb'
import { useUserService } from '../../services/apiServices'
import { ROLE } from '../../utilities/constant'

const AccountHeader = () => {
  const intl = useIntl()
  const { user } = useAuth()
  const location = useLocation()
  const componentRefs = useRef({})
  let tabIndex = 1
  const ecomUrl = process.env.REACT_APP_SLEEK_ECOM
  const { getByIdAsync } = useUserService()

  const [fileAvatarUrl, setFileAvatarUrl] = useState()
  const [currentUser, setCurrentUser] = useState(user)

  useEffect(() => {
    getData()
  }, [user])

  const getData = async () => {
    const { result } = await getByIdAsync(user?.profile?.id)
    setCurrentUser(result ?? {})
    setFileAvatarUrl([
      {
        url: result?.avatarUrl ?? `${ecomUrl}/media/images/no-avatar.png`,
      },
    ])
  }

  const handleCopyClick = (textToCopy) => {
    navigator.clipboard.writeText(textToCopy)
  }

  return (
    <div>
      <div className='header-page'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='title'>
            <h1 className='mb-4'>{intl.formatMessage({ id: 'User.UserProfile' })}</h1>
            <Breadcrumb />
          </div>
        </div>
      </div>
      <div className='card my-5'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4 avatar'>
              <FormControl
                ref={(ref) => (componentRefs.current.thumbnail = ref)}
                controlType={CONTROL_TYPE.FILEUPLOAD}
                label={intl.formatMessage({ id: 'Prodventory.Product.Thumbnail' })}
                value={fileAvatarUrl}
                onChange={setFileAvatarUrl}
                accept='.png,.jpg,.jpeg'
                isRequired={true}
                isMultiple={false}
                isAvatar={true}
                tabIndex={tabIndex++}
                isHideLabel={true}
              />
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center'>
                    <a className='text-gray-800 text-hover-primary fs-2 fw-bold me-2'>{user?.profile?.fullName}</a>
                  </div>

                  <div className='d-flex flex-wrap fw-semibold fs-6 pe-2 d-flex flex-column'>
                    <div className={`d-flex ${currentUser?.role === ROLE.CUSTOMER ? 'flex-column' : ''}`}>
                      <a href='#' className='d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2 gap-1'>
                        <Icon iconName='UserOutlined' />
                        {user?.profile?.role}
                      </a>
                      <a href='#' className='d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2 gap-1'>
                        <Icon iconName='MailOutlined' />
                        {user?.profile?.email}
                      </a>

                      {user?.profile?.phoneNumber && (
                        <a href='#' className='d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2 gap-1'>
                          <Icon iconName='PhoneOutlined' />
                          +84 {user?.profile?.phoneNumber}
                        </a>
                      )}
                    </div>

                    {currentUser?.role === ROLE.AFFILIATE && (
                      <div className='d-flex flex-column'>
                        <span className='fw-bold mb-0'>
                          Mã mời bạn:{' '}
                          <span className='text-primary fs-5 fw-bolder mb-0'>
                            {currentUser?.affiliate?.code ?? '...'}
                            <a className={'btn btn-sm btn-light-primary bg-transparent mx-3'} onClick={() => handleCopyClick(currentUser?.affiliate?.code)}>
                              <Icon iconName='CopyOutlined' />
                            </a>
                          </span>
                        </span>

                        <span className='fw-bold mb-0'>
                          Link mời bạn:{' '}
                          <span className='text-primary mb-0 text-break'>
                            {`${ecomUrl}/register/affiliate/${currentUser?.affiliate?.code}`}
                            <a
                              className={'btn btn-sm btn-light-primary bg-transparent mx-3'}
                              onClick={() => handleCopyClick(`${ecomUrl}/register/affiliate/${currentUser?.affiliate?.code}`)}
                            >
                              <Icon iconName='CopyOutlined' />
                            </a>
                          </span>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-6 fw-semibold flex-nowrap'>
              <li className='nav-item' key={'user-overview'}>
                <Link
                  to={'/user-profile/overview'}
                  className={`nav-link text-active-primary py-4 ${checkIsActive(location.pathname, 'overview') ? 'active' : ''}`}
                >
                  <FormattedMessage id={'User.UserProfile'} />
                </Link>
              </li>

              <li className='nav-item' key={'user-affiliates'}>
                <Link
                  to={'/user-profile/affiliates'}
                  className={`nav-link text-active-primary py-4 ${checkIsActive(location.pathname, 'affiliates') ? 'active' : ''}`}
                >
                  <FormattedMessage id={'IntroduceCollaborator'} />
                </Link>
              </li>

              <li className='nav-item' key={'user-changePassword'}>
                <Link
                  to={'/user-profile/change-password'}
                  className={`nav-link text-active-primary py-4 ${checkIsActive(location.pathname, 'change-password') ? 'active' : ''}`}
                >
                  <FormattedMessage id={'User.ChangePassword'} />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountHeader
