import { FormattedMessage, useIntl } from 'react-intl'

const FooterBanner = () => {
  const intl = useIntl()
  return (
    <section id='home-footer-banner' className='mb-10'>
      <div className='newsletter'>
        <div className='row d-none d-lg-flex'>
          <div className='col-lg-12'>
            <div className='position-relative newsletter-inner' style={{ background: `url('/media/banners/header-bg.png') no-repeat center` }}>
              <div className='newsletter-content'>
                <h2 className='mb-20 newsletter-title'>
                  <FormattedMessage id='Footer.StayHome' />
                </h2>
                <p className='mb-45'>
                  <FormattedMessage id='Footer.StartDaily' />{' '}
                  <span className='text-primary'>
                    <FormattedMessage id='Green24h' />
                  </span>
                </p>
                <form className='form-subcriber d-flex'>
                  <input
                    type='email'
                    placeholder={intl.formatMessage({ id: 'Footer.YourEmail' })}
                    style={{ background: `url('/media/png/icons/icon-plane.png') no-repeat 25px center` }}
                  />
                  <button className='btn' type='submit'>
                    <FormattedMessage id='OrderNow' />
                  </button>
                </form>
              </div>
              <img src={'/media/banners/footer-banner.png'} alt='newsletter' />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FooterBanner
