import { useEffect, useState } from 'react'
import DeliveryAddressModal from './DeliveryAddressModal'
import CustomerRelationModal from './CustomerRelationModal'
import { useIntl } from 'react-intl'
import _ from 'lodash'

const CheckoutHeader = ({ customerRelations, customerRelationSelected, onChangeCustomerRelation, selectedAddress, onChangeAddress }) => {
  const intl = useIntl()

  const [isOpenDeliveryAddress, setIsOpenDeliveryAddress] = useState(false)
  const [isOpenCustomer, setIsOpenCustomer] = useState(false)
  const [addresses, setAddresses] = useState([])

  useEffect(() => {
    if (customerRelationSelected && !_.isEmpty(customerRelationSelected?.address)) {
      setAddresses(customerRelationSelected.address)
      var defaultAdress =
        customerRelationSelected.address.length > 0 ? customerRelationSelected.address.find((x) => x.isDefault) || customerRelationSelected.address[0] : null
      onChangeAddress({ ...defaultAdress })
    }
  }, [customerRelationSelected])

  return (
    <div className='checkout-header'>
      <div className='checkout-header-wrap d-flex pe-8'>
        <div className='col-md-6 checkout-clear-both'>
          <div className='checkout-header-address-wrap'>
            <div className='checkout-header-address-flex'>
              <div className='checkout-header-address-icon'>
                <img src={'/media/svg/icons/people.svg'} alt='' className='checkout-svg-icon icon-user' />
              </div>
              <h2>{intl.formatMessage({ id: 'Customer' })}</h2>
            </div>
            <div className='checkout-body-address-wrap d-flex cursor-pointer pe-8' onClick={() => setIsOpenCustomer(true)}>
              <div className='checkout-body-address-content flex-fill' onClick={() => setIsOpenCustomer(true)}>
                {customerRelationSelected ? (
                  <>
                    <div className='checkout-body-address-phone flex-fill'>{customerRelationSelected?.fullName}</div>
                    <div className='checkout-body-address-phone flex-fill'>{customerRelationSelected?.phoneNumber}</div>
                  </>
                ) : (
                  <span className='checkout-body-address-phone flex-fill'>{intl.formatMessage({ id: 'Checkout.ChooseCustomer' })}</span>
                )}
              </div>
              <div className='checkout-body-address-btn-change'>{intl.formatMessage({ id: 'Change' })}</div>
            </div>
          </div>
        </div>

        <div className='col-md-6 ps-10'>
          <div className='checkout-header-address-wrap'>
            <div className='checkout-header-address-flex'>
              <div className='checkout-header-address-icon'>
                <img src={'/media/svg/icons/location.svg'} alt='' className='checkout-svg-icon icon-location-marker' />
              </div>
              <h2>{intl.formatMessage({ id: 'Checkout.DeliveryAddress' })}</h2>
            </div>
            <div className='checkout-body-address-wrap d-flex cursor-pointer' onClick={() => setIsOpenDeliveryAddress(true)}>
              <div className='checkout-body-address-content flex-fill'>
                <div className='checkout-body-address-phone flex-fill'>
                  {selectedAddress ? selectedAddress.fullAddress : <span>{intl.formatMessage({ id: 'Checkout.EnterAddress' })}</span>}
                </div>
              </div>
              <div className='checkout-body-address-btn-change'>{intl.formatMessage({ id: 'Change' })}</div>
            </div>
          </div>
        </div>
      </div>

      {isOpenDeliveryAddress && (
        <DeliveryAddressModal
          closeModal={setIsOpenDeliveryAddress}
          setSelectedAddress={onChangeAddress}
          addresses={addresses}
          selectedAddress={selectedAddress}
        />
      )}

      {isOpenCustomer && (
        <CustomerRelationModal
          closeModal={setIsOpenCustomer}
          customerRelationSelected={customerRelationSelected}
          setCustomerRelationSelected={onChangeCustomerRelation}
          customerRelations={customerRelations}
        />
      )}
    </div>
  )
}

export default CheckoutHeader
