import { FormattedMessage } from 'react-intl'
import ImageCarousel from '../../components/shared/ImageCarousel'
import Product from '../../components/shared/Product'
import { Link } from 'react-router-dom'

const OtherProduct = ({ products, supplierId }) => {
  const responsive = (baseSlidesToShow) => {
    return [
      { breakpoint: 1500, settings: { slidesToShow: baseSlidesToShow - 1 } },
      { breakpoint: 770, settings: { slidesToShow: baseSlidesToShow - 2 } },
      { breakpoint: 400, settings: { slidesToShow: baseSlidesToShow - 3 } },
    ]
  }
  return (
    <section>
      <div className='pt-8 me-8'>
        <div className='d-flex justify-content-between'>
          <h1 className='fw-bolder'>
            <FormattedMessage id='OtherProduct' />
          </h1>
          <Link to={`/supplier/${supplierId}/products`} className='fw-bold text-primary'>
            <FormattedMessage id='ViewAll' />
            <i className='fa fa-angle-right ms-2 text-primary' aria-hidden='true'></i>
          </Link>
        </div>

        <div className=''>
          <ImageCarousel Component={Product} products={products} columns={4} responsive={responsive} />
        </div>
      </div>
    </section>
  )
}

export default OtherProduct
