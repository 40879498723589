import { useState } from 'react'

const FooterFeatured = () => {
  const [features] = useState([
    {
      id: 1,
      imageUrl: '/media/svg/icons/icon-1.svg',
      title: 'Giá và ưu đãi tốt nhất',
      subTitle: 'Đơn hàng $50 trở lên',
    },
    {
      id: 2,
      imageUrl: '/media/svg/icons/icon-2.svg',
      title: 'Giao hàng miễn phí',
      subTitle: 'Dịch vụ tuyệt vời 24/7',
    },
    {
      id: 3,
      imageUrl: '/media/svg/icons/icon-3.svg',
      title: 'Ưu đãi hàng ngày tuyệt vời',
      subTitle: 'Khi bạn đăng ký',
    },
    {
      id: 4,
      imageUrl: '/media/svg/icons/icon-4.svg',
      title: 'Chủng loại đa dạng',
      subTitle: 'Giảm giá lớn',
    },
    {
      id: 5,
      imageUrl: '/media/svg/icons/icon-5.svg',
      title: 'Dễ dàng trả hàng',
      subTitle: 'Trong vòng 30 ngày',
    },
    {
      id: 6,
      imageUrl: '/media/svg/icons/icon-6.svg',
      title: 'Giao hàng an toàn',
      subTitle: 'Trong vòng 30 ngày',
    },
  ])

  return (
    <section id='home-footer-featured'>
      <div className='row'>
        {features.map((d) => {
          return (
            <div className='col-sm-6 col-md-4 mb-4' key={d.id}>
              <div className='banner-left-icon d-flex align-items-center' key={d.id}>
                <div className='banner-icon'>
                  <img src={d.imageUrl} alt='' />
                </div>
                <div className='banner-text'>
                  <h3 className='icon-box-title'>{d.title}</h3>
                  <p>{d.subTitle}</p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default FooterFeatured
