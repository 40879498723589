import React, { createContext, useState, useContext } from 'react'
import { useAuth } from '../../components/auth/AuthContext'
import { useShoppingCartService } from '../../services/apiServices'
import { useEffect } from 'react'

const ShopingCartContext = createContext()

export const ShopingCartContextProvider = ({ children }) => {
  const { getListAsync, insertAsync, deleteAsync, deleteAllAsync } = useShoppingCartService()
  const { user, checkLogin } = useAuth()
  const [shoppingCart, setShoppingCart] = useState({})

  useEffect(() => {
    reloadCart()
  }, [user])

  const addToCart = async (cartDetail) => {
    checkLogin()

    let updatedCart = {}

    setShoppingCart((prevCart) => {
      const cartDetails = prevCart.shoppingCartDetails || []

      const existingItemIndex = cartDetails.findIndex((item) => item.productId === cartDetail.productId && item.productDetailId === cartDetail.productDetailId)

      let updatedCartDetails

      if (existingItemIndex !== -1) {
        updatedCartDetails = cartDetails.map((item, index) => (index === existingItemIndex ? { ...item, quantity: item.quantity + cartDetail.quantity } : item))
      } else {
        updatedCartDetails = [...cartDetails, cartDetail]
      }

      const { totalAmount, count } = reCalculateCart(updatedCartDetails)

      updatedCart = { ...prevCart, totalAmount, count, shoppingCartDetails: updatedCartDetails }

      return updatedCart
    })

    const { result } = await insertAsync(updatedCart)
    if (result) {
      reloadCart()
    }
  }

  const increaseItem = async (cartDetailId) => {
    checkLogin()

    let updatedCart = {}

    setShoppingCart((prevCart) => {
      const cartDetails = prevCart.shoppingCartDetails || []

      const existingItemIndex = cartDetails.findIndex((item) => item.cartDetailId === cartDetailId)

      let updatedCartDetails

      if (existingItemIndex !== -1) {
        updatedCartDetails = cartDetails.map((item, index) => (index === existingItemIndex ? { ...item, quantity: item.quantity + 1 } : item))
      } else {
        updatedCartDetails = [...cartDetails]
      }

      const { totalAmount, count } = reCalculateCart(updatedCartDetails)

      updatedCart = { ...prevCart, totalAmount, count, shoppingCartDetails: updatedCartDetails }

      return updatedCart
    })

    const { result } = await insertAsync(updatedCart)
    if (result) {
      reloadCart()
    }
  }

  const decreaseItem = async (cartDetailId) => {
    checkLogin()

    let updatedCart = {}

    setShoppingCart((prevCart) => {
      const cartDetails = prevCart.shoppingCartDetails || []

      const existingItemIndex = cartDetails.findIndex((item) => item.cartDetailId === cartDetailId)

      let updatedCartDetails

      if (existingItemIndex !== -1) {
        updatedCartDetails = cartDetails
          .map((item, index) => (index === existingItemIndex ? { ...item, quantity: Math.max(item.quantity - 1, 0) } : item))
          .filter((item) => item.quantity > 0)
      } else {
        updatedCartDetails = [...cartDetails]
      }

      const { totalAmount, count } = reCalculateCart(updatedCartDetails)

      updatedCart = { ...prevCart, totalAmount, count, shoppingCartDetails: updatedCartDetails }

      return updatedCart
    })

    const { result } = await insertAsync(updatedCart)
    if (result) {
      reloadCart()
    }
  }

  const removeFromCart = async (id) => {
    checkLogin()

    const { result } = await deleteAsync(id)
    if (result) {
      reloadCart()
    }
  }

  const removeAll = async () => {
    checkLogin()

    const { result } = await deleteAllAsync()
    if (result) {
      reloadCart()
    }
  }

  const reloadCart = async () => {
    const { result } = user ? await getListAsync() : {}
    setShoppingCart(result || [])
  }

  const reCalculateCart = (cartDetails) => {
    const totalAmount = cartDetails.reduce((sum, item) => sum + ((item.sellingPrice ?? 0) - (item.discountAmount ?? 0)) * (item.quantity ?? 0), 0)
    return { totalAmount, count: cartDetails.length }
  }

  return (
    <ShopingCartContext.Provider value={{ shoppingCart, addToCart, increaseItem, decreaseItem, removeFromCart, removeAll }}>
      {children}
    </ShopingCartContext.Provider>
  )
}

export const useShopingCart = () => useContext(ShopingCartContext)
