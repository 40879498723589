/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'
import '../../assets/sass/order.scss'
import { useShopingCart } from '../../components/contexts/ShoppingCartContext'
import Rate from '../../components/shared/Rate'
import NumberFormat from '../../components/shared/NumberFormat'
import { DEFAULT_VALUE } from '../../utilities/constant'
import Breadcrumb from '../../components/layout/components/breadcrumb/Breadcrumb'
import { showToast, TOAST_TYPES } from '../../components/shared/Toast'

const ShoppingCart = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { shoppingCart, removeFromCart, removeAllCart, increaseItem, decreaseItem } = useShopingCart()

  const [items, setItems] = useState([])
  const [totalAmountSelected, setTotalAmountSelected] = useState(0)
  const [totalItemSelected, setTotalItemSelected] = useState(0)
  const [isAllChecked, setIsAllChecked] = useState(false)

  useEffect(() => {
    const selectedItems = items?.filter((item) => item.isChecked) ?? []
    const totalAmount = selectedItems.reduce((sum, item) => sum + ((item.sellingPrice ?? 0) - (item.discountAmount ?? 0)) * (item.quantity ?? 0), 0)
    setTotalAmountSelected(totalAmount)
    setTotalItemSelected(selectedItems.length)

    const allChecked = selectedItems.every((row) => row.isChecked)
    setIsAllChecked(allChecked)
  }, [items])

  useEffect(() => {
    setItems(shoppingCart?.shoppingCartDetails)
  }, [shoppingCart])

  const renderPropertyName = (propertyName) => {
    if (propertyName) {
      const elements = propertyName.split(';').filter((element) => element.trim() !== '')
      return (
        <>
          {elements.map((element, index) => (
            <div key={index}>{element.trim()}</div>
          ))}
        </>
      )
    }
  }

  const handleCheckAll = (e) => {
    const checked = e.target.checked
    setIsAllChecked(checked)
    setItems(items.map((row) => ({ ...row, isChecked: checked })))
  }

  const handleCheckRow = (index) => (e) => {
    const checked = e.target.checked
    const updatedRows = [...items]
    updatedRows[index].isChecked = checked

    const allChecked = updatedRows.every((row) => row.isChecked)
    setIsAllChecked(allChecked)

    setItems(updatedRows)
  }

  const handleCheckout = () => {
    const selectedItems = items.filter((item) => item.isChecked)

    if (selectedItems.length === 0) {
      showToast(`${intl.formatMessage({ id: 'Order.ChooseProduct' })}`, TOAST_TYPES.WARNING)
    } else {
      navigate('/checkout', { state: { selectedItems } })
    }
  }

  const renderBody = (item, index) => {
    return (
      <tr key={item.id}>
        <td className='custome-checkbox ps-10'>
          <input className='form-check-input' type='checkbox' id={`order-${item.id}`} checked={item.isChecked} onChange={handleCheckRow(index)} />
          <label className='form-check-label' htmlFor={`order-${item.id}`} />
        </td>
        <td className='image product-thumbnail'>
          <img src={item.fileThumbnailUrl} alt='' />
        </td>
        <td className='product-description product-name'>
          <h6 className='mb-2'>
            <a className='product-name mb-10 text-heading'>{item.productName}</a>
          </h6>
          <div className='mb-2'>{renderPropertyName(item.productPropertyName)}</div>
          <Rate value={item.productRate ?? 0} isDisabled={true} />
        </td>
        <td className='price' data-title='Price'>
          <NumberFormat amount={item.sellingPrice - item.discountAmount} className={'text-body fs-2'} suffix={DEFAULT_VALUE.VND} />
        </td>
        <td className='text-center detail-info' data-title='Stock'>
          <div className='detail-extralink mr-15'>
            <div className='detail-qty'>
              <a className='qty-down' onClick={() => increaseItem(item.id)}>
                <i className='fa-solid fa-caret-down' />
              </a>
              <input type='number' name='quantity' className='qty-val' value={item.quantity} min={1} />
              <a className='qty-up' onClick={() => decreaseItem(item.id)}>
                <i className='fa-solid fa-caret-up' />
              </a>
            </div>
          </div>
        </td>
        <td className='price' data-title='Price'>
          <NumberFormat amount={(item.sellingPrice - item.discountAmount) * item.quantity} suffix={DEFAULT_VALUE.VND} className={'text-brand fs-2'} />
        </td>
        <td className='action text-center' data-title='Remove'>
          <a className='text-body' onClick={() => removeFromCart(item.id)}>
            <i className='fa-solid fa-trash-can text-brand' />
          </a>
        </td>
      </tr>
    )
  }

  return (
    <>
      <Breadcrumb />
      <div id='order' className='d-flex flex-column'>
        <div className='d-flex flex-column'>
          <div className='mb-10 mt-10'>
            <h1 className='heading-2 mb-5'>{intl.formatMessage({ id: 'ShoppingCart' })}</h1>
            <div className='d-flex justify-content-between'>
              <h2 className='text-body'>
                {intl.formatMessage({ id: 'Order.YouHave' })} <span className='text-brand'>{items?.count ?? 0}</span>{' '}
                {intl.formatMessage({ id: 'Order.ProductInCart' })}
              </h2>

              <Link className='btn' to='/'>
                <i className='fa-solid fa-solid fa-cart-shopping trash-bottom color-white' />
                {intl.formatMessage({ id: 'Checkout.ContinueShopping' })}
              </Link>

              <a className='btn' onClick={() => removeAllCart()}>
                <i className='fa-solid fa-trash-can me-2 trash-bottom color-white' />
                {intl.formatMessage({ id: 'Order.RemoveCart' })}
              </a>
            </div>
          </div>
        </div>

        <div className='d-flex flex-column'>
          <div className='table-responsive shopping-summery'>
            <table className='table table-wishlist'>
              <thead className='table-header'>
                <tr className='main-heading'>
                  <th className='custome-checkbox start ps-10'>
                    <input className='form-check-input' type='checkbox' id='ckbAll' checked={isAllChecked} onChange={handleCheckAll} />
                    <label className='form-check-label' htmlFor='ckbAll' />
                  </th>
                  <th scope='col' colSpan={2}>
                    {intl.formatMessage({ id: 'Product' })}
                  </th>
                  <th scope='col'>{intl.formatMessage({ id: 'UnitPrice' })}</th>
                  <th scope='col' className='ps-15'>
                    {intl.formatMessage({ id: 'Quantity' })}
                  </th>
                  <th scope='col'>{intl.formatMessage({ id: 'TotalAmount' })}</th>
                  <th scope='col' className='end'>
                    {intl.formatMessage({ id: 'Action' })}
                  </th>
                </tr>
              </thead>
              <tbody>
                {items &&
                  items.map((item, index) => {
                    return renderBody(item, index)
                  })}
              </tbody>
            </table>
          </div>

          <table className='table table-wishlist mt-5'>
            <thead className='table-header'>
              <tr className='main-heading'>
                <th className='text-end'>
                  <h2>
                    {intl.formatMessage({ id: 'TotalAmount' })} (<span className='text-brand'>{totalItemSelected}</span>{' '}
                    <span className='text-lowercase'>{intl.formatMessage({ id: 'Product' })}</span>):
                  </h2>
                </th>
                <th className='text-end'>
                  <NumberFormat amount={totalAmountSelected} suffix={DEFAULT_VALUE.VND} className='color-orange fs-2' />
                </th>
                <th className='text-end'>
                  <a className='btn' onClick={() => handleCheckout()}>
                    {intl.formatMessage({ id: 'Checkout' })}
                    <i className='ps-2 fa-solid fa-right-from-bracket color-white' />
                  </a>
                </th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    </>
  )
}

export default ShoppingCart
