import React, { useState, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { CONTROL_TYPE, FormControl } from '../../../components/shared/FormControl'

const PriceFilter = ({ onChange }) => {
  const componentRefs = useRef({})
  const intl = useIntl()
  const [fromPrice, setFromPrice] = useState(0)
  const [toPrice, setToPrice] = useState(null)

  return (
    <div className='primary-sidebar'>
      <div className='sidebar-widget'>
        <h5 className='section-title style-1 mb-10'>
          <FormattedMessage id='AccordingToPrice' />
        </h5>
        <div className='price-filter mb-4'>
          <div className='d-flex gap-2'>
            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.fromPrice = ref)}
                controlType={CONTROL_TYPE.NUMBERBOX}
                label={intl.formatMessage({ id: 'From' })}
                value={fromPrice}
                onChange={setFromPrice}
                min={0}
              />
            </div>

            <div className='col-md-6 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.toPrice = ref)}
                controlType={CONTROL_TYPE.NUMBERBOX}
                label={intl.formatMessage({ id: 'To' })}
                value={toPrice}
                onChange={setToPrice}
                min={0}
              />
            </div>
          </div>
        </div>

        <a className='btn btn-sm btn-primary w-100' onClick={() => onChange(fromPrice, toPrice)}>
          <i className='fa fa-filter'></i> <FormattedMessage id='Apply' />
        </a>
      </div>
    </div>
  )
}
export default PriceFilter
