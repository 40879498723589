const CKEditorDisplay = ({ content }) => {
  const embedYouTube = (htmlContent) => {
    const youtubeRegex = /<oembed\s+url="https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})"><\/oembed>/g

    return htmlContent.replace(youtubeRegex, (match, videoId) => {
      return `<iframe src="https://www.youtube.com/embed/${videoId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
    })
  }

  const createMarkup = () => {
    if (content) {
      const embeddedContent = embedYouTube(content)
      return { __html: embeddedContent }
    }
  }

  return <div dangerouslySetInnerHTML={createMarkup()} />
}

export default CKEditorDisplay
