import React from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

const NewsBanner = ({ news }) => {
  const intl = useIntl()
  return (
    <Link to='#'>
      <div className='banner-img mb-lg-0 d-lg-block d-none hover-up'>
        <img src={'/media/banners/banner-11.png'} alt='' />
        <div className='banner-text'>
          <p className='title'>Oganic</p>
          <p className='content'>{intl.formatMessage({ id: 'News.SaveOganic' })}</p>
        </div>
      </div>
    </Link>
  )
}
export default NewsBanner
