import useApiConnection from '../apiConnection'
import { ECOM_ENDPOINTS } from '../apiEndpoints'

const useShoppingCartService = () => {
  const endpoint = process.env.REACT_APP_API_SLEEK_GLOBALADMIN
  const api = useApiConnection(endpoint, ECOM_ENDPOINTS.SHOPPING_CART)

  const getListAsync = async () => {
    return await api.getAsync()
  }

  const insertAsync = async (payload) => {
    return await api.postAsync('', { payload })
  }

  const deleteAsync = async (id) => {
    return await api.deleteAsync(id)
  }
  
  const deleteAllAsync = async () => {
    return await api.deleteAsync('delete-all')
  }

  return { getListAsync, insertAsync, deleteAsync, deleteAllAsync }
}

export default useShoppingCartService
