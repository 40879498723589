import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useLocationService, useCustomerService } from '../../services/apiServices'
import { CONTROL_TYPE, FormControl, validationAllComponents } from '../../components/shared/FormControl'
import Button from '../../components/shared/Button'
import Modal from '../../components/shared/Modal'
import { showToast, TOAST_TYPES } from '../../components/shared/Toast'

const CreateAddressModal = ({ customerId, editAddress, closeModal, fetchAddresses }) => {
  const intl = useIntl()
  const componentRefs = useRef({})
  const { getProvincesAsync, getDistrictsAsync, getWardsAsync } = useLocationService()
  const { insertCustomerAddressAsync, updateCustomerAddressAsync } = useCustomerService()
  let tabIndex = 1

  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const [wards, setWards] = useState([])

  const [provinceId, setProvinceId] = useState(editAddress?.provinceId ?? null)
  const [districtId, setDistrictId] = useState(editAddress?.districtId ?? null)
  const [wardId, setWardId] = useState(editAddress?.wardId ?? null)
  const [addressLine, setAddressLine] = useState(editAddress?.addressLine ?? null)
  const [isDefault, setIsDefault] = useState(editAddress?.isDefault ?? false)

  useEffect(() => {
    const fetchProvinces = async () => {
      const { result } = await getProvincesAsync()
      if (result) {
        setProvinces(
          result.map((item) => {
            return { value: item.id, label: item.name }
          })
        )
      }
    }

    fetchProvinces()
  }, [])

  useEffect(() => {
    const fetchDistricts = async (provinceId) => {
      const { result } = await getDistrictsAsync(provinceId)
      if (result) {
        const districtList = result.map((item) => ({ value: item.id, label: item.name }))

        setDistricts(districtList)

        if (!districtList.some((district) => district.value === districtId)) {
          setDistrictId(null)
        }
      }
    }

    if (provinceId) {
      fetchDistricts(provinceId)
    } else {
      setDistricts([])
      setDistrictId(null)
    }
  }, [provinceId])

  useEffect(() => {
    const fetchWards = async (districtId) => {
      const { result } = await getWardsAsync(districtId)
      if (result) {
        const wardsList = result.map((item) => ({ value: item.id, label: item.name }))

        setWards(wardsList)

        if (!wardsList.some((ward) => ward.value === wardId)) {
          setWardId(null)
        }
      }
    }

    if (districtId) {
      fetchWards(districtId)
    } else {
      setWards([])
      setWardId(null)
    }
  }, [districtId])

  const handleSave = async () => {
    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    const payload = {
      id: editAddress?.id,
      customerId: customerId,
      isDefault,
      isActive: true,
      address: {
        id: editAddress?.addressId,
        provinceId,
        districtId,
        wardId,
        addressLine,
        isActive: true,
      },
    }

    const { error } = editAddress.isAdded ? await insertCustomerAddressAsync(payload) : await updateCustomerAddressAsync(payload)
    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
      editAddress.isAdded = false
      fetchAddresses(editAddress.affiliateCustomerRelationId)
      closeModal(false)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
  }

  return (
    <Modal title={intl.formatMessage({ id: 'AddNewAddress' })} closeModal={closeModal} modalSize='mw-650px'>
      <form className='form'>
        <div
          className='d-flex flex-column'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-offset='300px'
        >
          <div className='row'>
            <div className='col-6 mb-3'>
              <FormControl
                controlType={CONTROL_TYPE.SELECT}
                ref={(ref) => (componentRefs.current.provinceId = ref)}
                label={intl.formatMessage({ id: 'Province' })}
                isRequired={true}
                value={provinceId}
                onChange={setProvinceId}
                tabIndex={tabIndex++}
                options={provinces}
              />
            </div>
            <div className='col-6 mb-3'>
              <FormControl
                controlType={CONTROL_TYPE.SELECT}
                ref={(ref) => (componentRefs.current.districtId = ref)}
                label={intl.formatMessage({ id: 'District' })}
                isRequired={true}
                value={districtId}
                onChange={setDistrictId}
                tabIndex={tabIndex++}
                options={districts}
              />
            </div>
            <div className='col-12 mb-3'>
              <FormControl
                controlType={CONTROL_TYPE.SELECT}
                ref={(ref) => (componentRefs.current.wardId = ref)}
                label={intl.formatMessage({ id: 'Ward' })}
                isRequired={true}
                value={wardId}
                onChange={setWardId}
                tabIndex={tabIndex++}
                options={wards}
              />
            </div>
            <div className='col-12 mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.addressLine = ref)}
                controlType={CONTROL_TYPE.TEXTAREA}
                label={intl.formatMessage({ id: 'AddressLine' })}
                value={addressLine}
                rows={2}
                onChange={setAddressLine}
                tabIndex={tabIndex++}
                isRequired={true}
              />
            </div>
            <div className='mb-3'>
              <FormControl
                ref={(ref) => (componentRefs.current.isDefault = ref)}
                tabIndex={6}
                controlType={CONTROL_TYPE.CHECKBOX}
                label={intl.formatMessage({ id: 'Default' })}
                value={isDefault}
                onChange={setIsDefault}
                isFromSwitch={true}
              />
            </div>
          </div>
        </div>

        <div className='text-center pt-5'>
          <Button
            onClick={() => closeModal(false)}
            title={intl.formatMessage({ id: 'Close' })}
            className={'btn btn-light me-3'}
            tabIndex={4}
            icon='CloseOutlined'
          >
            <FormattedMessage id='Close' />
          </Button>
          <Button onClick={handleSave} title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} tabIndex={5} icon='SaveOutlined'>
            <FormattedMessage id='Save' />
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default CreateAddressModal
