import { useIntl } from 'react-intl'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import App from '../App'
import MasterLayout from '../components/layout/MasterLayout'
import Login from '../components/auth/Login'
import Logout from '../components/auth/Logout'
import Register from '../components/auth/Register'
import ForgotPassword from '../components/auth/ForgotPassword'
import NewPassword from '../components/auth/NewPassword'
import Confirmation from '../components/auth/Confirmation'
import AuthLayout from '../components/auth/AuthLayout'
import Home from '../pages/home/Home'
import { PageTitle } from '../components/layout/components/breadcrumb/PageData'
import Search from '../pages/search/Search'
import CategoryPage from '../pages/category/CategoryPage'
import ListSupplierPage from '../pages/supplier/ListSupplierPage'
import SupplierPage from '../pages/supplier/SupplierPage'
import ProductDetailPage from '../pages/productDetail/ProductDetailPage'
import ShoppingCart from '../pages/shoppingCart/ShoppingCart'
import { CheckoutPage } from '../pages/checkout'
import { CustomerPage } from '../pages/customer'
import { PurchasePage } from '../pages/purchase'
import { AccountBalancePage } from '../pages/accountBalance'
import UserProfile from '../pages/userProfile/UserProfilePage'
import UserProfilePage from '../pages/userProfile/UserProfilePage'
import RegisterAffiliate from '../pages/register/Affiliate'
import RegisterSupplier from '../pages/register/Supplier'

const AppRoutes = () => {
  const intl = useIntl()

  const loadBreadcrumbs = () => {
    let item = [{ title: intl.formatMessage({ id: 'Home' }), path: '/home' }]
    return item
  }

  const SearchPage = () => {
    return (
      <>
        <PageTitle breadcrumbs={loadBreadcrumbs} isHideTitle={true}>
          {intl.formatMessage({ id: 'Search' })}
        </PageTitle>
        <Search />
      </>
    )
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path='/login' element={<Login />} />
          <Route path='/logout' element={<Logout />} />
          <Route path='/registration' element={<Register />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/new-password' element={<NewPassword />} />
          <Route path='/confirmation' element={<Confirmation />} />
        </Route>
        <Route element={<App />}>
          <Route element={<MasterLayout />}>
            <Route path='/' element={<Home />} />
            <Route path='/home' element={<Navigate to='/' />} />
            <Route path='search/:categoryId/:searchValue' element={<SearchPage />} />
            <Route path='search/:categoryId/*' element={<SearchPage />} />
            <Route path='categories/:categoryId/*' element={<CategoryPage />} />
            <Route path='supplier/:supplierId/*' element={<SupplierPage />} />
            <Route path='suppliers' element={<ListSupplierPage />} />
            <Route path='product/:productId/*' element={<ProductDetailPage />} />
            <Route path='/shopping-cart' element={<ShoppingCart />} />
            <Route path='/checkout/*' element={<CheckoutPage />} />
            <Route path='/pages/customer' element={<CustomerPage />} />
            <Route path='/purchase/:status/*' element={<PurchasePage />} />
            <Route path='/pages/accountBalance' element={<AccountBalancePage />} />
            <Route path='/*' element={<UserProfilePage />} />
            <Route path='*' element={<Navigate to='/' />} />
            <Route
              path='/register/affiliate/:referralCode'
              element={
                <>
                  <PageTitle breadcrumbs={loadBreadcrumbs} isHideTitle={true}>
                    {intl.formatMessage({ id: 'Affiliate.Register' })}
                  </PageTitle>
                  <RegisterAffiliate />
                </>
              }
            />
            <Route
              path='/register/supplier'
              element={
                <>
                  <PageTitle breadcrumbs={loadBreadcrumbs} isHideTitle={true}>
                    {intl.formatMessage({ id: 'Supplier.Register' })}
                  </PageTitle>
                  <RegisterSupplier />
                </>
              }
            />

            {/*
            <Route path='/pages/my-offer' element={<MyOfferPage />} />
          */}
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default AppRoutes
