import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import Rate from './Rate'
import NumberFormat from './NumberFormat'
import _ from 'lodash'
import { useAuth } from '../auth/AuthContext'

const Product = ({ products }) => {
  const intl = useIntl()
  const [hoveredIndex, setHoveredIndex] = useState(null)
  const { userPermissions } = useAuth()

  const handleProductHover = (index) => {
    setHoveredIndex(index)
  }

  return products
    ?.filter((x) => !_.isEmpty(x.details))
    .map((item, index) => {
      const product = item.details?.reduce((lowest, product) => {
        const currentPrice = product.sellingPrice - product.discountAmount
        const lowestPrice = lowest.sellingPrice - lowest.discountAmount

        return currentPrice < lowestPrice ? product : lowest
      })

      return (
        <div key={`${index}`} className={`product-item ${hoveredIndex === index ? 'hovered' : ''}`}>
          <div className='card overflow-auto overflow-hidden rounded product-item-content'>
            <div className='card-body p-2'>
              <div className='mb-2 position-relative t-0'>
                <Link className={`ratio ratio-1x1`} to={`/product/${item.id}`} onMouseEnter={() => handleProductHover(index)} onMouseLeave={handleProductHover}>
                  <img src={item.fileThumbnail.url} loading='lazy' className='object-fit-contain rounded-2' alt='' />
                </Link>
              </div>

              <div className='top-0 start-0 position-absolute'>
                <span
                  className={`product-badges rounded-bottom-right
                ${item.type ? item.typeName.toLocaleLowerCase() : ''}`}
                >
                  {item.type ? intl.formatMessage({ id: `Ecom.FeaturedProduct.${item.typeName.toUpperCase()}` }) : ''}
                </span>
              </div>

              <div style={{ minHeight: '130px' }}>
                <div className='product-item-text overflow-hidden mb-1 text-dark'>
                  <Link to={`/product/${item.id}`} onMouseEnter={() => handleProductHover(index)} onMouseLeave={handleProductHover}>
                    <span className='fw-bold'>{item.name}</span>
                  </Link>
                </div>

                <div className='product-item-text d-flex align-items-center justify-content-between mb-1'>
                  <Rate value={item.rating} isDisabled={true} />
                </div>

                <div className='product-item-text mb-1'>
                  <NumberFormat amount={product.sellingPrice - product.discountAmount} suffix='đ' className={'text-primary fs-3 fw-bold'} />
                  {product.discountAmount > 0 && <NumberFormat amount={product.sellingPrice} className='old-price' suffix='đ' />}
                </div>
                {userPermissions && (
                  <div className='product-item-text text-muted fs-7 mb-1'>
                    {intl.formatMessage({ id: 'SalesCommission' })}: <NumberFormat amount={product.commissionAmount} suffix='đ' className={'text-muted fs-7'} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    })
}
export default Product
