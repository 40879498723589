import { useRef, useState } from 'react'
import { CONTROL_TYPE, FormControl, validationAllComponents } from '../shared/FormControl'
import { FormattedMessage, useIntl } from 'react-intl'
import Icon from '../shared/Icon'
import { Link, useNavigate } from 'react-router-dom'
import { useAuthService } from '../../services/apiServices'
import { useAuth } from './AuthContext'
import _ from 'lodash'

const Register = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const componentRefs = useRef({})
  const { registerAsync } = useAuthService()
  const { reloadUser, returnUrl } = useAuth()
  let tabIndex = 1

  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [email, setEmail] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [password, setPassword] = useState(null)
  const [confirmPassword, setConfirmPassword] = useState(null)
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(true)

  const [errors, setErrors] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      setIsSubmitting(true)
      const isValid = await validationAllComponents(componentRefs)

      if (isValid && isValidConfirmPassword) {
        console.log({
          firstName,
          lastName,
          email,
          phoneNumber,
          password,
          confirmPassword,
          returnUrl: returnUrl(),
        })

        const { error, result } = await registerAsync({
          firstName,
          lastName,
          email,
          phoneNumber: '' + phoneNumber,
          password,
          confirmPassword,
          returnUrl: returnUrl(),
        })
        if (error) {
          setErrors([error.message])
        } else {
          if (result.confirmEmail) {
            navigate('/confirmation', {
              state: { confirmMessage: result.message },
            })
          } else {
            reloadUser(result)
          }
        }
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const validatorConfirmNewPassword = () => {
    setIsValidConfirmPassword(true)
    if (_.isEmpty(password) || _.isEmpty(confirmPassword)) return
    if (password !== confirmPassword) {
      setIsValidConfirmPassword(false)
      return { error: true, message: `${intl.formatMessage({ id: 'User.ResetPassword.ValidatorConfirmPassword' })}` }
    }
  }

  return (
    <form className='form w-100' onSubmit={handleSubmit}>
      <div className='text-center mb-11'>
        <h1 className='text-gray-900 fw-bolder mb-3'>
          <FormattedMessage id='Register' />
        </h1>
        <div className='text-gray-500 fw-semibold fs-6'>Green24h.com</div>
      </div>

      {Object.keys(errors).length > 0 && (
        <div className='text-danger'>
          {Object.values(errors).map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </div>
      )}

      <div className='fv-row d-flex gap-4 mb-8'>
        <div>
          <FormControl
            ref={(ref) => (componentRefs.current.firstName = ref)}
            controlType={CONTROL_TYPE.INPUT}
            label={intl.formatMessage({ id: 'Register.FirstName' })}
            value={firstName}
            onChange={setFirstName}
            tabIndex={tabIndex++}
            isRequired={true}
          />
        </div>

        <div>
          <FormControl
            ref={(ref) => (componentRefs.current.lastName = ref)}
            tabIndex={tabIndex++}
            controlType={CONTROL_TYPE.INPUT}
            label={intl.formatMessage({ id: 'Register.LastName' })}
            value={lastName}
            isRequired={true}
            onChange={setLastName}
          />
        </div>
      </div>
      <div className='fv-row mb-8'>
        <FormControl
          ref={(ref) => (componentRefs.current.userName = ref)}
          controlType={CONTROL_TYPE.INPUT}
          label={intl.formatMessage({ id: 'Email' })}
          value={email}
          onChange={setEmail}
          tabIndex={tabIndex++}
          isRequired={true}
          isEmail={true}
        />
      </div>

      <div className='fv-row mb-8'>
        <FormControl
          ref={(ref) => (componentRefs.current.phoneNumber = ref)}
          controlType={CONTROL_TYPE.PHONENUMBER}
          label={intl.formatMessage({ id: 'Ecom.Affiliate.PhoneNumber' })}
          value={phoneNumber}
          onChange={setPhoneNumber}
          tabIndex={tabIndex++}
          isRequired={true}
        />
      </div>

      <div className='fv-row mb-8'>
        <FormControl
          ref={(ref) => (componentRefs.current.email = ref)}
          controlType={CONTROL_TYPE.INPUT}
          label={intl.formatMessage({ id: 'Password' })}
          value={password}
          onChange={setPassword}
          tabIndex={tabIndex++}
          isRequired={true}
          isPassword={true}
          hideText={true}
        />
        <div className='form-text'>{intl.formatMessage({ id: 'User.ResetPassword.Note' })}</div>
      </div>

      <div className='fv-row mb-4'>
        <FormControl
          ref={(ref) => (componentRefs.current.confirmPassword = ref)}
          controlType={CONTROL_TYPE.INPUT}
          label={intl.formatMessage({ id: 'Register.ConfirmPassword' })}
          value={confirmPassword}
          onChange={setConfirmPassword}
          tabIndex={tabIndex++}
          isRequired={true}
          isPassword={true}
          hideText={true}
          validator={() => validatorConfirmNewPassword()}
        />
      </div>

      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8 justify-content-end'>
        <Link to='/forgot-password' className='link-primary'>
          <FormattedMessage id='Login.ForgotPassword' />
        </Link>
      </div>

      <div className='d-grid mb-10'>
        <button type='submit' id='kt_sign_in_submit' className='btn btn-primary' disabled={isSubmitting}>
          <div className='d-flex align-items-center justify-content-center gap-2'>
            <FormattedMessage id={isSubmitting ? 'PleaseWait' : 'Register'} />
            <Icon iconName={isSubmitting ? 'LoadingOutlined' : ''} />
          </div>
        </button>
      </div>

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        <FormattedMessage id='Login.NotAMemberYet' />{' '}
        <Link to='/login' className='link-primary'>
          <FormattedMessage id='Login' />
        </Link>
      </div>
    </form>
  )
}

export default Register
