import '../../assets/sass/imageCarouse.scss'
import Slider from 'react-slick'
import _ from 'lodash'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const ImageCarousel = ({ Component, responsive, ...props }) => {
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: props?.columns,
    slidesToScroll: 1,
    arrows: true,
    responsive: _.isFunction(responsive) ? responsive(props?.columns) : [],
  }

  return (
    <Slider {...sliderSettings} className={props?.className ?? ''}>
      {props.products?.map((item, index) => {
        return <Component key={index} products={[item]} />
      })}
    </Slider>
  )
}

export default ImageCarousel
