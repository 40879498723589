import { useIntl } from 'react-intl'

const CheckoutPayment = ({ paymentMethods, paymentMethodSelected, onChangePaymentMethod }) => {
  const intl = useIntl()

  return (
    <>
      <div className='checkout-payment-wrap'>
        <div style={{ display: 'contents' }}>
          <div>
            <div className='checkout-payment-method checkout-payment-setting'>
              <div className='checkout-payment-method-title'>{intl.formatMessage({ id: 'PaymentMethod' })}</div>
              <div className='checkout-payment-method-tab'>
                {paymentMethods.map((d) => (
                  <span key={d.value} onClick={() => onChangePaymentMethod(d)}>
                    <div className={`product-variation ${d.value === paymentMethodSelected.value ? 'product-variation-selected' : ''}`} disabled={true}>
                      {d.label}
                      {d.value === paymentMethodSelected.value && (
                        <div className='product-variation_tick'>
                          <img src={'/media/svg/icons/tick.svg'} alt='' className='checkout-svg-icon tick-svg me-2 cursor-pointer' />
                        </div>
                      )}
                    </div>
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CheckoutPayment
