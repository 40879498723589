import useApiConnection from '../apiConnection'
import { ECOM_ENDPOINTS } from '../apiEndpoints'

const useCustomerService = () => {
  const endpoint = process.env.REACT_APP_API_SLEEK_GLOBALADMIN
  const api = useApiConnection(endpoint, ECOM_ENDPOINTS.CUSTOMER)

  const getByIdAsync = async (id) => {
    return await api.getAsync(id)
  }

  const getListAsync = async (payload) => {
    return await api.getAsync('', { payload })
  }

  const getByAffiliateIdAsync = async () => {
    return await api.getAsync(`get-by-affiliateId`)
  }

  const getListExportAsync = async (payload) => {
    return await api.postAsync('export-excel', { payload })
  }

  const insertAsync = async (payload) => {
    return await api.putAsync('', { payload })
  }

  const updateAsync = async (payload) => {
    return await api.postAsync('', { payload })
  }

  const deleteAsync = async (id) => {
    return await api.deleteAsync(id)
  }

  const insertCustomerAddressAsync = async (payload) => {
    return await api.putAsync('addresses', { payload })
  }

  const updateCustomerAddressAsync = async (payload) => {
    return await api.postAsync('addresses', { payload })
  }

  const deleteCustomerAddressAsync = async (id) => {
    return await api.deleteAsync(`addresses/${id}`)
  }

  const getByUserIdAsync = async (id) => {
    return await api.getAsync(`get-by-userId/${id}`)
  }

  return {
    getByIdAsync,
    getListAsync,
    insertAsync,
    updateAsync,
    deleteAsync,
    getListExportAsync,
    getByAffiliateIdAsync,
    insertCustomerAddressAsync,
    updateCustomerAddressAsync,
    deleteCustomerAddressAsync,
    getByUserIdAsync,
  }
}

export default useCustomerService
