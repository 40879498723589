import React from 'react'
import { Link, Outlet } from 'react-router-dom'
import { useLocation } from 'react-router'
import { FormattedMessage } from 'react-intl'
import SVG from 'react-inlinesvg'
import { checkIsActive } from '../../utilities/sharedFunction'
import Breadcrumb from '../../components/layout/components/breadcrumb/Breadcrumb'
import '../../assets/sass/supplier.scss'

const Supplier = ({ supplier }) => {
  const location = useLocation()
  
  return (
    <div className='supplier-info'>
      <Breadcrumb />
      <div className='header-page mt-4 mb-8'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='title d-flex justify-content-center align-items-center'>
            <div className='me-4'>
              <div className='rounded-pill border d-inline-block position-relative'>
                <img src={supplier?.fileLogo?.url ?? '/media/jpg/shop.jpg'} className='supplier-logo object-fit-contain rounded-pill' alt='' />
              </div>
            </div>
            <div className='me-4 border-end'>
              <div className='fw-bolder text-uppercase fs-2'>{supplier?.companyName}</div>
              <div className='row align-items-center'>
                <div className='my-1'>
                  <SVG src={'/media/svg/icons/location.svg'} />
                  {supplier?.address}
                </div>
              </div>
            </div>
          </div>

          <ul className='tags-list'>
            <li className='hover-up'>
              <Link to={`/supplier/${supplier?.id}/home`} className={`${checkIsActive(location.pathname, `/supplier/${supplier?.id}/home`) ? 'active' : ''}`}>
                <FormattedMessage id='Home' />
              </Link>
            </li>
            <li className='hover-up'>
              <Link
                to={`/supplier/${supplier?.id}/products`}
                className={`${checkIsActive(location.pathname, `/supplier/${supplier?.id}/product`) ? 'active' : ''}`}
              >
                <FormattedMessage id='AllProducts' />
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className='supplier-content'>
        <Outlet />
      </div>
    </div>
  )
}

export default Supplier
