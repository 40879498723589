import React from 'react'
import { Link } from 'react-router-dom'
import FooterBanner from './FooterBanner'
import FooterFeatured from './FooterFeatured'
import { FormattedMessage } from 'react-intl'

const Footer = () => {
  const homeUrl = process.env.REACT_APP_SLEEK_HOME

  return (
    <section className='footer p-0 bg-white'>
      <div className='container-xxl py-4'>
        <FooterBanner />
        <FooterFeatured />
      </div>

      <div className='section-padding footer-mid'>
        <div className='container-xxl py-4'>
          <div className='d-flex flex-md-row flex-column justify-content-between gap-5 gap-xxl-20'>
            <div className='widget-about font-md mb-md-3 mb-lg-3 mb-xl-0'>
              <div className='logo mb-4'>
                <Link className='mb-4' to='/home'>
                  <img alt='Logo' src={'/media/logos/logo_large.png'} className='logo-sticky h-45px me-10' />
                </Link>
                <p className='font-lg text-heading'>
                  <FormattedMessage id='Footer.TextHeading' />
                </p>
              </div>
              <ul className='contact-infor'>
                <li>
                  <img src={'/media/svg/icons/icon-location.svg'} alt='' />
                  <strong>
                    <FormattedMessage id='Address' />:
                  </strong>{' '}
                  <span>275C Lương Đình Của, Phường An Phú, Tp. Thủ Đức, Việt Nam</span>
                </li>
                <li>
                  <img src={'/media/svg/icons/icon-contact.svg'} alt='' />
                  <strong>
                    <FormattedMessage id='CallUs' />:
                  </strong>
                  <span>08 5553 8385 - 0985 117776</span>
                </li>
                <li>
                  <img src={'/media/svg/icons/icon-email-2.svg'} alt='' />
                  <strong>Email:</strong>
                  <span>doandavid@green24h.com</span>
                </li>
                <li>
                  <img src={'/media/svg/icons/icon-clock.svg'} alt='' />
                  <strong>
                    <FormattedMessage id='WorkingTime' />:
                  </strong>
                  <span>08:00 - 18:00, Mon - Sat</span>
                </li>
              </ul>
            </div>

            <div className='footer-link-widget d-none d-xl-flex flex-column'>
              <h4 className='widget-title'>
                <FormattedMessage id='Company' />
              </h4>
              <ul className='footer-list mb-sm-5 mb-md-0'>
                <li className='hover-right'>
                  <Link className='hover-text' to={`${homeUrl}/gioi-thieu`} target='_blank'>
                    <FormattedMessage id='AboutUs' />
                  </Link>
                </li>
                {/* <li className='hover-right'>
                  <Link className='hover-text' to='#'>
                    <FormattedMessage id='DeliveryInformation' />
                  </Link>
                </li> */}
                <li className='hover-right'>
                  <Link className='hover-text' to={`${homeUrl}/chinh-sach-bao-mat`} target='_blank'>
                    <FormattedMessage id='PrivacyPolicy' />
                  </Link>
                </li>
                <li className='hover-right'>
                  <Link className='hover-text' to={`${homeUrl}/dieu-khoan-su-dung-dich-vu`} target='_blank'>
                    <FormattedMessage id='TermsConditions' />
                  </Link>
                </li>
                <li className='hover-right'>
                  <Link className='hover-text' to={`${homeUrl}/lien-he`} target='_blank'>
                    <FormattedMessage id='ContactUs' />
                  </Link>
                </li>
                <li className='hover-right'>
                  <Link className='hover-text' to={`${homeUrl}`} target='_blank'>
                    <FormattedMessage id='SupportCenter' />
                  </Link>
                </li>
                {/* <li className='hover-right'>
                  <Link className='hover-text' to='#'>
                    <FormattedMessage id='Occupation' />
                  </Link>
                </li> */}
              </ul>
            </div>

            <div className='footer-link-widget d-none d-xl-flex flex-column'>
              <h4 className='widget-title'>
                <FormattedMessage id='User.Account' />
              </h4>
              <ul className='footer-list mb-sm-5 mb-md-0'>
                <li className='hover-right'>
                  <Link className='hover-text' to='/registration'>
                    <FormattedMessage id='Register' />
                  </Link>
                </li>
                <li className='hover-right'>
                  <Link className='hover-text' to='/shopping-cart'>
                    <FormattedMessage id='ViewCart' />
                  </Link>
                </li>
                <li className='hover-right'>
                  <Link className='hover-text' to='#'>
                    <FormattedMessage id='FavoriteProduct' />
                  </Link>
                </li>
                <li className='hover-right'>
                  <Link className='hover-text' to='purchase/0'>
                    <FormattedMessage id='OrderTracking' />
                  </Link>
                </li>
                {/* <li className='hover-right'>
                  <Link className='hover-text' to='#'>
                    <FormattedMessage id='HelpTicket' />
                  </Link>
                </li>
                <li className='hover-right'>
                  <Link className='hover-text' to='#'>
                    <FormattedMessage id='ShippingDetail' />
                  </Link>
                </li>
                <li className='hover-right'>
                  <Link className='hover-text' to='#'>
                    <FormattedMessage id='CompareProduct' />
                  </Link>
                </li> */}
              </ul>
            </div>

            <div className='footer-link-widget d-none d-xl-flex flex-column'>
              <h4 className='widget-title'>
                <FormattedMessage id='Partner' />
              </h4>
              <ul className='footer-list mb-sm-5 mb-md-0'>
                <li className='hover-right'>
                  <Link className='hover-text' to='/register/supplier'>
                    <FormattedMessage id='Footer.BecomeSupplier' />
                  </Link>
                </li>
                <li className='hover-right'>
                  <Link className='hover-text' to='/register/affiliate/0'>
                    <FormattedMessage id='Affiliate.Register' />
                  </Link>
                </li>
                {/* <li className='hover-right'>
                  <Link className='hover-text' to='#'>
                    <FormattedMessage id='Footer.ConstructionSolution' />
                  </Link>
                </li> */}
                <li className='hover-right'>
                  <Link className='hover-text' to={`${homeUrl}/category/kenh-tiep-thi-vi`}>
                    <FormattedMessage id='Footer.MarketingAds' />
                  </Link>
                </li>
                {/* <li className='hover-right'>
                  <Link className='hover-text' to='#'>
                    <FormattedMessage id='Footer.ServiceDevelopment' />
                  </Link>
                </li> */}
                {/* <li className='hover-right'>
                  <Link className='hover-text' to='#'>
                    <FormattedMessage id='PromotionPolicy' />
                  </Link>
                </li> */}
                {/* <li className='hover-right'>
                  <Link className='hover-text' to='#'>
                    <FormattedMessage id='ProductCategory' />
                  </Link>
                </li> */}
              </ul>
            </div>

            <div className='footer-link-widget'>
              <h4 className='widget-title'>
                <FormattedMessage id='Footer.InstallApp' />
              </h4>
              <p className=''>
                <FormattedMessage id='Footer.InstallAppFrom' />
              </p>
              <div className='download-app'>
                <a className='hover-up mb-sm-2 mb-lg-0'>
                  <img className='active' src={'/media/jpg/app-store.jpg'} alt='' />
                </a>
                <a className='hover-up mb-sm-2'>
                  <img src={'/media/jpg/google-play.jpg'} alt='' />
                </a>
              </div>
              <p className='mb-4'>
                <FormattedMessage id='Footer.SecuredPayment' />
              </p>
              <img src={'/media/png/payment-method.png'} alt=''></img>
            </div>
          </div>
        </div>
      </div>

      <div className='container-xxl py-4'>
        <div className='row align-items-center'>
          <div className='col-12 mb-4'>
            <div className='footer-bottom'></div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6'>
            <p className='mb-0'>
              © 2024,{' '}
              <strong className='text-primary'>
                <FormattedMessage id='Green24h' />
              </strong>{' '}
              - <FormattedMessage id='Ecommerce' /> <br />
              <FormattedMessage id='AllRightReserved' />
            </p>
          </div>
          <div className='col-xl-4 col-lg-6 d-none d-xl-block'>
            <div className='hotline d-flex align-items-center justify-content-center'>
              <img className='opacity-50' src={'/media/svg/icons/phone-call.svg'} alt='hotline' />
              <p>
                08 5553 8385
                <span>
                  24/7 <FormattedMessage id='SupportCenter' />
                </span>
              </p>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 col-md-6 text-end d-none d-md-block'>
            <div className='mobile-social-icon'>
              <h6>
                <FormattedMessage id='FollowUsOn' />
              </h6>
              <a className='hover-up' target='_blank' href='https://www.facebook.com/profile.php?id=61554251262875'>
                <img src={'/media/svg/icons/icon-facebook-white.svg'} alt='' />
              </a>
              <a className='hover-up d-flex align-items-center' target='_blank' href='https://www.linkedin.com/in/d%C4%A9-%C4%91o%C3%A0n-v%C4%83n-7ba4562a9/'>
                <i className='fa-brands fa-linkedin-in text-white' aria-hidden='true'></i>
              </a>

              <a className='hover-up' target='_blank' href='https://www.youtube.com/@Green24h'>
                <img src={'/media/svg/icons/icon-youtube-white.svg'} alt='' />
              </a>
              {/* <a className='hover-up'>
                <img src={'/media/svg/icons/icon-instagram-white.svg'} alt='' />
              </a>
              <a className='hover-up'>
                <img src={'/media/svg/icons/icon-twitter-white.svg'} alt='' />
              </a>
              
              <a className='hover-up d-flex align-items-center'>
                <i className='fa-brands fa-tiktok text-white' aria-hidden='true'></i>
              </a> */}
            </div>
            <p className='font-sm'>
              <FormattedMessage id='Footer.TextDiscount' />
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer
