import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { withPageData } from './PageData'

const Breadcrumb = ({ pageTitle, pageDescription, pageBreadcrumbs, isHideTitle }) => {
  return (
    <div
      className='page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0'
      data-swapper='true'
      data-swapper-mode='prepend'
      data-swapper-parent="{default: 'content_container', lg: 'header_container'}"
    >
      {pageBreadcrumbs && pageBreadcrumbs.length > 0 && (
        <ul className='breadcrumb fw-bold my-1'>
          {pageBreadcrumbs.map((item, index) => (
            <Fragment key={`${item.path}${index}`}>
              {
                <li className='breadcrumb-item'>
                  {item.path ? (
                    <Link to={item.path} className='text-primary'>
                      {item.title}
                    </Link>
                  ) : (
                    <span className='text-muted'>{item.title}</span>
                  )}
                </li>
              }
            </Fragment>
          ))}
          <li className='breadcrumb-item text-muted'>{pageTitle}</li>
        </ul>
      )}

      {!isHideTitle && (
        <h1 className='text-dark fw-bolder my-0 fs-2'>
          {pageTitle && pageTitle}
          {pageDescription && <span className='text-muted fs-6 fw-normal ms-1'>{pageDescription}</span>}
        </h1>
      )}
    </div>
  )
}

export default withPageData(Breadcrumb)
