import { Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Icon from '../../../components/shared/Icon'
import Confirm from '../../../components/shared/Confirm'
import { useCustomerService } from '../../../services/apiServices'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import _ from 'lodash'
import CreateAddressModal from '../../customer/CreateAddressModal'
import { useAuth } from '../../../components/auth/AuthContext'
import { ROLE } from '../../../utilities/constant'

const CustomerAddress = () => {
  const intl = useIntl()
  const { user } = useAuth()
  const { deleteCustomerAddressAsync, getByIdAsync } = useCustomerService()

  const [address, setAddress] = useState(null)
  const [isEditAddress, setIsEditAddress] = useState(false)
  const [customer, setCustomer] = useState({})
  const [customerId, setCustomerId] = useState(user?.profile?.customer?.id)

  useEffect(() => {
    setCustomerId(user?.profile?.customer?.id)
  }, [user])

  useEffect(() => {
    getCustomer()
  }, [customerId])

  const handleCreateAddress = () => {
    setAddress({ isAdded: true })
    setIsEditAddress(true)
  }

  const handleEditAddress = (item) => {
    setAddress(item)
    setIsEditAddress(true)
  }

  const handleRemoveAddress = async (id) => {
    const { error } = await deleteCustomerAddressAsync(id)

    if (_.isEmpty(error)) {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteSuccess' })}`, TOAST_TYPES.SUCCESS)
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.DeleteError' })}`, TOAST_TYPES.ERROR)
    }
  }

  const getCustomer = async () => {
    if (user?.profile?.role === ROLE.CUSTOMER) {
      const { result } = await getByIdAsync(customerId)
      setCustomer(result ?? {})
    }
  }

  return (
    <div className='card mb-4 mb-xl-10'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0 d-flex align-items-center justify-content-between w-100'>
          <h4 className='fw-bolder m-0'>{intl.formatMessage({ id: 'Prodventory.Warehouse.Address' })}</h4>
          <a className='btn btn-sm btn-primary d-flex align-items-center gap-2' onClick={handleCreateAddress}>
            <Icon iconName='PlusOutlined' className='fs-3' />
            {intl.formatMessage({ id: 'AddNewAddress' })}
          </a>
        </div>
      </div>
      <div className='collapse show'>
        <div className='card-body border-top'>
          <div className='table-responsive'>
            <table className='table dataTable align-middle table-row-dashed table-hover'>
              <tbody className='fw-semibold'>
                {_.map(customer?.address, function (item, index) {
                  return (
                    <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>{item.fullAddress}</div>
                        {item.isDefault && <div className='ms-1 badge bg-danger text-light'>{intl.formatMessage({ id: 'Default' })}</div>}
                      </td>
                      <td className='min-w-100px d-flex justify-content-end gap-2'>
                        <Tooltip title={intl.formatMessage({ id: 'Edit' })}>
                          <div onClick={() => handleEditAddress(item)} className='btn btn-sm btn-icon btn-light-primary'>
                            <Icon iconName='EditOutlined' />
                          </div>
                        </Tooltip>

                        <Confirm
                          title={intl.formatMessage({ id: 'Notifications' })}
                          description={intl.formatMessage({ id: 'Confirm.Remove' })}
                          onConfirm={() => handleRemoveAddress(item.id)}
                          tooltip={intl.formatMessage({ id: 'Delete' })}
                          button={
                            <div className='btn btn-sm btn-icon btn-light-danger'>
                              <Icon iconName='DeleteOutlined' />
                            </div>
                          }
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isEditAddress && <CreateAddressModal customerId={customerId} editAddress={address} closeModal={setIsEditAddress} fetchAddresses={() => getCustomer()} />}
    </div>
  )
}

export default CustomerAddress
