import { useIntl } from 'react-intl'
import { useState } from 'react'
import Modal from '../../components/shared/Modal'
import Button from '../../components/shared/Button'
import NumberFormat from '../../components/shared/NumberFormat'
import { DEFAULT_VALUE } from '../../utilities/constant'

const ShippingUnitModal = ({ closeModal, shippingMethodSelected, shippingMethods, onChangeShippingMethod }) => {
  const intl = useIntl()
  const today = new Date()
  const [shippingMethod, setShippingMethod] = useState(shippingMethodSelected)

  const handleSave = () => {
    closeModal(false)
    onChangeShippingMethod(shippingMethod)
  }

  return (
    <Modal title={intl.formatMessage({ id: 'Checkout.ChooseShippingUnit' })} modalSize='mw-650px' closeModal={closeModal} paddingLess={true}>
      <div className='shipping-unit d-flex justify-content-between flex-column min-h-250px'>
        <div className='d-flex flex-column cursor-pointer'>
          {shippingMethods.map((d, index) => (
            <div
              key={d + index}
              className={`shipping-item d-flex align-items-center justify-content-between  ${d.value === shippingMethod.value ? 'active' : ''}`}
              onClick={() => setShippingMethod(d)}
            >
              <div className='me-3'>
                <div className='shipping-type'>
                  {d.name}{' '}
                  <span className='color-red'>({<NumberFormat amount={d.shippingCost} className='shipping-type color-red' suffix={DEFAULT_VALUE.VND} />})</span>
                </div>
                <div className='shipping-time gray-color'>
                  <span>
                    {intl.formatMessage({ id: 'Checkout.GuaranteedReceiptGoods' })} {today.getUTCDate() + d.receivedDay} {intl.formatMessage({ id: 'Month' })}{' '}
                    {today.getMonth() + 1} - {today.getUTCDate() + d.receivedDay + 2} {intl.formatMessage({ id: 'Month' })} {today.getMonth() + 1}
                  </span>
                </div>
                {/* <div className='shipping-voucher gray-color'>
                  {intl.formatMessage({ id: 'Checkout.ReceiveVoucher' })} {today.getUTCDate() + d.receivedDay + 2} {intl.formatMessage({ id: 'Month' })}{' '}
                  {today.getMonth() + 1} {today.getFullYear()}.
                </div> */}
              </div>
              {d.value === shippingMethod.value && (
                <div className='ml-auto'>
                  <img src={'/media/svg/icons/selected.svg'} alt='' className='stardust-icon stardust-icon-tick' />
                </div>
              )}
            </div>
          ))}
        </div>

        <div className='text-center py-4'>
          <Button
            onClick={() => closeModal(false)}
            title={intl.formatMessage({ id: 'Close' })}
            className={'btn btn-light me-3'}
            tabIndex={4}
            icon='CloseOutlined'
          />
          <Button onClick={() => handleSave()} title={intl.formatMessage({ id: 'Complete' })} className={'btn btn-primary'} tabIndex={5} icon='SaveOutlined' />
        </div>
      </div>
    </Modal>
  )
}

export default ShippingUnitModal
