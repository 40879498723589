import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import SVG from 'react-inlinesvg'
import Breadcrumb from '../../components/layout/components/breadcrumb/Breadcrumb'
import NumberFormat from '../../components/shared/NumberFormat'
import BankAccounts from './components/BankAccounts'
import WithdrawalHistories from './components/WithdrawalHistories'
import SalesRevenues from './components/SalesRevenues'
import Icon from '../../components/shared/Icon'
import WithdrawalEditor from './components/WithdrawalEditor'
import { useAccountHeaderContext } from './components/AccountHeaderContext'
import { useSalesRevenueService } from '../../services/apiServices'
import { useAuth } from '../../components/auth/AuthContext'

const AccountBalance = () => {
  const intl = useIntl()
  const { setReloadWithdrawalHistories, accountBalance } = useAccountHeaderContext()
  const { getTotalAmountAsync } = useSalesRevenueService()
  const { user } = useAuth()

  const [monthlyCommission, setMonthlyCommission] = useState(0)
  const [commissionLastMonth, setCommissionLastMonth] = useState(0)
  const [withdrawModal, setWithdrawModal] = useState({ id: null, show: false })

  useEffect(() => {
    const now = new Date()
    const currentYear = now.getFullYear()
    const currentMonth = now.getMonth() + 1

    fetchData(currentYear, currentMonth, true)
    fetchData(currentYear, currentMonth - 1, false)
  }, [user])


  const fetchData = async (year, month, isCurrent) => {
    const { result } = await getTotalAmountAsync({
      year,
      month,
    })

    if (isCurrent) {
      setMonthlyCommission(result ?? 0)
    } else {
      setCommissionLastMonth(result ?? 0)
    }
  }

  const showWithdrawModal = (id) => {
    setWithdrawModal({ id, show: true })
  }

  const hideWithdrawModal = () => {
    setWithdrawModal({ id: null, show: false })
  }

  return (
    <div className='account-balance'>
      <div className='header-page'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='title'>
            <h1 className='mb-4'>{intl.formatMessage({ id: 'Enum.AccountBalance' })}</h1>
            <Breadcrumb />
          </div>
        </div>
      </div>

      <div className='card my-5 d-flex gap-4 flex-sm-column flex-md-row'>
        <div className='shadow-sm card-body d-flex flex-row align-items-center rounded justify-content-between'>
          <div className='d-flex flex-row align-items-center w-50'>
            <div className='text-primary'>
              <SVG src={'/media/svg/icons/wallet-blue.svg'} />
            </div>
            <div className='w-100 ms-3'>
              <p className='fw-bold mb-1 lh-lg'>Có thể rút</p>
              <NumberFormat type='text' amount={accountBalance} className='text-primary' suffix='đ' />
            </div>
          </div>

          <div className='d-flex align-items-end w-200px w-sm-300px flex-column'>
            <div onClick={() => showWithdrawModal()} className='d-flex gap-2 btn btn-primary'>
              <Icon iconName='TransactionOutlined' />
              <FormattedMessage id='Ecom.Supplier.Withdrawal' />
            </div>
          </div>
        </div>

        <div className='shadow-sm card-body rounded d-flex flex-row align-items-center rounded'>
          <div className='text-primary'>
            <SVG src={'/media/svg/icons/wallet-red.svg'} />
          </div>
          <div className='w-100 ms-3'>
            <p className='fw-bold mb-1 lh-lg'>Doanh số tháng này</p>
            <NumberFormat amount={monthlyCommission} className='text-primary' suffix='đ' />
          </div>
          <i className='ms-1 fa fa-angle-right'></i>
        </div>

        <div className='shadow-sm card-body rounded d-flex flex-row align-items-center rounded'>
          <div className='text-primary'>
            <SVG src={'/media/svg/icons/wallet-red.svg'} />
          </div>
          <div className='w-100 ms-3'>
            <p className='fw-bold mb-1 lh-lg'>Doanh số tháng trước</p>
            <NumberFormat amount={commissionLastMonth} className='text-primary' suffix='đ' />
          </div>
          <i className='ms-1 fa fa-angle-right'></i>
        </div>
      </div>

      <BankAccounts />
      <WithdrawalHistories />
      <SalesRevenues />
      <WithdrawalEditor modalState={withdrawModal} handleClose={() => hideWithdrawModal()} reloadList={() => setReloadWithdrawalHistories(true)} />
    </div>
  )
}

export default AccountBalance
