import { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useAuth } from '../../components/auth/AuthContext'
import { AFFILIATE_STATUS_ENUM, getKeyByValue } from '../../utilities/enum'
import Table from '../../components/shared/Table'
import TableSearch from '../../components/shared/TableSearch'
import { useAffiliateService } from '../../services/apiServices'

const Affiliates = () => {
  const intl = useIntl()
  const { user } = useAuth()
  const { getSubordinateAffiliateAsync } = useAffiliateService()

  const [filter, setFilter] = useState({ search: null })
  const [loadData, setLoadData] = useState(false)

  useEffect(() => {
    setLoadData(true)
  }, [filter])

  const fetchData = async (pageIndex, pageSize) => {
    setLoadData(false)
    return await getSubordinateAffiliateAsync({ pageIndex, pageSize, userId: user?.profile?.id, ...filter })
  }

  const columns = [
    {
      title: `${intl.formatMessage({ id: 'Affiliate.AffiliateName' })}`,
      dataIndex: 'fullName',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      width: 200,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Affiliate.Email' })}`,
      dataIndex: 'email',
      width: 100,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Affiliate.Phone' })}`,
      dataIndex: 'phoneNumber',
      width: 100,
      showSorterTooltip: false,
    },
    {
      title: `${intl.formatMessage({ id: 'Affiliate.ApproveStatusName' })}`,
      dataIndex: 'status',
      width: 100,
      showSorterTooltip: false,
      render: (status, data) => (
        <span
          className={`badge badge-${
            status === AFFILIATE_STATUS_ENUM.PENDINGAPPROVAL ? 'primary' : status === AFFILIATE_STATUS_ENUM.APPROVED ? 'success' : 'secondary'
          } me-1 fs-7`}
        >
          <FormattedMessage id={`Enum.${data.statusName}`} />
        </span>
      ),
    },
    {
      title: `${intl.formatMessage({ id: 'Affiliate.RegisterDate' })}`,
      dataIndex: 'createdDateStr',
      width: 100,
      showSorterTooltip: false,
    },
  ]

  const toolbar = (
    <div className='d-flex flex-column flex-md-row justify-content-end gap-4'>
      <div className='d-flex flex-column flex-sm-row gap-4'>
        <TableSearch onSearch={setFilter} />
      </div>
    </div>
  )

  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0'>
          <h4 className='fw-bolder m-0'>{intl.formatMessage({ id: 'IntroduceCollaborator' })}</h4>
        </div>
      </div>
      <div className='collapse show'>
        <Table columns={columns} fetchData={fetchData} onReload={loadData} toolbar={toolbar} />
      </div>
    </div>
  )
}

export default Affiliates
