import React, { useState, useEffect, useRef } from 'react'
import { Table as AntTable, Pagination, Select } from 'antd'
import { useIntl } from 'react-intl'
import { DEFAULT_VALUE } from '../../utilities/constant'
import { showToast, TOAST_TYPES } from '../../components/shared/Toast'
import { formatString } from '../../utilities/sharedFunction'

const { Option } = Select

const Table = ({ columns, fetchData, rowSelection, colExpandable, scrollY, ellipsis, onReload, toolbar }) => {
  const parentRef = useRef(null)
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [scrollX, setScrollX] = useState(undefined)
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize, setPageSize] = useState(DEFAULT_VALUE.PAGE_SIZE)
  const [totalCount, setTotalCount] = useState(0)
  const [dataSource, setDataSource] = useState([])
  const scroll = {}

  useEffect(() => {
    if (onReload) {
      loadData()
    }
  }, [onReload])

  const handleTableChange = (page, size) => {
    setCurrentPage(page)
    setPageSize(size)
    loadData()
  }

  const loadData = async () => {
    setLoading(true)
    setDataSource([])
    setTotalCount(0)
    try {
      const { result } = await fetchData(currentPage, pageSize)
      if (result) {
        setDataSource(result.items)
        setTotalCount(result.totalCount ?? 0)
      } else {
        showToast(`${intl.formatMessage({ id: 'Error.Loading' })}`, TOAST_TYPES.ERROR)
      }
    } catch (error) {
      console.log(error)
      showToast(`${intl.formatMessage({ id: 'Error.Loading' })}`, TOAST_TYPES.ERROR)
    } finally {
      setLoading(false)
    }
  }

  const tableColumns = columns.map((item) => ({
    ...item,
    ellipsis,
  }))

  if (scrollY) {
    scroll.y = scrollY
  }

  if (scrollX) {
    scroll.x = '100vw'
    tableColumns[0].fixed = true
    tableColumns[tableColumns.length - 1].fixed = 'right'
  }

  const tableProps = {
    bordered: true,
    loading,
    size: 'small', //large, middle, small
    expandable: colExpandable,
    showHeader: true,
    rowSelection: rowSelection ?? false,
  }

  return (
    <div
      ref={parentRef}
      // style={{
      //   overflowX: 'auto',
      //   width: `calc(100vw - ${asideMinimized ? '125px' : '385px'})`,
      //   maxWidth: `calc(100vw - ${asideMinimized ? '125px' : '385px'})`,
      // }}
    >
      <AntTable
        {...tableProps}
        pagination={false}
        columns={tableColumns}
        dataSource={
          dataSource
            ? dataSource.map((row, index) => ({
                ...row,
                key: row.id || index,
              }))
            : []
        }
        scroll={scroll}
        title={toolbar ? () => toolbar : null}
        footer={() => (
          <div className='d-flex justify-content-between align-items-center'>
            <span className='d-none d-sm-inline'>
              {formatString(`${intl.formatMessage({ id: 'ShowingTo' })} `, currentPage)}
              <Select defaultValue={pageSize} style={{ width: 70 }} onChange={(e) => handleTableChange(currentPage, e)}>
                <Option value={10}>10</Option>
                <Option value={25}>25</Option>
                <Option value={50}>50</Option>
                <Option value={100}>100</Option>
              </Select>
              {formatString(` ${intl.formatMessage({ id: 'OfItems' })}`, totalCount)}
            </span>
            <Pagination current={currentPage} pageSize={pageSize} total={totalCount} onChange={handleTableChange} />
          </div>
        )}
      />
    </div>
  )
}
export default Table
