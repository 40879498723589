import React, { useEffect, useState } from 'react'
import { Route, Routes, useParams, Navigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import Purchases from './Purchases'
import { purchaseRoutes, PurchasedProducts } from '.'
import { useOrderService } from '../../services/apiServices'
import { PageTitle } from '../../components/layout/components/breadcrumb/PageData'
import { useAuth } from '../../components/auth/AuthContext'

const PurchasePage = () => {
  const intl = useIntl()
  const { status } = useParams()
  const { user } = useAuth()
  const { getListAsync } = useOrderService()
  const [orders, setOrders] = useState([])

  useEffect(() => {
    const fetchOrders = async () => {
      const { result } = await getListAsync({ pageIndex: 0, pageSize: 10, status })
      setOrders(result?.items ?? [])
    }
    fetchOrders()
  }, [status, user])

  const route = purchaseRoutes.find((d) => d.type.toString() === status)
  const loadBreadcrumbs = () => {
    let item = [{ title: intl.formatMessage({ id: 'Home' }), path: '/home' }, { title: intl.formatMessage({ id: 'OrderTracking' }) }]
    return item
  }

  return (
    <Routes>
      <Route path='*' element={<Navigate to='/' />} />
      <Route path='/' element={<Purchases />}>
        <Route
          path='/'
          element={
            <>
              <PageTitle breadcrumbs={loadBreadcrumbs} isHideTitle={true}>
                {intl.formatMessage({ id: route.title })}
              </PageTitle>
              <PurchasedProducts orders={orders} />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default PurchasePage
