import { useIntl } from 'react-intl'
import { useState } from 'react'
import Modal from '../../components/shared/Modal'
import Button from '../../components/shared/Button'

const DeliveryAddressModal = ({ closeModal, addresses, selectedAddress, setSelectedAddress }) => {
  const intl = useIntl()
  const [address, setAddress] = useState(selectedAddress)
  const handleSave = () => {
    closeModal(false)
    if (address === selectedAddress) return
    setSelectedAddress({ ...address })
  }

  return (
    <Modal title={intl.formatMessage({ id: 'Checkout.ChooseDeliveryAddress' })} modalSize='mw-750px' closeModal={closeModal} paddingLess={true}>
      <div className='shipping-unit d-flex justify-content-between flex-column min-h-250px'>
        <div>
          {addresses?.map((d, index) => (
            <div key={index} className='d-flex flex-column cursor-pointer' onClick={() => setAddress(d)}>
              <div className={`shipping-item ${address?.id === d.id ? 'active' : ''} d-flex align-items-center`}>
                <div className='me-5'>
                  <div className='shipping-type'>{d.fullAddress}</div>
                </div>
                {address?.id === d.id && (
                  <div className='ml-auto'>
                    <img src={'/media/svg/icons/selected.svg'} alt='' className='stardust-icon stardust-icon-tick' />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className='text-center py-4'>
          <Button
            onClick={() => closeModal(false)}
            title={intl.formatMessage({ id: 'Close' })}
            className={'btn btn-light me-3'}
            tabIndex={4}
            icon='CloseOutlined'
          />
          <Button onClick={() => handleSave()} title={intl.formatMessage({ id: 'Complete' })} className={'btn btn-primary'} tabIndex={5} icon='SaveOutlined' />
        </div>
      </div>
    </Modal>
  )
}

export default DeliveryAddressModal
