import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { useAuth } from '../../../components/auth/AuthContext'
import { ROLE } from '../../../utilities/constant'
import { FormControl, validationAllComponents } from '../../../components/shared/FormControl'
import { CONTROL_TYPE } from '../../../components/shared/FormControl'
import { useAffiliateService, useCustomerService, useLocationService } from '../../../services/apiServices'
import { addToFormData } from '../../../utilities/sharedFunction'
import { showToast, TOAST_TYPES } from '../../../components/shared/Toast'
import Button from '../../../components/shared/Button'
import dayjs from 'dayjs'

const UserProfile = () => {
  const intl = useIntl()
  const { user } = useAuth()
  const componentRefs = useRef({})
  let tabIndex = 1
  const { getProvincesAsync, getDistrictsAsync, getWardsAsync } = useLocationService()
  const { getByUserIdAsync: getCustomer, updateAsync: updateCustomer } = useCustomerService()
  const { getByUserIdAsync: getAffiliate, updateAsync: updateAffiliate } = useAffiliateService()

  const [provinces, setProvinces] = useState([])
  const [districts, setDistricts] = useState([])
  const [wards, setWards] = useState([])

  const [id, setId] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [email, setEmail] = useState(null)
  const [birthday, setBirthday] = useState(null)
  const [provinceId, setProvinceId] = useState(null)
  const [districtId, setDistrictId] = useState(null)
  const [wardId, setWardId] = useState(null)
  const [addressLine, setAddressLine] = useState(null)

  useEffect(() => {
    if (user) {
      getData()
      fetchProvinces()
    }
  }, [user])

  const fetchProvinces = async () => {
    const { result } = await getProvincesAsync()
    if (result) {
      setProvinces(
        result.map((item) => {
          return { value: item.id, label: item.name }
        })
      )
    }
  }

  useEffect(() => {
    const fetchDistricts = async (provinceId) => {
      const { result } = await getDistrictsAsync(provinceId)
      if (result) {
        const districtList = result.map((item) => ({ value: item.id, label: item.name }))

        setDistricts(districtList)

        if (!districtList.some((district) => district.value === districtId)) {
          setDistrictId(null)
        }
      }
    }

    if (provinceId) {
      fetchDistricts(provinceId)
    } else {
      setDistricts([])
      setDistrictId(null)
    }
  }, [provinceId])

  useEffect(() => {
    const fetchWards = async (districtId) => {
      const { result } = await getWardsAsync(districtId)
      if (result) {
        const wardsList = result.map((item) => ({ value: item.id, label: item.name }))

        setWards(wardsList)

        if (!wardsList.some((ward) => ward.value === wardId)) {
          setWardId(null)
        }
      }
    }

    if (districtId) {
      fetchWards(districtId)
    } else {
      setWards([])
      setWardId(null)
    }
  }, [districtId])

  const getData = async () => {
    if (user?.profile?.role === ROLE.AFFILIATE) {
      const { result } = await getAffiliate(user?.profile?.id)
      loadData(result.id, result.firstName, result.lastName, result.phoneNumber, result.email, result.gender, result.birthday, result.address)
    } else {
      const { result } = await getCustomer(user?.profile?.id)
      if (result) {
        loadData(result.id, result.firstName, result.lastName, result.phoneNumber, result.email, result.gender, result.birthday)
      }
    }
  }

  const handleSave = async () => {
    const isValid = await validationAllComponents(componentRefs)
    if (!isValid) return

    const formData = new FormData()

    addToFormData(formData, 'id', id)
    addToFormData(formData, 'firstName', firstName)
    addToFormData(formData, 'lastName', lastName)
    addToFormData(formData, 'phoneNumber', phoneNumber)
    addToFormData(formData, 'email', email)
    addToFormData(formData, 'birthday', birthday.toISOString())
    if (user?.profile?.role === ROLE.AFFILIATE) {
      addToFormData(formData, 'address', {
        provinceId,
        districtId,
        wardId,
        addressLine,
        isActive: true,
      })

      const { error } = await updateAffiliate(formData)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
        getData()
      } else {
        showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
        console.log(error)
      }
    } else {
      const { error } = await updateCustomer(formData)
      if (_.isEmpty(error)) {
        showToast(`${intl.formatMessage({ id: 'Notify.SaveSuccess' })}`, TOAST_TYPES.SUCCESS)
        getData()
      } else {
        showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
        console.log(error)
      }
    }
  }

  const loadData = (id, firstName, lastName, phoneNumber, email, gender, birthday, address) => {
    setId(id)
    setFirstName(firstName)
    setLastName(lastName)
    setPhoneNumber(phoneNumber)
    setEmail(email)
    setBirthday(birthday ? dayjs(birthday) : null)
    setProvinceId(address?.provinceId)
    setDistrictId(address?.districtId)
    setWardId(address?.wardId)
    setAddressLine(address?.addressLine)
  }

  return (
    <div className='card mb-4'>
      <div className='card-header cursor-pointer'>
        <div className='card-title m-0 d-flex justify-content-between w-100'>
          <h4 className='fw-bolder m-0'>{intl.formatMessage({ id: 'User.UserProfile' })}</h4>
          <Button onClick={handleSave} icon='SaveOutlined' title={intl.formatMessage({ id: 'Save' })} className={'btn btn-primary'} />
        </div>
      </div>
      <div className='collapse show'>
        <div className='card-body border-top p-9'>
          <div className='flex-row-fluid'>
            <div className='d-flex w-100 flex-column align-items-center'>
              <div className='col-12 col-md-6 mb-3 d-flex gap-3'>
                <div className='col-6'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.customerName = ref)}
                    tabIndex={tabIndex++}
                    controlType={CONTROL_TYPE.INPUT}
                    label={intl.formatMessage({ id: 'Ecom.Affiliate.FirstName' })}
                    value={firstName}
                    isRequired={true}
                    onChange={setFirstName}
                  />
                </div>
                <div className='col-6'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.customerName = ref)}
                    tabIndex={tabIndex++}
                    controlType={CONTROL_TYPE.INPUT}
                    label={intl.formatMessage({ id: 'Ecom.Affiliate.LastName' })}
                    value={lastName}
                    isRequired={true}
                    onChange={setLastName}
                  />
                </div>
              </div>

              <div className='col-12 col-md-6 mb-3 d-flex gap-3'>
                <div className='col-6'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.phoneNumber = ref)}
                    tabIndex={tabIndex++}
                    controlType={CONTROL_TYPE.PHONENUMBER}
                    label={intl.formatMessage({ id: 'PhoneNumber' })}
                    value={phoneNumber}
                    isRequired={true}
                    onChange={setPhoneNumber}
                  />
                </div>
                <div className='col-6'>
                  <FormControl
                    ref={(ref) => (componentRefs.current.birthday = ref)}
                    tabIndex={tabIndex++}
                    controlType={CONTROL_TYPE.DATEPICKER}
                    label={intl.formatMessage({ id: 'Affiliate.Birthday' })}
                    value={birthday}
                    isRequired={true}
                    onChange={setBirthday}
                  />
                </div>
              </div>
              <div className='col-12 col-md-6  mb-3'>
                <FormControl
                  ref={(ref) => (componentRefs.current.email = ref)}
                  tabIndex={tabIndex++}
                  controlType={CONTROL_TYPE.INPUT}
                  label={intl.formatMessage({ id: 'Email' })}
                  isRequired={true}
                  value={email}
                  onChange={setEmail}
                  readOnly={true}
                />
              </div>
              {user?.profile?.role === ROLE.AFFILIATE && (
                <>
                  <div className='col-12 col-md-6 d-flex gap-3'>
                    <div className='col-6 mb-3'>
                      <FormControl
                        controlType={CONTROL_TYPE.SELECT}
                        ref={(ref) => (componentRefs.current.provinceId = ref)}
                        label={intl.formatMessage({ id: 'Province' })}
                        isRequired={true}
                        value={provinceId}
                        onChange={setProvinceId}
                        tabIndex={tabIndex++}
                        options={provinces}
                      />
                    </div>
                    <div className='col-6 mb-3'>
                      <FormControl
                        controlType={CONTROL_TYPE.SELECT}
                        ref={(ref) => (componentRefs.current.districtId = ref)}
                        label={intl.formatMessage({ id: 'District' })}
                        isRequired={true}
                        value={districtId}
                        onChange={setDistrictId}
                        tabIndex={tabIndex++}
                        options={districts}
                      />
                    </div>
                  </div>

                  <div className='col-12 col-md-6 mb-3'>
                    <FormControl
                      controlType={CONTROL_TYPE.SELECT}
                      ref={(ref) => (componentRefs.current.wardId = ref)}
                      label={intl.formatMessage({ id: 'Ward' })}
                      isRequired={true}
                      value={wardId}
                      onChange={setWardId}
                      tabIndex={tabIndex++}
                      options={wards}
                    />
                  </div>
                  <div className='col-12 col-md-6 mb-3'>
                    <FormControl
                      ref={(ref) => (componentRefs.current.addressLine = ref)}
                      controlType={CONTROL_TYPE.TEXTAREA}
                      label={intl.formatMessage({ id: 'AddressLine' })}
                      value={addressLine}
                      rows={2}
                      onChange={setAddressLine}
                      tabIndex={tabIndex++}
                      isRequired={true}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserProfile
