import { useAuth } from '../../components/auth/AuthContext'
import { ROLE } from '../../utilities/constant'
import CustomerAddress from './components/CustomerAddress'
import UserProfile from './components/UserProfile'

const Overview = () => {
  const { user } = useAuth()

  return (
    <>
      <UserProfile></UserProfile>
      {user?.profile?.role === ROLE.CUSTOMER && <CustomerAddress></CustomerAddress>}
    </>
  )
}
export default Overview
