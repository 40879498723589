import React, { createContext, useContext, useEffect, useState } from 'react'
import { ROLE } from '../../../src/utilities/constant'

const AuthContext = createContext()

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [userPermissions, setUserPermissions] = useState()

  const AUTH_LOCAL_USER = process.env.REACT_APP_AUTH_LOCAL_USER
  const AUTH_LOCAL_URL = 'returnUrl'

  useEffect(() => {
    if (window.location.pathname !== '/login' && window.location.pathname !== '/logout') {
      loadUserFromStorage()
    }
  }, [])

  const loadUserFromStorage = async () => {
    const currentUser = await getUser()
    if (currentUser) {
      setUser(currentUser)
      setUserPermissions(currentUser?.profile?.role === ROLE.AFFILIATE ? true : false)
    }
  }

  const getUser = async () => {
    const currentUser = localStorage.getItem(AUTH_LOCAL_USER)
    return currentUser ? JSON.parse(currentUser) : null
  }

  const reloadUser = async (user) => {
    localStorage.setItem(AUTH_LOCAL_USER, user ? JSON.stringify(user) : null)
    if (user) {
      window.location.href = '/'
    } else {
      window.location.href = '/login'
    }
  }

  const login = async () => {
    localStorage.setItem(AUTH_LOCAL_URL, window.location.href)
    window.location.href = '/login'
  }

  const logout = async () => {
    localStorage.setItem(AUTH_LOCAL_URL, window.location.href)
    window.location.href = '/logout'
  }

  const register = async () => {
    localStorage.setItem(AUTH_LOCAL_URL, window.location.href)
    window.location.href = '/registration'
  }

  const checkLogin = async () => {
    const currentUser = await getUser()
    if (!currentUser) {
      login()
    }
  }

  const returnUrl = () => {
    return localStorage.getItem(AUTH_LOCAL_URL)
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        userPermissions,
        reloadUser,
        login,
        logout,
        register,
        checkLogin,
        returnUrl,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
