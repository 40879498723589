import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Carousel } from 'react-bootstrap'
import ImageCarousel from '../../../components/shared/ImageCarousel'
import Product from '../../../components/shared/Product'
import { DEFAULT_VALUE } from '../../../utilities/constant'
import { useFeaturedProductService } from '../../../services/apiServices'

const ProductsByCategory = ({ categories }) => {
  const { getByCategoryAsync } = useFeaturedProductService()
  const [currentBanner, setCurrentBanner] = useState(null)
  const [index, setIndex] = useState(0)
  const [products, setProducts] = useState([])

  useEffect(() => {
    setCurrentBanner(categories[index])
    if (categories[index]) {
      fetchData(categories[index].id)
    }
  }, [index, categories])

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex)
  }

  const handleInterval = () => {
    setCurrentBanner(categories[index])
  }

  const fetchData = async (categoryId) => {
    const { result } = await getByCategoryAsync(categoryId, {
      pageIndex: DEFAULT_VALUE.PAGE_INDEX,
      pageSize: 20,
    })
    if (result) {
      setProducts(result.items)
    }
  }

  const responsive = (baseSlidesToShow) => {
    return [
      { breakpoint: 1500, settings: { slidesToShow: baseSlidesToShow - 1 } },
      { breakpoint: 770, settings: { slidesToShow: baseSlidesToShow - 2 } },
      { breakpoint: 400, settings: { slidesToShow: baseSlidesToShow - 3 } },
      // { breakpoint: 400, settings: { slidesToShow: baseSlidesToShow - 3 } },
    ]
  }
  return (
    <section id='home-product-by-category'>
      <div className='mb-8 mb-xl-14'>
        <div className='section-title d-flex align-items-center justify-content-between'>
          <h3>{currentBanner?.name}</h3>
          <Link to={`/categories/${currentBanner?.id}`} className='fw-bold text-primary'>
            <FormattedMessage id='ViewAll' />
            <i className='fa fa-angle-right ms-2 text-primary' aria-hidden='true'></i>
          </Link>
        </div>
        <div className='d-flex justify-content-between p-0'>
          <div className='category-banner col-3 d-none d-lg-flex'>
            <Carousel
              activeIndex={index}
              onSelect={handleSelect}
              onSlide={handleInterval}
              nextIcon={<span aria-hidden='true' className='carousel-control-next-icon changed' />}
            >
              {categories?.map((item) => (
                <Carousel.Item key={item.id} interval={300 * DEFAULT_VALUE.THOUDSAND_MILLISECOND}>
                  <img className='d-block w-100 rounded' src={item?.fileImage?.url} alt='First slide' />
                  <Carousel.Caption></Carousel.Caption>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
          <div className='ps-0 ps-lg-8 col-12 col-lg-9'>
            <ImageCarousel Component={Product} products={products.map((x) => x.product)} columns={4} responsive={responsive} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductsByCategory
