import React, { useState, useEffect } from 'react'
import '../../assets/sass/home.scss'
import { useBannerService, useCategoryService, usePromotionService } from '../../services/apiServices'
import Banner from './components/Banner'
import { BANNER_POSITION_ENUM } from '../../utilities/enum'
import Categories from './components/Categories'
import CategoryBanners from './components/CategoryBanners'
import MarqueeText from './components/MarqueeText'
import Promotions from './components/Promotions'
import FeaturedProducts from './components/FeaturedProducts'
import TopProducts from './components/TopProducts'
import ProductsByCategory from './components/ProductsByCategory'
import _ from 'lodash'

const Home = () => {
  const { getCurrentListAsync: getBanners } = useBannerService()
  const { getCurrentListAsync: getCategories } = useCategoryService()
  const { getCurrentListAsync: getPromotions } = usePromotionService()

  const [banners, setBanners] = useState([])
  const [categories, setCategories] = useState([])
  const [promotions, setPromotions] = useState([])

  const fetchBanners = async () => {
    const { result } = await getBanners()
    if (result) {
      setBanners(result)
    }
  }

  const fetchCategories = async () => {
    const { result } = await getCategories()
    if (result) {
      setCategories(result)
    }
  }

  const fetchPromotions = async () => {
    const { result } = await getPromotions()
    if (result) {
      const sortedPromotions = result.sort((a, b) => new Date(b.fromDate) - new Date(a.fromDate))
      const latestPromotions = sortedPromotions.slice(0, 4)

      setPromotions(latestPromotions)
    }
  }

  useEffect(() => {
    fetchBanners()
    fetchCategories()
    fetchPromotions()
  }, [])

  const getRandomItems = (arr, n) => {
    let result = []
    let copy = [...arr]
    while (result.length < n && copy.length) {
      const index = Math.floor(Math.random() * copy.length)
      result.push(copy.splice(index, 1)[0])
    }
    return result
  }

  return (
    <>
      <Banner banners={banners.filter((e) => e.position === BANNER_POSITION_ENUM.TOP)} />
      <Categories categories={categories} />
      <CategoryBanners categories={getRandomItems(categories, 3)} />
      <MarqueeText items={categories.slice(0, 5).map((x) => x.name)} />
      <FeaturedProducts />
      <MarqueeText items={categories.slice(5, 10).map((x) => x.name)} />
      <ProductsByCategory categories={categories} />
      {banners.some((e) => e.position === BANNER_POSITION_ENUM.MIDDLE) && (
        <Banner banners={banners.filter((e) => e.position === BANNER_POSITION_ENUM.MIDDLE)} />
      )}
      {!_.isEmpty(promotions) && <Promotions promotions={promotions} />}
      {banners.some((e) => e.position === BANNER_POSITION_ENUM.BOTTOM) && (
        <Banner banners={banners.filter((e) => e.position === BANNER_POSITION_ENUM.BOTTOM)} />
      )}
      <TopProducts />
    </>
  )
}

export default Home
