import React, { useState, useEffect, useRef } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import '../../assets/sass/supplier.scss'
import { useSupplierService, useCategoryService } from '../../services/apiServices'
import CategoryFilter from '../search/components/CategoryFilter'
import NewsBanner from '../search/components/NewsBanner'
import Breadcrumb from '../../components/layout/components/breadcrumb/Breadcrumb'
import { DEFAULT_VALUE } from '../../utilities/constant'
import PageNumber from '../../components/shared/PageNumber'
import { FormControl, CONTROL_TYPE } from '../../components/shared/FormControl'

const ListSupplier = () => {
  const intl = useIntl()
  const componentRefs = useRef({})
  const { searchAsync } = useSupplierService()
  const { getCurrentListAsync: getCategories } = useCategoryService()

  const [timer, setTimer] = useState(null)

  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize] = useState(20)
  const [totalPages, setTotalPages] = useState(0)
  const [suppliers, setSuppliers] = useState([])
  const [categories, setCategories] = useState([])
  const [categoiesSearch, setCategoriesSearch] = useState([])
  const [nameSearch, setNameSearch] = useState(null)

  useEffect(() => {
    fetchCategories()
    fetchsuppliers()
    clearTimeout(timer)
  }, [])

  useEffect(() => {
    fetchsuppliers()
  }, [categoiesSearch])

  const fetchCategories = async () => {
    const { result } = await getCategories()
    if (result) {
      setCategories(result)
    }
  }

  const fetchsuppliers = async (search) => {
    const { result } = await searchAsync({
      pageIndex: currentPage,
      pageSize: pageSize,
      search: search ?? null,
      categoryIds: categoiesSearch ?? null,
    })
    if (result) {
      setSuppliers(result.items)
      setTotalPages(result.totalPageCount)
    }
  }

  const handleChangeCategory = (checked, id) => {
    var currentCategories = [...categoiesSearch]

    if (checked) {
      currentCategories.push(id)
    } else {
      currentCategories = currentCategories.filter((x) => x !== id)
    }

    setCategoriesSearch(currentCategories)
  }

  const handleInputChange = (e) => {
    setNameSearch(e)
    clearTimeout(timer)

    const newTimer = setTimeout(() => {
      fetchsuppliers(e)
    }, 1000)

    setTimer(newTimer)
  }

  return (
    <div className='list-supplier pb-8'>
      <div className='header-page'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='title'>
            <h1 className='mb-4'>{intl.formatMessage({ id: 'Suppliers' })}</h1>
            <Breadcrumb />
          </div>
        </div>
      </div>

      <div className='d-flex pt-8'>
        <div className='category-filter-panel col-3 pe-8'>
          <div className='mb-8'>
            <CategoryFilter categories={categories} handleChangeCategory={handleChangeCategory} />
          </div>

          <div className='mb-8'>
            <NewsBanner news={[]} />
          </div>
        </div>

        <div className='item-result col-9'>
          <div className='sort-bar mb-4'>
            <span>
              <FormattedMessage id='Search' />
            </span>
            <div className='sort-by-options'>
              <div className='ms-2 col-sm-10 col-md-6'>
                <FormControl
                  ref={(ref) => (componentRefs.current.nameSearch = ref)}
                  tabIndex={3}
                  controlType={CONTROL_TYPE.INPUT}
                  value={nameSearch}
                  onChange={handleInputChange}
                  isHideLabel={true}
                  label={intl.formatMessage({ id: 'SupplierName' })}
                />
              </div>
            </div>

            <div className='mini-page-controller'>
              <div className='state'>
                <span className='current'>{currentPage}</span>/<span className='total'>{totalPages}</span>
              </div>
              <a
                onClick={() => setCurrentPage(currentPage - 1)}
                className={`prev-btn btn button-outline ms-5 ${currentPage > 1 && currentPage <= totalPages ? '' : 'disabled'}`}
              >
                <i className='fa fa-angle-left' aria-hidden='true'></i>
              </a>

              <a onClick={() => setCurrentPage(currentPage + 1)} className={`next-btn btn button-outline ${currentPage < totalPages ? '' : 'disabled'}`}>
                <i className='fa fa-angle-right' aria-hidden='true'></i>
              </a>
            </div>
          </div>

          <div className='row row-cols-2 px-2'>
            {suppliers?.map((item) => (
              <Link className='card mb-4 p-0' to={`/supplier/${item?.id}`} key={item?.id}>
                <div className='d-flex flex-row justify-content-start p-4 border hover rounded'>
                  <div className='me-4'>
                    <div className='rounded-pill border d-inline-block position-relative'>
                      <img
                        width='70'
                        height='70'
                        src={item?.fileLogo?.url ?? '/media/jpg/shop.jpg'}
                        loading='lazy'
                        className='object-fit-contain rounded-pill'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='w-100'>
                    <div className='fw-bold text-uppercase'>{item?.companyName}</div>
                    <div className='row align-items-start'>
                      <div className='text-muted my-1'>
                        <SVG src={'/media/svg/icons/location.svg'} />
                        {item?.address}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>

          <div className='p-4'>
            <PageNumber currentPage={currentPage} totalPages={totalPages} className='pagination-lg' onPageChange={setCurrentPage} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListSupplier
