/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useAuth } from '../../../components/auth/AuthContext'

const UserMenu = () => {
  const { user, logout, userPermissions } = useAuth()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img src={user?.profile?.avatarUrl ?? '/media/images/no-avatar.png'} alt='avatar' />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user?.profile?.fullName}
              <span className='badge badge-light-primary fw-bolder fs-8 px-2 py-1 ms-2'>{user?.profile?.role}</span>
            </div>
            <div className='fw-bold text-muted text-hover-primary fs-7'>{user?.profile?.email}</div>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        {userPermissions && (
          <Link to={'/pages/accountBalance'} className='menu-link px-5'>
            <FormattedMessage id='Enum.AccountBalance' />
          </Link>
        )}

        <Link to={'/purchase/1'} className='menu-link px-5'>
          <FormattedMessage id='PurchaseOrder' />
        </Link>

        {userPermissions && (
          <>
            <Link to={'/pages/customer'} className='menu-link px-5'>
              <FormattedMessage id='Customer' />
            </Link>

            <Link to={'/user-profile/affiliates'} className='menu-link px-5'>
              <FormattedMessage id='IntroduceCollaborator' />
            </Link>
          </>
        )}
        {/* <Link to={'/'} className='menu-link px-5'>
          <FormattedMessage id='SalesPostTemplate' />
        </Link> */}
      </div>
      
      <div className='menu-item px-5'>
        <Link to={`/user-profile/overview`} className='menu-link px-5'>
          <FormattedMessage id='User.UserProfile' />
        </Link>

        <Link to={`/user-profile/change-password`} className='menu-link px-5'>
          <FormattedMessage id='User.ChangePassword' />
        </Link>

        <a onClick={() => logout()} className='menu-link px-5'>
          <FormattedMessage id='Logout' />
        </a>
      </div>
    </div>
  )
}

export default UserMenu
