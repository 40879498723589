import React from 'react'

import { DEFAULT_VALUE, ROLE } from './constant'

const getCurrentUrl = (pathname) => {
  return pathname.split(/[?#]/)[0]
}

export const checkIsActive = (pathname, url) => {
  const current = getCurrentUrl(pathname)
  if (!current || !url) {
    return false
  }

  if (current === url) {
    return true
  }

  if (current.indexOf(url) > -1) {
    return true
  }

  return false
}

export const isValidEmail = (email) => {
  const emailRegex = DEFAULT_VALUE.REGEX_EMAIL
  return emailRegex.test(email)
}

export const isValidPassword = (password) => {
  const passRegex = DEFAULT_VALUE.REGEX_PASSWORD
  return passRegex.test(password)
}

export const isValidPhoneNumber = (phoneNumber) => {
  const phoneNumberRegex = DEFAULT_VALUE.REGEX_PHONENUMBER
  return phoneNumberRegex.test(phoneNumber)
}

export const formatString = (template, ...args) => {
  return template.replace(/{(\d+)}/g, (match, index) => {
    const argIndex = parseInt(index, 10)
    return args[argIndex] !== undefined ? args[argIndex] : match
  })
}

export const formattedError = (error) => {
  return error?.includes('\n')
    ? error.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))
    : error
}

export const addToFormData = (formData, key, value) => {
  if (value !== null && value !== undefined) {
    if (value instanceof File) {
      formData.append(key, value)
    }
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        if (typeof item === 'object' && item) {
          Object.keys(item).forEach((subKey) => {
            formData.append(`${key}[${index}].${subKey}`, item[subKey])
          })
        } else {
          formData.append(key, item)
        }
      })
    } else if (typeof value === 'object') {
      Object.keys(value).forEach((subKey) => {
        if (value[subKey] !== null && value[subKey] !== undefined) {
          formData.append(`${key}.${subKey}`, value[subKey])
        }
      })
    } else {
      formData.append(key, value)
    }
  }
}

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const getBase64FromUrl = async (url) => {
  try {
    const response = await fetch(url)
    if (!response.ok) throw new Error('Network response was not ok')

    const blob = await response.blob()
    const reader = new FileReader()

    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
      reader.readAsDataURL(blob)
    })
  } catch (error) {
    throw new Error(`Failed to fetch image: ${error.message}`)
  }
}

export const getFileFromUrl = async (url, fileName, type) => {
  try {
    const response = await fetch(url)

    if (!response.ok) {
      throw new Error(`Failed to fetch file: ${response.statusText}`)
    }

    const blob = await response.blob()
    return new File([blob], fileName, { type: type, lastModified: Date.now() })
  } catch (error) {
    console.error('Fetch error:', error)
    throw new Error(`Failed to fetch file: ${error.message}`)
  }
}

export const downloadFile = (urlDownload) => {
  window.open(urlDownload)
}

export const getMenuItems = (menuItems, permissions) => {
  const isAdmin = permissions.includes(ROLE.ADMIN)

  return menuItems
    .map((item) => {
      if (item.section) {
        const filteredItems = item.items.filter((subItem) => isAdmin || permissions.includes(subItem.id))
        return filteredItems.length > 0
          ? {
              section: item.section,
              items: filteredItems,
            }
          : null
      }

      return isAdmin || permissions.includes(item.id) ? item : null
    })
    .filter((item) => item !== null)
}

export const addDays = (date, days) => {
  var result = new Date()
  result.setDate(date.getDate() + days)
  return result
}
