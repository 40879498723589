import React, { useState, useEffect } from 'react'
import SVG from 'react-inlinesvg'
import Product from '../../components/shared/Product'
import PageNumber from '../../components/shared/PageNumber'
import Promotions from '../home/components/Promotions'
import { PRODUCT_STATUS_ENUM } from '../../utilities/enum'
import { DEFAULT_VALUE } from '../../utilities/constant'
import { useProductService, usePromotionService } from '../../services/apiServices'
import { FormattedMessage } from 'react-intl'

const SupplierHome = ({ supplier }) => {
  const { getListAsync: getProducts } = useProductService()
  const { getCurrentListAsync: getPromotions } = usePromotionService()

  const [products, setProducts] = useState([])
  const [promotions, setPromotions] = useState([])
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize] = useState(25)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    fetchProduct()
    fetchPromotions()
  }, [])

  const fetchProduct = async () => {
    try {
      const { result } = await getProducts({
        pageIndex: currentPage,
        pageSize: pageSize,
        status: PRODUCT_STATUS_ENUM.APPROVED,
        supplierId: supplier.id,
      })

      if (result) {
        setProducts(result.items)
        setTotalPages(result.totalPageCount)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchPromotions = async () => {
    const { result } = await getPromotions()
    if (result) {
      const sortedPromotions = result.sort((a, b) => new Date(b.fromDate) - new Date(a.fromDate))
      const latestPromotions = sortedPromotions.slice(0, 4)

      setPromotions(latestPromotions)
    }
  }
  return (
    <div className='supplier-home'>
      <Promotions promotions={promotions} />
      <div className='my-4 mt-8 mb-xl-10'>
        <h1 className='fw-bolder mb-4 text-uppercase'>
          <FormattedMessage id='Product' />
        </h1>
        <div className='row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5'>
          <Product products={products} />
        </div>

        <div className='p-4'>
          <PageNumber currentPage={currentPage} totalPages={totalPages} className='pagination-lg' onPageChange={setCurrentPage} />
        </div>
      </div>
      {/* 
      <div className='my-4 mt-8 mb-xl-10'>
        <div className='d-flex justify-content-between'>
          <h1 className='fw-bolder mb-0 text-uppercase'>Mẫu bài đăng bán</h1>

          <button type='button' className='py-2 fw-bold px-3 btn btn-primary btn-sm'>
            <div className='position-relative g-0 row-cols-auto align-items-center justify-content-center w-100 flex-nowrap row'>
              <i className='fa fa-pencil me-2' aria-hidden='true'></i>
              Tạo mới
            </div>
          </button>
        </div>

        <div className='row mt-4'>
          <div className='col-6'>
            <div className='primary-sidebar mb-5'>
              <div className='sidebar-widget'>
                <div className='d-flex align-items-center justify-content-between'>
                  <div className='d-flex align-items-center'>
                    <div className='ratio me-2 bg-white rounded-circle overflow-hidden ratio-1x1' style={{ width: '48px', height: '48px' }}>
                      <img
                        src='https://media.sellycdn.net/files/md_avatar_author_social.png'
                        loading='lazy'
                        className='object-fit-cover w-100 h-100 rounded-circle'
                      />
                    </div>
                    <div>
                      <h5 className='mb-0 fw-bold fs-7 text-uppercase'>
                        Hướng dẫn bán hàng
                        <SVG className='ms-1' width={16} height={16} src={'/media/svg/icon/check-circle.svg'} />
                      </h5>
                      <div className='fs-7'>Trần Thị Huyền Trang </div>
                      <div className='fs-7 text-muted cursor-pointer'>11/04/2023 - 14:45</div>
                    </div>
                  </div>
                  <span className='cursor-pointer'>
                    <i className='fa fa-ellipsis-v fs-2' aria-hidden='true'></i>
                  </span>
                </div>

                <div className='list-group my-4'>
                  <div className='list-group-item border-0 p-0'>
                    <p className='fw-bold mb-1 text-break'>Áo khoác dù nam nữ</p>
                    <p className='text-wrap mb-2` overflow-hidden mb-2'>
                      <span style={{ whiteSpace: 'pre-line' }}>
                        ❤️ với chất liệu vải dù 2 lớp, lớp bên trong có vải lót dù giúp thoáng khí mát mẻ.
                        <br />
                        ❤️ Vải dày đẹp, không nhăn, thời trang.
                        <br />
                        ❤️ Đường may chắc chắn bền đẹp.
                        <br />
                        ❤️ Dây kéo (khóa) chắc chắn, không kẹt không hư hỏng.
                        <br />
                        ❤️ Có đủ size cho các bạn lựa chọn
                        <br />
                        Size S: 1m50 – 1m55, 40 – 50kg.
                        <br />
                        Size M: 1m55 – 1m60, 51 – 55kg.
                        <br />
                        Size L: 1m60 – 1m65, 56 – 64kg.
                        <br />
                        Size XL: 1m65 – 1m75, 65 – 70kg.
                        <br />
                        Size XXL: &gt;175cm, 71 – 85kg.
                        <br />
                        <br />
                        ❤️ Màu sắc đa dạng: Đen sọc trắng, trắng sọc đỏ, trắng sọc đen, đen sọc đỏ
                        <br />
                        Xuất xứ: Việt Nam
                        <br />
                        ❤️ Giá chỉ 100k. Nhanh tay tậu cho mình một cái để mặc đi chơi cùng bạn bè đi cả nhà ơi
                      </span>
                      <a className='ms-1 fw-normal text-gray-500 cursor-pointer'>Thu gọn</a>
                    </p>
                    <div className='grid-3-wrapper mx-n3'>
                      <div className='grid-3-container'>
                        <div className='item1'>
                          <div className='ratio ratio-1x1'>
                            <img
                              src='https://media.sellycdn.net/files/sm_2023_03_22_04_23_12_0700_TrULxJwfoW.jpeg'
                              loading='lazy'
                              className='object-fit-cover overflow-hidden cursor-pointer'
                            />
                          </div>
                        </div>
                        <div className='item2'>
                          <div className='ratio ratio-1x1'>
                            <img
                              src='https://media.sellycdn.net/files/sm_2023_03_22_04_23_12_0700_hhKWrkVgLt.jpeg'
                              loading='lazy'
                              className='object-fit-cover overflow-hidden cursor-pointer'
                            />
                          </div>
                        </div>
                        <div className='item3 position-relative'>
                          <div className='ratio ratio-1x1'>
                            <img
                              src='https://media.sellycdn.net/files/sm_2023_03_22_04_23_12_0700_xdzwQGQbff.jpeg'
                              loading='lazy'
                              className='object-fit-cover overflow-hidden cursor-pointer'
                            />
                          </div>
                          <div className='restMedia___2S3D7 position-absolute w-100 h-100 top-0 cursor-pointer show-rest-media'>
                            <span className='position-absolute top-50 start-50 translate-middle text-white fs-3'>+2</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='pt-4'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <div className='cursor-pointer'>
                          <i className='fa fa-heart me-1' aria-hidden='true'></i>
                          <span className='fs-7'>19</span>
                        </div>
                        <div className='cursor-pointer'>
                          <i className='fa fa-edit me-1' aria-hidden='true'></i>
                          <span className='fs-7'>Đăng bán</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-6'>
            <div className='primary-sidebar mb-5'>
              <div className='sidebar-widget'>
                <div className='d-flex align-items-center justify-content-between'>
                  <div className='d-flex align-items-center'>
                    <div className='ratio me-2 bg-white rounded-circle overflow-hidden ratio-1x1' style={{ width: '48px', height: '48px' }}>
                      <img
                        src='https://media.sellycdn.net/files/md_avatar_author_social.png'
                        loading='lazy'
                        className='object-fit-cover w-100 h-100 rounded-circle'
                      />
                    </div>
                    <div>
                      <h5 className='mb-0 fw-bold fs-7 text-uppercase'>
                        Hướng dẫn bán hàng
                        <SVG className='ms-1' width={16} height={16} src={'/media/svg/icon/check-circle.svg'} />
                      </h5>
                      <div className='fs-7'>Trần Thị Huyền Trang </div>
                      <div className='fs-7 text-muted cursor-pointer'>11/04/2023 - 14:45</div>
                    </div>
                  </div>
                  <span className='cursor-pointer'>
                    <i className='fa fa-ellipsis-v fs-2' aria-hidden='true'></i>
                  </span>
                </div>

                <div className='list-group my-4'>
                  <div className='list-group-item border-0 p-0'>
                    <p className='fw-bold mb-1 text-break'>Áo khoác dù nam nữ</p>
                    <p className='text-wrap mb-2` overflow-hidden mb-2'>
                      <span style={{ whiteSpace: 'pre-line' }}>
                        ❤️ với chất liệu vải dù 2 lớp, lớp bên trong có vải lót dù giúp thoáng khí mát mẻ.
                        <br />
                        ❤️ Vải dày đẹp, không nhăn, thời trang.
                        <br />
                        ❤️ Đường may chắc chắn bền đẹp.
                        <br />
                        ❤️ Dây kéo (khóa) chắc chắn, không kẹt không hư hỏng.
                        <br />
                        ❤️ Có đủ size cho các bạn lựa chọn
                        <br />
                        Size S: 1m50 – 1m55, 40 – 50kg.
                        <br />
                        Size M: 1m55 – 1m60, 51 – 55kg.
                        <br />
                        Size L: 1m60 – 1m65, 56 – 64kg.
                        <br />
                        Size XL: 1m65 – 1m75, 65 – 70kg.
                        <br />
                        Size XXL: &gt;175cm, 71 – 85kg.
                        <br />
                        <br />
                        ❤️ Màu sắc đa dạng: Đen sọc trắng, trắng sọc đỏ, trắng sọc đen, đen sọc đỏ
                        <br />
                        Xuất xứ: Việt Nam
                        <br />
                        ❤️ Giá chỉ 100k. Nhanh tay tậu cho mình một cái để mặc đi chơi cùng bạn bè đi cả nhà ơi
                      </span>
                      <a className='ms-1 fw-normal text-gray-500 cursor-pointer'>Thu gọn</a>
                    </p>
                    <div className='grid-3-wrapper mx-n3'>
                      <div className='grid-3-container'>
                        <div className='item1'>
                          <div className='ratio ratio-1x1'>
                            <img
                              src='https://media.sellycdn.net/files/sm_2023_03_22_04_23_12_0700_TrULxJwfoW.jpeg'
                              loading='lazy'
                              className='object-fit-cover overflow-hidden cursor-pointer'
                            />
                          </div>
                        </div>
                        <div className='item2'>
                          <div className='ratio ratio-1x1'>
                            <img
                              src='https://media.sellycdn.net/files/sm_2023_03_22_04_23_12_0700_hhKWrkVgLt.jpeg'
                              loading='lazy'
                              className='object-fit-cover overflow-hidden cursor-pointer'
                            />
                          </div>
                        </div>
                        <div className='item3 position-relative'>
                          <div className='ratio ratio-1x1'>
                            <img
                              src='https://media.sellycdn.net/files/sm_2023_03_22_04_23_12_0700_xdzwQGQbff.jpeg'
                              loading='lazy'
                              className='object-fit-cover overflow-hidden cursor-pointer'
                            />
                          </div>
                          <div className='restMedia___2S3D7 position-absolute w-100 h-100 top-0 cursor-pointer show-rest-media'>
                            <span className='position-absolute top-50 start-50 translate-middle text-white fs-3'>+2</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='pt-4'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <div className='cursor-pointer'>
                          <i className='fa fa-heart me-1' aria-hidden='true'></i>
                          <span className='fs-7'>19</span>
                        </div>
                        <div className='cursor-pointer'>
                          <i className='fa fa-edit me-1' aria-hidden='true'></i>
                          <span className='fs-7'>Đăng bán</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       */}
    </div>
  )
}

export default SupplierHome
