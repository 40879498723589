import { useIntl, FormattedMessage } from 'react-intl'
import { useState } from 'react'
import { VOUCHER_DISCOUNT_TYPE_ENUM, VOUCHER_TYPE_ENUM } from '../../utilities/enum'
import { DEFAULT_VALUE } from '../../utilities/constant'
import NumberFormat from '../../components/shared/NumberFormat'
import Button from '../../components/shared/Button'
import Modal from '../../components/shared/Modal'

const SystemVoucherModal = ({ closeModal, voucherSelected, coupons, onChangeVoucher, couponType }) => {
  const intl = useIntl()
  const [voucher, setVoucher] = useState(voucherSelected)
  const title =
    couponType === VOUCHER_TYPE_ENUM.VOUCHER_SHIPPING
      ? 'Coupon.FreeShip'
      : couponType === VOUCHER_TYPE_ENUM.VOUCHER_SUPPLIER
      ? 'Checkout.ShopVoucher'
      : 'Checkout.GreenVoucher'

  const handleSave = () => {
    onChangeVoucher(voucher)
    closeModal(false)
  }

  const calculatePercentage = (usedNumber, maxUsesNumber) => {
    if (maxUsesNumber <= 0) return 0
    return ((usedNumber ?? 0) / maxUsesNumber) * 100
  }
  return (
    <Modal title={intl.formatMessage({ id: title })} modalSize='mw-650px' closeModal={closeModal} paddingLess={true}>
      <div className='shipping-unit d-flex justify-content-between flex-column min-h-250px'>
        <div className='cursor-pointer'>
          {coupons?.length > 0 &&
            coupons.map((d, index) => (
              <div key={d + index} className={`shipping-item ${voucher?.id === d.id ? 'active' : ''}`} onClick={() => setVoucher(d)}>
                <div>
                  <div className='shipping-type'>
                    {d.discountType === VOUCHER_DISCOUNT_TYPE_ENUM.FIXED_PRICE ? (
                      <FormattedMessage
                        id='Coupon.MaxDiscountAmount'
                        values={{ maxDiscountAmount: <NumberFormat amount={d.maxDiscountAmount} className='shipping-type' suffix={DEFAULT_VALUE.VND} /> }}
                      />
                    ) : d.discountType === VOUCHER_DISCOUNT_TYPE_ENUM.PERCENTAGE ? (
                      <>
                        <FormattedMessage id='Coupon.DiscountPercent' values={{ discountValue: d.discountValue }} />{' '}
                        <FormattedMessage
                          id='Coupon.MaxDiscountAmount'
                          values={{ maxDiscountAmount: <NumberFormat amount={d.maxDiscountAmount} className='shipping-type' suffix={DEFAULT_VALUE.VND} /> }}
                        />
                      </>
                    ) : (
                      <>
                        <FormattedMessage
                          id='Coupon.MaxDiscountAmount'
                          values={{ maxDiscountAmount: <NumberFormat amount={d.maxDiscountAmount} className='shipping-type' suffix={DEFAULT_VALUE.VND} /> }}
                        />
                      </>
                    )}
                    <br />
                    <FormattedMessage
                      id='Coupon.MinAmount'
                      values={{ minAmount: <NumberFormat amount={d.minAmount ?? 0} className='shipping-type' suffix={DEFAULT_VALUE.VND} /> }}
                    />
                  </div>
                  <div className='shipping-time gray-color'>
                    {d.maxUsesNumber && (
                      <FormattedMessage id='Coupon.UsedNumber' values={{ usedNumber: calculatePercentage(d.couponUsedNumber, d.maxUsesNumber) }} />
                    )}{' '}
                    {d.toDate && <FormattedMessage id='Coupon.ToDate' values={{ toDate: d.toDateStr }} />}
                  </div>
                </div>
              </div>
            ))}
        </div>

        <div className='text-center py-4'>
          <Button
            onClick={() => closeModal(false)}
            title={intl.formatMessage({ id: 'Close' })}
            className={'btn btn-light me-3'}
            tabIndex={4}
            icon='CloseOutlined'
          />
          <Button onClick={() => handleSave()} title={intl.formatMessage({ id: 'Complete' })} className={'btn btn-primary'} tabIndex={5} icon='SaveOutlined' />
        </div>
      </div>
    </Modal>
  )
}

export default SystemVoucherModal
