import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import '../../../assets/sass/shoppingCart.scss'
import { DEFAULT_VALUE } from '../../../utilities/constant'
import { useShopingCart } from '../../contexts/ShoppingCartContext'
import NumberFormat from '../../shared/NumberFormat'

const ShoppingCart = () => {
  const intl = useIntl()
  const { shoppingCart, removeFromCart } = useShopingCart()

  return (
    <>
      <div className='d-flex cursor-pointer hover-text'>
        <span className='header-action-content'>
          <img src={'/media/svg/icons/icon-cart.svg'} alt='' />
          <span className='pro-count blue'>{shoppingCart?.shoppingCartDetails?.length ?? 0}</span>
        </span>
        <span className='header-action-content d-none d-lg-flex align-items-center'>
          <span className='ms-2 label d-none d-xl-flex'>{intl.formatMessage({ id: 'ShoppingCart' })}</span>
        </span>
      </div>

      <div className='cart-dropdown-wrap cart-dropdown-items'>
        <ul className='d-display pe-3'>
          {shoppingCart?.shoppingCartDetails?.map((item) => {
            return (
              <li key={item.id}>
                <Link to={`/product/${item.productId}`} className=''>
                  <div className='shopping-cart-img'>
                    <img src={item?.fileThumbnailUrl} alt='' />
                  </div>
                  <div className='shopping-cart-title'>
                    <h4 className='hover-text'>{item.productName}</h4>
                    <h4>
                      <span>{item.productPropertyName}</span>
                    </h4>
                    <h4>
                      <span className='shopping-cart-quantity'>{item.quantity} ×</span>
                      <NumberFormat className={'shopping-cart-price ps-1'} amount={item.sellingPrice - item.discountAmount} suffix={DEFAULT_VALUE.VND} />
                    </h4>
                  </div>
                </Link>
                <div className='shopping-cart-delete cursor-pointer' onClick={() => removeFromCart(item.id)}>
                  <i className='fa-sharp fa-solid fa-xmark' />
                </div>
              </li>
            )
          })}
        </ul>
        {shoppingCart?.shoppingCartDetails?.length > 0 ? (
          <div className='shopping-cart-footer'>
            <div className='shopping-cart-total'>
              <h4>
                {intl.formatMessage({ id: 'TotalAmount' })} <NumberFormat amount={shoppingCart?.totalAmount} suffix={DEFAULT_VALUE.VND} />
              </h4>
            </div>
            <div className='shopping-cart-button'>
              <a className='outline' href={`/shopping-cart`}>
                {intl.formatMessage({ id: 'ViewCart' })}
              </a>
              <a href={`/checkout`}> {intl.formatMessage({ id: 'Checkout' })}</a>
            </div>
          </div>
        ) : (
          <div className='text-center'>{intl.formatMessage({ id: 'NoResult' })}</div>
        )}
      </div>
    </>
  )
}

export default ShoppingCart
