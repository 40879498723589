import React, { createContext, useContext, useState, useEffect } from 'react'
import { useSupplierService, useProductService, useOrderService } from '../../../services/apiServices'

const AccountHeaderContext = createContext()

export const useAccountHeaderContext = () => useContext(AccountHeaderContext)

export const AccountHeaderProvider = ({ children }) => {
  const [reloadWithdrawalHistories, setReloadWithdrawalHistories] = useState(false)

  return (
    <AccountHeaderContext.Provider
      value={{
        reloadWithdrawalHistories,
        setReloadWithdrawalHistories,
      }}
    >
      {children}
    </AccountHeaderContext.Provider>
  )
}
