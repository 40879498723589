import React, { useState, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { PRODUCT_SORT_ENUM } from '../../../utilities/enum'
import { CONTROL_TYPE, FormControl } from '../../../components/shared/FormControl'

const SortBar = ({ currentPage, totalPages, onPageChange, priceSort, featuredSort, onPriceSort, onFeaturedSort }) => {
  const componentRefs = useRef({})
  const intl = useIntl()

  const [sortPrices] = useState(
    Object.keys(PRODUCT_SORT_ENUM)
      .filter((key) => PRODUCT_SORT_ENUM[key] === PRODUCT_SORT_ENUM.PRICE_ASCENDING || PRODUCT_SORT_ENUM[key] === PRODUCT_SORT_ENUM.PRICE_DESCENDING)
      .map((key) => ({
        value: PRODUCT_SORT_ENUM[key],
        label: intl.formatMessage({ id: `SortProduct.${key}` }),
      }))
  )

  return (
    <div className='sort-bar'>
      <span>
        <FormattedMessage id='SortBy' />
      </span>
      <div className='sort-by-options'>
        <div className='option-group'>
          <button
            aria-pressed='true'
            onClick={() => onFeaturedSort(PRODUCT_SORT_ENUM.TRENDING_PRODUCT)}
            className={`option ${featuredSort === PRODUCT_SORT_ENUM.TRENDING_PRODUCT ? 'selected' : ''}`}
          >
            <span aria-hidden='true'>
              <FormattedMessage id='SortProduct.TRENDING_PRODUCT' />
            </span>
          </button>
          <button
            aria-pressed='false'
            onClick={() => onFeaturedSort(PRODUCT_SORT_ENUM.RECENTLY_ADDED)}
            className={`option ${featuredSort === PRODUCT_SORT_ENUM.RECENTLY_ADDED ? 'selected' : ''}`}
          >
            <span aria-hidden='true'>
              <FormattedMessage id='SortProduct.RECENTLY_ADDED' />
            </span>
          </button>
          <button
            aria-pressed='false'
            onClick={() => onFeaturedSort(PRODUCT_SORT_ENUM.TOP_SELLING)}
            className={`option ${featuredSort === PRODUCT_SORT_ENUM.TOP_SELLING ? 'selected' : ''}`}
          >
            <span aria-hidden='true'>
              <FormattedMessage id='SortProduct.TOP_SELLING' />
            </span>
          </button>
        </div>

        <div>
          <FormControl
            ref={(ref) => (componentRefs.current.priceSort = ref)}
            controlType={CONTROL_TYPE.SELECT}
            value={priceSort}
            onChange={onPriceSort}
            options={sortPrices}
            isHideLabel={true}
            isClearable={false}
          />
        </div>
      </div>

      <div className='mini-page-controller'>
        <div className='state'>
          <span className='current'>{currentPage}</span>/<span className='total'>{totalPages}</span>
        </div>
        <a
          onClick={() => onPageChange(currentPage - 1)}
          className={`prev-btn btn button-outline ms-5 ${currentPage > 1 && currentPage <= totalPages ? '' : 'disabled'}`}
        >
          <i className='fa fa-angle-left' aria-hidden='true'></i>
        </a>

        <a onClick={() => onPageChange(currentPage + 1)} className={`next-btn btn button-outline ${currentPage < totalPages ? '' : 'disabled'}`}>
          <i className='fa fa-angle-right' aria-hidden='true'></i>
        </a>
      </div>
    </div>
  )
}
export default SortBar
