import { useState, forwardRef, useImperativeHandle, useRef } from 'react'
import _ from 'lodash'
import { formatString, isValidEmail, isValidPassword } from '../../utilities/sharedFunction'
import bindValidationMessages from '../../utilities/validationMessage'
import Input from './Input'
import Label from './Label'
import Select from './Select'
import TextArea from './TextArea'
import DatePicker from './DatePicker'
import FileUpload from './FileUpload'
import NumberBox from './NumberBox'
import Tags from './Tags'
import MultiSelect from './MultiSelect'
import Dropdown from './Dropdown'
import Checkbox from './Checkbox'
import PhoneNumber from './PhoneNumber'
import CkEditor from './CKEditor'

const CONTROL_TYPE = {
  INPUT: 'Input',
  SELECT: 'Select',
  TEXTAREA: 'TextArea',
  DATEPICKER: 'DatePicker',
  FILEUPLOAD: 'FileUpload',
  NUMBERBOX: 'NumberBox',
  TAGS: 'Tags',
  MULTISELECT: 'MultiSelect',
  DROPDOWN: 'Dropdown',
  CHECKBOX: 'Checkbox',
  PHONENUMBER: 'PhoneNumber',
  EDITOR: 'Editor'
}

const validationAllComponents = async (componentRefs) => {
  const validations = await Promise.all(
    _.map(componentRefs.current, async (ref) => {
      if (!ref) return false
      return await ref.validateData()
    })
  )

  return _.every(validations)
}

const FormControl = forwardRef((props, ref) => {
  const { value, label, controlType, isHideLabel = false } = props
  const [isValid, setIsValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const validationMessage = bindValidationMessages()
  const controlRef = useRef(null)

  const labelProps = {
    title: props.label,
    isRequired: props.isRequired,
    contentTooltip: props.contentTooltip,
  }

  const validateProps = {
    validator: props.validator,
    isRequired: props.isRequired,
    isEmail: props.isEmail,
    isPassword: props.isPassword,
  }

  useImperativeHandle(ref, () => ({
    validateData,
    triggerClick: () => {
      controlRef.current.click()
    },
    clearValue: () => {
      if (controlRef.current.clearValue) controlRef.current.clearValue()
    },
  }))

  const validateData = async () => {
    let error = false
    let message = []
    let hasValue = !_.isNil(value)

    if (_.isString(value)) {
      hasValue = hasValue && !_.isEmpty(_.trim(value))
    } else if (_.isNumber(value)) {
      hasValue = hasValue && !_.isNaN(value)
    } else if (_.isArray(value)) {
      hasValue = hasValue && !_.isEmpty(value)
    } else if (_.isObject(value)) {
      hasValue = hasValue && !_.isEmpty(value)
    }

    //Check required
    if (validateProps.isRequired && !hasValue) {
      error = true
      message = [...message, formatString(validationMessage.required, label)]
    } else if (hasValue) {
      //Check email
      if (validateProps.isEmail) {
        if (!isValidEmail(value)) {
          error = true
          message = [...message, formatString(validationMessage.invalid, label)]
        }
      }
      //check Password
      if (validateProps.isPassword) {
        if (!isValidPassword(value)) {
          error = true
          message = [...message, formatString(validationMessage.invalid, label)]
        }
      }
      //check other
      if (_.isFunction(validateProps.validator)) {
        const result = await validateProps.validator()
        if (result?.error) {
          error = true
          message = [...message, formatString(result?.message, label)]
        }
      }
    }
    setIsValid(!error)
    setErrorMessage(message.join('\n'))
    return !error
  }

  const renderControl = () => {
    switch (controlType) {
      case CONTROL_TYPE.INPUT:
        const inputProps = {
          ref: controlRef,
          placeholder: props.placeholder ?? label,
          value: props.value,
          readOnly: props.readOnly,
          tabIndex: props.tabIndex,
          className: props.className,
          size: props.size,
          hideText: props.hideText,
          onChange: props.onChange,
          onBlur: validateData,
          isValid,
          errorMessage,
        }
        return <Input {...inputProps} />

      case CONTROL_TYPE.TEXTAREA:
        const textAreaProps = {
          ref: controlRef,
          placeholder: props.placeholder ?? label,
          value: props.value,
          readOnly: props.readOnly,
          tabIndex: props.tabIndex,
          className: props.className,
          rows: props.rows,
          onChange: props.onChange,
          onBlur: validateData,
          isValid,
          errorMessage,
        }
        return <TextArea {...textAreaProps} />

      case CONTROL_TYPE.NUMBERBOX:
        const numberBoxProps = {
          ref: controlRef,
          placeholder: props.placeholder ?? label,
          value: props.value,
          readOnly: props.readOnly,
          tabIndex: props.tabIndex,
          className: props.className,
          prefix: props.prefix,
          suffix: props.suffix,
          min: props.min,
          max: props.max,
          onChange: props.onChange,
          onBlur: validateData,
          isValid,
          errorMessage,
        }
        return <NumberBox {...numberBoxProps} />

      case CONTROL_TYPE.SELECT:
        const selectProps = {
          ref: controlRef,
          value: props.value,
          readOnly: props.readOnly,
          tabIndex: props.tabIndex,
          className: props.className,
          options: props.options,
          onChange: props.onChange,
          onBlur: validateData,
          isValid,
          errorMessage,
          placeholder: props.placeholder ?? label,
          isClearable: props.isClearable
        }
        return <Select {...selectProps} />

      case CONTROL_TYPE.MULTISELECT:
        const mutiSelectProps = {
          ref: controlRef,
          value: props.value,
          readOnly: props.readOnly,
          tabIndex: props.tabIndex,
          className: props.className,
          options: props.options,
          onChange: props.onChange,
          onBlur: validateData,
          isValid,
          errorMessage,
        }
        return <MultiSelect {...mutiSelectProps} />

      case CONTROL_TYPE.TAGS:
        const tagsProps = {
          ref: controlRef,
          value: props.value,
          readOnly: props.readOnly,
          tabIndex: props.tabIndex,
          className: props.className,
          options: props.options,
          onChange: props.onChange,
          onBlur: validateData,
          isValid,
          errorMessage,
        }
        return <Tags {...tagsProps} />

      case CONTROL_TYPE.DROPDOWN:
        const dropdownProps = {
          ref: controlRef,
          value: props.value,
          readOnly: props.readOnly,
          tabIndex: props.tabIndex,
          options: props.options,
          onChange: props.onChange,
          onBlur: validateData,
          isValid,
          errorMessage,
        }
        return <Dropdown {...dropdownProps} />

      case CONTROL_TYPE.DATEPICKER:
        const datePickerProps = {
          ref: controlRef,
          value: props.value,
          readOnly: props.readOnly,
          tabIndex: props.tabIndex,
          className: props.className,
          hasTime: props.hasTime,
          onChange: props.onChange,
          onBlur: validateData,
          isValid,
          errorMessage,
          placeholder: props.placeholder ?? label,
        }
        return <DatePicker {...datePickerProps} />

      case CONTROL_TYPE.FILEUPLOAD:
        const fileUploadProps = {
          ref: controlRef,
          value: props.value,
          tabIndex: props.tabIndex,
          className: props.className,
          accept: props.accept,
          isMultiple: props.isMultiple,
          isAvatar: props.isAvatar,
          onChange: props.onChange,
          isValid,
          errorMessage,
        }
        return <FileUpload {...fileUploadProps} />

      case CONTROL_TYPE.CHECKBOX:
        const checkboxProps = {
          ref: controlRef,
          value: props.value,
          placeholder: props.placeholder ?? label,
          isFromSwitch: props.isFromSwitch,
          readOnly: props.readOnly,
          tabIndex: props.tabIndex,
          className: props.className,
          hasTime: props.hasTime,
          onChange: props.onChange,
          onBlur: validateData,
          isValid,
          errorMessage,
        }
        return <Checkbox {...checkboxProps} />

      case CONTROL_TYPE.PHONENUMBER:
        const phoneNumberProps = {
          ref: controlRef,
          placeholder: props.placeholder ?? label,
          value: props.value,
          readOnly: props.readOnly,
          tabIndex: props.tabIndex,
          className: props.className,
          onChange: props.onChange,
          onBlur: validateData,
          isValid,
          errorMessage,
        }
        return <PhoneNumber {...phoneNumberProps} />

        case CONTROL_TYPE.EDITOR:
          const editorProps = {
            ref: controlRef,
            value: props.value,
            readOnly: props.readOnly,
            tabIndex: props.tabIndex,
            onChange: props.onChange,
            onBlur: validateData,
            isValid,
            errorMessage,
          }
          return <CkEditor {...editorProps} />

      default:
        return null
    }
  }

  return (
    <>
      {controlType !== CONTROL_TYPE.CHECKBOX && !isHideLabel && <Label {...labelProps} />}
      {renderControl()}
    </>
  )
})

export { FormControl, CONTROL_TYPE, validationAllComponents }
