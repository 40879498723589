import React, { useState, useEffect } from 'react'
import CustomeCheckbox from '../../../components/shared/CustomeCheckbox'
import { FormattedMessage } from 'react-intl'
import { useSubCategoryService } from '../../../services/apiServices'

const CategoryFilter = ({ categoryId, categories, handleChangeCategory, handleChangeSubCategory }) => {
  const { getCurrentListAsync: getSubCategories } = useSubCategoryService()

  const [subCategories, setSubCategories] = useState([])

  useEffect(() => {
    fetchSubCategories()
  }, [categoryId])

  const fetchSubCategories = async () => {
    if (categoryId !== '0') {
      const { result } = await getSubCategories(categoryId)
      if (result) {
        setSubCategories(result)
      }
    }
  }

  return (
    <div className='primary-sidebar'>
      <div className='sidebar-widget'>
        <h5 className='section-title style-1 mb-4'>
          <FormattedMessage id='AccordingToCategory' />
        </h5>

        <div className='list-group my-4'>
          <div className='list-group-item border-0 p-0'>
            {(categoryId ?? '0') === '0'
              ? categories?.map((item) => {
                  return (
                    <CustomeCheckbox
                      key={item.id}
                      className='p-4 my-2 border rounded hover'
                      id={`sub-${item.id}`}
                      name='filter-subcategory'
                      text={item.name}
                      value={false}
                      onChange={(e) => handleChangeCategory(e.target.checked, item.id)}
                    />
                  )
                })
              : subCategories?.map((item) => {
                  return (
                    <CustomeCheckbox
                      key={item.id}
                      className='p-4 my-2 border rounded hover'
                      id={`sub-${item.id}`}
                      name='filter-subcategory'
                      text={item.name}
                      value={false}
                      onChange={(e) => handleChangeSubCategory(e.target.checked, item.id)}
                    />
                  )
                })}
          </div>
        </div>
      </div>
    </div>
  )
}
export default CategoryFilter
