import _ from 'lodash'
import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { showToast, TOAST_TYPES } from '../../components/shared/Toast'
import NumberFormat from '../../components/shared/NumberFormat'
import { DEFAULT_VALUE } from '../../utilities/constant'
import { useOrderService, usePaymentService } from '../../services/apiServices'
import { useNavigate } from 'react-router-dom'
import { ORDER_STATUS_ENUM, PaymentMethodEnum } from '../../utilities/enum'
import { useShopingCart } from '../../components/contexts/ShoppingCartContext'
import Button from '../../components/shared/Button'

const CheckoutTotal = ({ orders, customer, address }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { insertAsync } = useOrderService()
  const { vnPay } = usePaymentService()

  const { removeFromCart } = useShopingCart()

  const totalAmount = orders.reduce((acc, current) => acc + current.totalAmount, 0)
  const shippingCost = orders.reduce((acc, current) => acc + current.shippingCost, 0)
  const shippingDiscount = orders.reduce((acc, current) => acc + current.shippingDiscount, 0)
  const supplierDiscount = orders.reduce((acc, current) => acc + current.supplierDiscount, 0)
  const appDiscount = orders.length > 0 ? orders[0].appDiscount : 0
  const paymentAmount = totalAmount + shippingCost - (shippingDiscount + supplierDiscount + appDiscount)

  const handleSaveOrder = async () => {
    if (orders?.length === 0) return

    const paymentMethod = orders[0].paymentMethod

    if (_.isEmpty(customer) || _.isEmpty(address)) {
      showToast(`${intl.formatMessage({ id: 'Checkout.RequireChoseCustomer' })}`, TOAST_TYPES.ERROR)
      return
    }

    const { error, result } = await insertAsync(orders)
    if (_.isEmpty(error) && result) {
      orders.map((x) => x.details.map((d) => removeFromCart(d.id)))
      if (paymentMethod === PaymentMethodEnum.VNPay) {
        const { result: payment } = await vnPay(result.map((x) => x.id))
        window.open(payment.paymentUrl, '_self')
      } else {
        navigate(`/purchase/${ORDER_STATUS_ENUM.WAITFORCONFIRM}`)
      }
    } else {
      showToast(`${intl.formatMessage({ id: 'Notify.SaveError' })}`, TOAST_TYPES.ERROR)
      console.log(error)
    }
  }

  return (
    <div className='checkout-table-payment'>
      <h3 className='checkout-text-center checkout-text-gray'>{intl.formatMessage({ id: 'TotalAmount' })}</h3>
      <NumberFormat amount={totalAmount} suffix={DEFAULT_VALUE.VND} className='checkout-text-center checkout-text-flex-end' />

      <h3 className='checkout-text-center checkout-text-gray checkout-payment-ship'>{intl.formatMessage({ id: 'Checkout.ShippingFee' })}</h3>
      <NumberFormat amount={shippingCost} className='checkout-text-center checkout-text-flex-end checkout-payment-ship' suffix={DEFAULT_VALUE.VND} />

      {shippingDiscount > 0 && (
        <>
          <h3 className='checkout-text-center checkout-text-gray checkout-discount-payment-ship'>{intl.formatMessage({ id: 'Checkout.DiscountShipping' })}</h3>
          <NumberFormat
            amount={shippingDiscount}
            className='checkout-text-center checkout-text-flex-end checkout-discount-payment-ship'
            suffix={DEFAULT_VALUE.VND}
          />
        </>
      )}

      {(appDiscount > 0 || supplierDiscount > 0) && (
        <>
          <h3 className='checkout-text-center checkout-text-gray checkout-discount-payment'>{intl.formatMessage({ id: 'Checkout.TotalDiscountVoucher' })}</h3>
          <NumberFormat
            amount={supplierDiscount + appDiscount}
            className='checkout-text-center checkout-text-flex-end checkout-discount-payment'
            suffix={DEFAULT_VALUE.VND}
          />
        </>
      )}

      <h3 className='checkout-text-center checkout-text-gray checkout-payment-all'>{intl.formatMessage({ id: 'Checkout.TotalPayment' })}</h3>
      <NumberFormat
        amount={paymentAmount}
        className='checkout-text-center checkout-payment-highlight checkout-text-flex-end checkout-payment-all'
        suffix={DEFAULT_VALUE.VND}
      />
      <div className='checkout-payment-purchase'>
        <Link className='btn me-2' to='/'>
          <i className='pe-2 fa-solid fa-cart-shopping color-white' />
          {intl.formatMessage({ id: 'Checkout.ContinueShopping' })}
        </Link>

        <div className='checkout-payment-agree'>
          <div className='checkout-payment-agree-text'>
            {intl.formatMessage({ id: 'Click' })} <span className='text-brand'>{intl.formatMessage({ id: 'Checkout' })}</span>{' '}
            {intl.formatMessage({ id: 'Checkout.MeanYouAgree' })}{' '}
            <a className='text-brand'>
              {intl.formatMessage({ id: 'Checkout.Terms' })} {intl.formatMessage({ id: 'Green24h' })}
            </a>
          </div>
        </div>

        <Button onClick={handleSaveOrder} icon='CheckOutlined' title={intl.formatMessage({ id: 'Checkout' })} className={'btn btn-primary'} />
      </div>
    </div>
  )
}

export default CheckoutTotal
