import React from 'react'
import { useIntl } from 'react-intl'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { purchaseRoutes } from '.'
import '../../assets/sass/order.scss'
import '../../assets/sass/purchase.scss'
import { checkIsActive } from '../../utilities/sharedFunction'
import Breadcrumb from '../../components/layout/components/breadcrumb/Breadcrumb'

const Purchases = () => {
  const intl = useIntl()
  const location = useLocation()

  return (
    <div className='purchases-list'>
      <div className='header-page'>
        <div className='title'>
          <h1 className='mb-4'>{intl.formatMessage({ id: 'OrderTracking' })}</h1>
          <Breadcrumb />
        </div>
      </div>
      <div className='purchase-root d-fex flex-column border py-0 mt-4 shadow-sm'>
        <div className='purchase-right-nav'>
          <div className='purchase-right-wrap'>
            <section className='purchase-tablist'>
              {purchaseRoutes.map((d, index) => {
                return (
                  <Link
                    key={d.title + '_' + index}
                    to={`/purchase/` + d.type}
                    className={`purchase-col-all text-active-primary ${
                      checkIsActive(location.pathname, `/purchase/` + d.type) ? 'purchase-border-primary active' : ''
                    }`}
                  >
                    <span className='purchase-text-all'>{intl.formatMessage({ id: d.title })}</span>
                  </Link>
                )
              })}
            </section>
          </div>
        </div>

        {/* <section>
          <div className='purchase-search-wrap m-0'>
            <img src={'/media/png/icons/search.png'} alt='' className='ms-4' />
            <input placeholder={intl.formatMessage({ id: 'Purchase.SearchTitle' })} />
          </div>
        </section> */}

        <main>
          <Outlet />
        </main>
      </div>
    </div>
  )
}

export default Purchases
