import React, { useState, useEffect } from 'react'
import { Route, Routes, useParams, Navigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import ProductDetail from './ProductDetail'
import { PageTitle } from '../../components/layout/components/breadcrumb/PageData'
import { useProductService } from '../../services/apiServices'

const ProductDetailPage = () => {
  const intl = useIntl()
  const { productId } = useParams()
  const { getByIdAsync } = useProductService()

  const [product, setProduct] = useState({})

  useEffect(() => {
    fetchProduct()

  }, [productId])

  const fetchProduct = async () => {
    const { result } = await getByIdAsync(productId)
    if (result) {
      setProduct(result)
    }
  }

  const loadBreadcrumbs = () => {
    let item = [{ title: intl.formatMessage({ id: 'Home' }), path: '/home' }]
    const category = {
      categoryId: product?.categoryId,
      categoryName: product?.categoryName,
    }

    const subCategory = {
      subCategoryId: product?.subCategoryId,
      subCategoryName: product?.subCategoryName,
    }

    if (!_.isEmpty(category)) {
      item.push({ title: category.categoryName, path: `/categories/${category.categoryId}` })
    }
    if (!_.isEmpty(subCategory)) {
      item.push({ title: subCategory.subCategoryName, path: `/categories/${category.categoryId}` })
    }
    return item
  }

  return (
    <Routes>
      <Route path='*' element={<Navigate to='/' />} />
      <Route
        path='/'
        element={
          <>
            <PageTitle breadcrumbs={loadBreadcrumbs} isHideTitle={true}>
              {product?.name}
            </PageTitle>
            <ProductDetail product={product}/>
          </>
        }
      />
    </Routes>
  )
}

export default ProductDetailPage
