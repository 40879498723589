import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../components/layout/components/breadcrumb/PageData'
import ListSupplier from './ListSupplier'

const ListSupplierPage = () => {
  const intl = useIntl()

  const loadBreadcrumbs = () => {
    let item = [{ title: intl.formatMessage({ id: 'Home' }), path: '/home' }]
    return item
  }

  return (
    <Routes>
      <Route path='*' element={<Navigate to='/' />} />
      <Route
        path='/'
        element={
          <>
            <PageTitle breadcrumbs={loadBreadcrumbs} isHideTitle={true}>
              {intl.formatMessage({ id: 'Supplier' })}
            </PageTitle>
            <ListSupplier />
          </>
        }
      />
    </Routes>
  )
}

export default ListSupplierPage
