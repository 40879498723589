import { Pagination } from 'react-bootstrap'

const PageNumber = ({ currentPage, totalPages, onPageChange, className }) => {
  const pageItems = []
  const maxPagesToShow = 5

  let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2))
  let endPage = Math.min(totalPages, startPage + maxPagesToShow - 1)

  if (totalPages > maxPagesToShow) {
    if (endPage === totalPages) {
      startPage = totalPages - maxPagesToShow + 1
    } else if (startPage === 1) {
      endPage = maxPagesToShow
    }
  }
  
  pageItems.push(<Pagination.Prev key='prev' onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} />)

  if (startPage > 1) {
    pageItems.push(<Pagination.Ellipsis key='startEllipsis' />)
  }

  for (let page = startPage; page <= endPage; page++) {
    pageItems.push(
      <Pagination.Item key={page} active={page === currentPage} onClick={() => onPageChange(page)}>
        {page}
      </Pagination.Item>
    )
  }

  if (endPage < totalPages) {
    pageItems.push(<Pagination.Ellipsis key='endEllipsis' />)
  }

  pageItems.push(<Pagination.Next key='next' onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} />)

  return (
    <Pagination className={className}>{pageItems}</Pagination>
  )
}

export default PageNumber
