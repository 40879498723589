import React from 'react'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { SignalRProvider } from './components/contexts/SignalRContext'
import InitLayout from './components/layout/InitLayout'
import { Toastify } from './components/shared/Toast'
import { LoadingProvider } from './components/contexts/LoadingContext'
import LoadingSpinner from './components/shared/LoadingSpinner'
import { Spin } from 'antd'
import ShoppingCart from './components/layout/components/ShoppingCart'
import { ShopingCartContextProvider } from './components/contexts/ShoppingCartContext'

const App = () => {
  return (
    <Suspense
      fallback={
        <div className='loading-overlay d-flex justify-content-center align-items-center'>
          <Spin size='large' />
        </div>
      }
    >
      <InitLayout />
      <Toastify />
      <SignalRProvider>
        <LoadingProvider>
          <LoadingSpinner />
          <ShopingCartContextProvider>
            <Outlet />
          </ShopingCartContextProvider>
        </LoadingProvider>
      </SignalRProvider>
    </Suspense>
  )
}

export default App
