import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { DEFAULT_VALUE } from '../../utilities/constant'
import PageNumber from '../../components/shared/PageNumber'
import { PRODUCT_SORT_ENUM, PRODUCT_STATUS_ENUM } from '../../utilities/enum'
import CategoryFilter from './components/CategoryFilter'
import PriceFilter from './components/PriceFilter'
import SortBar from './components/SortBar'
import NewsBanner from './components/NewsBanner'
import RatingFilter from './components/RatingFilter'
import Product from '../../components/shared/Product'
import Breadcrumb from '../../components/layout/components/breadcrumb/Breadcrumb'
import { useProductService, useCategoryService } from '../../services/apiServices'

import '../../assets/sass/category.scss'

const Search = () => {
  const intl = useIntl()
  const { getCurrentListAsync: getCategories } = useCategoryService()
  const { getListAsync: getProducts } = useProductService()

  const { categoryId, searchValue } = useParams()
  const [categories, setCategories] = useState([])
  const [products, setProducts] = useState([])
  const [currentPage, setCurrentPage] = useState(DEFAULT_VALUE.PAGE_INDEX)
  const [pageSize] = useState(20)
  const [totalPages, setTotalPages] = useState(0)

  const [categoiesSearch, setCategoriesSearch] = useState([])
  const [subCategoriesSearch, setSubCategoriesSearch] = useState([])
  const [groupsSearch, setGroupsSearch] = useState([])
  const [ratingSearch, setRatingSearch] = useState(null)
  const [fromPriceSearch, setFromPriceSearch] = useState(0)
  const [toPriceSearch, setToPriceSearch] = useState(null)
  const [priceSort, setPriceSort] = useState(PRODUCT_SORT_ENUM.PRICE_ASCENDING)
  const [featuredSort, setFeaturedSort] = useState(null)

  useEffect(() => {
    fetchCategories()
    fetchProduct()
  }, [searchValue, categoryId])

  useEffect(() => {
    fetchProduct()
  }, [currentPage, categoiesSearch, subCategoriesSearch, ratingSearch, fromPriceSearch, toPriceSearch, priceSort, featuredSort])

  const handleChangeCategory = (checked, id) => {
    var currentCategories = [...categoiesSearch]

    if (checked) {
      currentCategories.push(id)
    } else {
      currentCategories = currentCategories.filter((x) => x !== id)
    }

    setCategoriesSearch(currentCategories)
  }

  const handleChangePrice = (formPrice, toPrice) => {
    setFromPriceSearch(formPrice)
    setToPriceSearch(toPrice)
  }

  const handleChangeSubCategory = (checked, id) => {
    var currentSubCategories = [...subCategoriesSearch]

    if (checked) {
      currentSubCategories.push(id)
    } else {
      currentSubCategories = currentSubCategories.filter((x) => x !== id)
    }

    setSubCategoriesSearch(currentSubCategories)
  }

  const fetchCategories = async () => {
    const { result } = await getCategories(categoryId === '0' ? null : categoryId)
    if (result) {
      setCategories(result)
    }
  }

  const fetchProduct = async () => {
    try {
      const { result } = await getProducts({
        pageIndex: currentPage,
        pageSize: pageSize,
        search: searchValue ?? null,
        categoryIds: categoryId === '0' ? categoiesSearch : [categoryId] ?? null,
        subCategoryIds: subCategoriesSearch ?? null,
        tags: groupsSearch ?? null,
        rating: ratingSearch ?? null,
        fromPrice: fromPriceSearch ?? null,
        toPrice: toPriceSearch ?? null,
        sorts: [priceSort ?? 0, featuredSort ?? 0],
        status: PRODUCT_STATUS_ENUM.APPROVED,
      })
      if (result) {
        setProducts(result.items)
        setTotalPages(result.totalPageCount)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className='category-wrapper pb-8'>
      <div className='header-page'>
        <div className='title'>
          <h1 className='mb-4'>
            {intl.formatMessage({ id: 'SearchResults' })}: {searchValue}
          </h1>
          <Breadcrumb />
        </div>
      </div>

      <div className='d-flex pt-8'>
        <div className='category-filter-panel col-3 pe-8'>
          <div className='mb-8'>
            <CategoryFilter
              categoryId={categoryId}
              categories={categories}
              handleChangeCategory={handleChangeCategory}
              handleChangeSubCategory={handleChangeSubCategory}
            />
          </div>

          <div className='mb-8'>
            <PriceFilter onChange={handleChangePrice} />
          </div>

          <div className='mb-8'>
            <RatingFilter rating={ratingSearch} onChange={setRatingSearch} />
          </div>

          <div className='mb-8'>
            <NewsBanner news={[]} />
          </div>
        </div>

        <div className='item-result col-9'>
          <SortBar
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={setCurrentPage}
            priceSort={priceSort}
            featuredSort={featuredSort}
            onPriceSort={setPriceSort}
            onFeaturedSort={setFeaturedSort}
          />

          <div className='row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4'>
            <Product products={products} />
          </div>
          <div className='p-4'>
            <PageNumber currentPage={currentPage} totalPages={totalPages} className='pagination-lg' onPageChange={setCurrentPage} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Search
