import React, { useState, useEffect } from 'react'
import { Route, Routes, useParams, Navigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import Category from '../category/Category'
import { PageTitle } from '../../components/layout/components/breadcrumb/PageData'
import { useCategoryService } from '../../services/apiServices'

const CategoryPage = () => {
  const intl = useIntl()
  const { categoryId } = useParams()
  const { getByIdAsync } = useCategoryService()
  const [item, setItem] = useState({})

  useEffect(() => {
    fetchCategories()
  }, [])

  const fetchCategories = async () => {
    try {
      const { result } = await getByIdAsync(categoryId)
      if (result) {
        setItem(result)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const loadBreadcrumbs = () => {
    let item = [{ title: intl.formatMessage({ id: 'Home' }), path: '/home' }]
    return item
  }

  return (
    <Routes>
      <Route path='*' element={<Navigate to='/' />} />
      <Route
        path='/'
        element={
          <>
            <PageTitle breadcrumbs={loadBreadcrumbs} isHideTitle={true}>
              {item.name}
            </PageTitle>
            <Category />
          </>
        }
      />
    </Routes>
  )
}

export default CategoryPage
