import React from 'react'
import { Link } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import '../../assets/sass/productDetail.scss'
import ProductReview from './ProductReview'
import { DEFAULT_VALUE } from '../../utilities/constant'
import { useIntl } from 'react-intl'
import NumberFormat from '../../components/shared/NumberFormat'
import CKEditorDisplay from '../../components/shared/CKEditorDisplay'
import { useAuth } from '../../components/auth/AuthContext'

const ProductSupplier = ({ supplier, product, productDetail }) => {
  const intl = useIntl()
  const { userPermissions } = useAuth()

  return (
    <>
      <section className='card d-flex flex-row p-4 mt-4 border me-8 gap-4'>
        <Link to={`/supplier/${supplier?.id}`} className='rounded-pill border d-inline-block position-relative'>
          <img width='70' height='70' src={'/media/jpg/shop.jpg'} loading='lazy' className='object-fit-contain rounded-pill' alt='' />
        </Link>

        <div className='w-50'>
          <div className='fw-bold text-uppercase'>{supplier?.companyName}</div>
          <div className='row align-items-center'>
            <div className='text-muted my-1'>
              <SVG src={'/media/svg/icons/location.svg'} />
              {supplier?.address}
            </div>
          </div>
        </div>
      </section>

      <section className='card p-4 mt-4 border me-8'>
        <div className='tab-style-1'>
          <ul className='nav nav-tabs text-uppercase'>
            <li className='nav-item'>
              <a className='nav-link active' id='Description-tab' data-bs-toggle='tab' href='#Description'>
                {intl.formatMessage({ id: 'ProductInformation' })}
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' id='Reviews-tab' data-bs-toggle='tab' href='#Reviews'>
                {intl.formatMessage({ id: 'RatingProduct' })} ({product?.quantityProductReviewed ?? 0})
              </a>
            </li>
          </ul>

          <div className='tab-content mt-10'>
            <div className='tab-pane fade active show' id='Description'>
              <div className='d-flex'>
                <div className='col-4 px-4'>
                  <div className='bg-light p-4 rounded'>
                    <div className='fw-bold fw-bolder text-uppercase'>{intl.formatMessage({ id: 'Product.PriceInformation' })}</div>
                  </div>
                  <div className='mt-4'>
                    <div className='list-group list-group-flush'>
                      <div className='px-2 list-group-item'>
                        <div className='justify-content-between row'>
                          <div className='col'>
                            <p className='fw-bold'>{intl.formatMessage({ id: 'Product.YourSellingPrice' })}</p>
                          </div>
                          <NumberFormat amount={productDetail?.sellingPrice} className='fw-bold col-auto' suffix={DEFAULT_VALUE.VND} />
                        </div>
                      </div>

                      {userPermissions && (
                        <div className='px-2 list-group-item'>
                          <div className='justify-content-between row'>
                            <div className='col'>
                              <p>{intl.formatMessage({ id: 'Product.CommissionMoney' })}</p>
                            </div>

                            <NumberFormat amount={productDetail?.commissionAmount} className='fw-bold col-auto text-primary' suffix={DEFAULT_VALUE.VND} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='col-4 px-4 border-start'>
                  <div className='d-flex justify-content-between bg-light p-4 rounded'>
                    <div className='fw-bold fw-bolder text-uppercase'>{intl.formatMessage({ id: 'Product.Description' })}</div>
                    {/* <div className='fw-bold text-primary cursor-pointer'>{intl.formatMessage({ id: 'Product.CopyDescription' })}</div> */}
                  </div>
                  <div className='mt-4 editor'>
                    <CKEditorDisplay content={product?.description} />
                  </div>
                </div>

                <div className='col-4 px-4 border-start'>
                  <div className='d-flex justify-content-between bg-light p-4 rounded'>
                    <div className='fw-bold fw-bolder text-uppercase'>{intl.formatMessage({ id: 'Product.Instruction' })}</div>
                  </div>
                  <div className='mt-4 editor'>
                    <CKEditorDisplay content={product?.userGuide} />
                  </div>
                </div>
              </div>
            </div>

            <div className='tab-pane fade' id='Reviews'>
              <ProductReview productId={product?.id} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ProductSupplier
